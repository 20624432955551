import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  chipList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipRoot: {
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chipLabel: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    userSelect: 'auto',
  },
}));
