import React from 'react';

export const createDraftActions = <T extends { id: string; name: string }>(
  fieldParagraphStyles: T[],
  fieldCharStyles: T[],
) => {
  return (fieldCharStyles.map(({ id, name }) => ({
    type: 'inline',
    label: name,
    style: `idsn:c:${id}`,
    icon: () => <span>{name}</span>,
  })) as any)
    .concat([
      {
        type: 'separator',
      },
    ])
    .concat(
      fieldParagraphStyles.map(({ id, name }) => ({
        type: 'block',
        label: name,
        style: `idsn:p:${id}`,
        icon: () => <span>{name}</span>,
      })),
    );
};
