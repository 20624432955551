import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: 'none',
  },
}));
