import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  placeholder: {
    color: theme.palette.grey[600],
  },
  variantdefault: {},
  variantwhite: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderColor: theme.palette.grey[200],
    borderRadius: 3,
  },
}));
