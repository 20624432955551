import { CircularProgress, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { EditInfoProps } from '../utils';
import { useStyles } from './styles';
import clsx from 'clsx';

export const TextFieldEdit: FC<EditInfoProps & { multiline?: boolean }> = ({
  value,
  onChange,
  loading,
  className,
  title,
  onValidate,
  variant,
  multiline,
  inputRef,
  labelClass,
}) => {
  const classes = useStyles();
  const [hasChanged, setHasChanged] = useState(false);

  if (Array.isArray(value)) return null;

  return (
    <div className={clsx(classes.wrapper, className)}>
      <TextField
        variant={variant}
        value={value || ''}
        className={classes.field}
        label={title}
        multiline={multiline}
        disabled={loading}
        inputRef={inputRef}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => {
          onChange?.(e.target.value);
          setHasChanged(true);
        }}
        onBlur={({ target }) => {
          if (hasChanged) {
            onValidate?.(target.value);
            setHasChanged(false);
          }
        }}
        InputLabelProps={{
          className: labelClass,
        }}
      />
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
};
