import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { FC, useState } from 'react';

export interface MenuAction {
  label: string;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

interface MoreActionsMenuProps {
  menuActions: MenuAction[];
}

export const MoreActionsMenu: FC<MoreActionsMenuProps> = ({ menuActions }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  if (!menuActions.length) {
    return <></>;
  }

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(undefined)}
      >
        {menuActions.map((action) => (
          <MenuItem
            key={action.label}
            disabled={action.disabled}
            onClick={(event) => {
              event.stopPropagation();
              action.onClick(event);
              setAnchorEl(undefined);
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
