import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1.2),
  },
  buttonsActions: {
    padding: `0 ${theme.spacing(1.2)}`,
    paddingBottom: theme.spacing(1.2),
  },
}));
