import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    marginTop: `-${theme.spacing(2)}`,
  },
  hideWrapper: {
    display: 'none',
  },
  divider: {
    // blue600
    backgroundColor: '#2196F3',
    width: '40%',
  },
  text: {
    // blue700
    color: '#1976D2',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
