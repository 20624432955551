import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  chipsList: {
    marginTop: theme.spacing(1),
  },
  title: {
    color: theme.palette.grey[500],
    display: 'block',
  },
  clickableText: {
    marginTop: theme.spacing(1),
    maxWidth: '100%',
  },
  textContent: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
}));
