import { Typography } from '@mui/material';
import { PhotoInfos } from '../PhotoInfos';
import React, { FC } from 'react';
import { useStyles } from './styles';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';

interface InfoSidebarProps {
  photos: SearchPhotosPhoto[];
}

export const InfoSidebar: FC<InfoSidebarProps> = ({ photos }) => {
  const classes = useStyles();

  return (
    <>
      {photos.length ? (
        <>
          <Typography variant="h5" className={classes.title}>
            Informations
          </Typography>
          <PhotoInfos
            photo={photos[photos.length - 1]}
            editable={photos.length === 1}
          />
        </>
      ) : (
        <Typography variant="h6" className={classes.noSelection}>
          Aucune image sélectionnée
        </Typography>
      )}
    </>
  );
};
