import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: 'white',
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 5,
    border: `2px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.25s ease-out',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.light}`,
    },
  },
  affectation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    width: '2em',
    height: '2em',
    padding: theme.spacing(1),
  },
}));
