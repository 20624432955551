import { Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { apiToForm, PrintSubjectFormModel } from './dataTransformer';
import { useGetPrintSubject } from './getPrintSubjectQuery.topic.graphql';
import { useStyles } from './styles';

interface SubjectTemplateProps {
  idSubject: string;
  creatorName: string;
}

export const SubjectTemplate: FC<SubjectTemplateProps> = ({
  idSubject,
  creatorName,
}) => {
  const classes = useStyles();
  const { data: subjectData, loading: subjectLoading } = useGetPrintSubject({
    id: idSubject,
  });
  if (subjectLoading) {
    return <div>Loading</div>;
  }
  if (!subjectData || !subjectData.subject) {
    return <div>ERROR</div>;
  }
  const values: PrintSubjectFormModel = apiToForm(subjectData);

  return (
    <div className={classes.container}>
      <Paper className={classes.header} elevation={0}>
        <div className={classes.subjectWrapper}>
          <div>
            <Typography variant="h6">TITRE DU SUJET: {values.title}</Typography>
            <Typography variant="body2">Créé par {creatorName}</Typography>
          </div>
          <div>
            <Typography>Thématique : {values.subjectCategory}</Typography>
            <Typography>
              Sous-thématique : {values.subSubjectCategory}
            </Typography>
          </div>
        </div>
      </Paper>
      <div className={classes.contentWrapper}>
        <Typography variant="h6">AFFECTATIONS</Typography>
        <div className={classes.content}>
          {values.assignments.map((assignment, i) => (
            <Typography key={`${assignment.fullTitle}-${i}`}>
              {assignment.fullTitle}
            </Typography>
          ))}
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.contentBlock}>
          <Typography variant="h6">SYNOPSIS</Typography>
          <div className={classes.content}>{values.synopsis}</div>
        </div>
        <div className={classes.contentBlock}>
          <Typography variant="h6">DEMANDE DE MEDIA</Typography>
          <Typography className={classes.content}>
            {values.mediaRequest}
          </Typography>
        </div>
        <div className={classes.contentBlock}>
          <Typography variant="h6">COMMENTAIRE</Typography>
          <Typography className={classes.content}>{values.comment}</Typography>
        </div>
      </div>
    </div>
  );
};
