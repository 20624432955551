import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    left: theme.spacing(2.5),
    top: theme.spacing(3),
  },
  avatar: {},
}));
