import { gql, useQuery } from '@apollo/client';
import {
  GetSubjectUsersQuery,
  GetSubjectUsersQueryVariables,
} from '../../../__generated__/queries-topic';

export const SUBJECT_USER_FRAGMENT = gql`
  fragment SubjectUser on SubjectUser {
    userId
    user {
      email
      name
      avatarUrl
    }
  }
`;

export const GET_SUBJECT_USERS = gql`
  query GetSubjectUsers($id: ID!) {
    subject(where: { id: $id }) {
      id
      subjectUsers(first: 100) {
        ...SubjectUser
      }
    }
  }
  ${SUBJECT_USER_FRAGMENT}
`;

export const useGetSubjectUsers = (variables: GetSubjectUsersQueryVariables) =>
  useQuery<GetSubjectUsersQuery, GetSubjectUsersQueryVariables>(
    GET_SUBJECT_USERS,
    {
      variables,
    },
  );
