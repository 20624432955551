import React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useStyles } from './styles';

export const CustomDialog: React.FC<DialogProps> = (props) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="md" classes={{ paper: classes.dialog }} {...props} />
  );
};
