import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  title: {
    whiteSpace: 'nowrap',
    margin: '1rem',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  icon: {
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  searchActionBar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > button': {
      marginLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginLeft: theme.spacing(2),
  },
}));
