import { Paper, Typography } from '@mui/material';
import { EditorState } from 'draft-js';
import { MegadraftEditor } from 'megadraft';
import React, { FC } from 'react';
import { FormattedDate } from '../../../components/FormattedDate';
import { asideBlockPlugin } from '../AsideBlockPlugin';
import { useStyles } from './styles';

interface ArticleTemplateProps {
  title: string;
  lead: EditorState;
  body: EditorState;
  signature: string;
  subjectTitle: string;
  assignmentTitle: JSX.Element | string;
  createdAt?: Date;
}

export const ArticleTemplate: FC<ArticleTemplateProps> = ({
  title,
  lead,
  body,
  signature,
  subjectTitle,
  assignmentTitle,
  createdAt,
}) => {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.header} elevation={0}>
        <div className={classes.rightWrapper}>
          <div>
            {createdAt && <FormattedDate date={createdAt} />}
            <Typography variant="h4">{assignmentTitle}</Typography>
          </div>
        </div>
        <div className={classes.subjectWrapper}>
          <Typography variant="h5">SUJET: {subjectTitle}</Typography>
        </div>
      </Paper>
      <div className={classes.contentWrapper}>
        <div className={classes.contentBlock}>
          <Typography variant="h6">TITRE</Typography>
          <Typography className={classes.content}>{title}</Typography>
        </div>
        <div className={classes.contentBlock}>
          <Typography variant="h6">CHAPO</Typography>
          <div className={classes.content}>
            <MegadraftEditor
              readOnly={true}
              sidebarRendererFn={() => <span />}
              editorState={EditorState.createWithContent(
                lead.getCurrentContent(),
              )}
            />
          </div>
        </div>
        <div className={classes.contentBlock}>
          <Typography variant="h6">CONTENU TEXTE</Typography>
          <div className={classes.content}>
            <MegadraftEditor
              classeName={classes.content}
              editorState={EditorState.createWithContent(
                body.getCurrentContent(),
              )}
              readOnly={true}
              plugins={[asideBlockPlugin]}
            />
          </div>
        </div>
        <div className={classes.contentBlock}>
          <Typography variant="h6">SIGNATURE</Typography>
          <Typography className={classes.content}>{signature}</Typography>
        </div>
      </div>
    </>
  );
};
