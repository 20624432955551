import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  blockICon: {
    position: 'absolute',
    right: '-11px',
    cursor: 'pointer',
    top: '-14px',
    background: theme.palette.common.white,
    color: theme.palette.grey[600],
    borderRadius: '50%',
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.5)',
  },
  iconCss: {
    width: '1.35em',
    height: '1.25em',
    padding: '3px',
  },
  downloadCss: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
