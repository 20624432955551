import { gql, useQuery } from '@apollo/client';
import {
  LocationPage_GetPrintHeadingByPrintIssueQuery,
  LocationPage_GetPrintHeadingByPrintIssueQueryVariables,
} from '../../../__generated__/queries-topic';
import { PRINT_HEADING_FRAGMENT } from './printHeadingFragment.topic.graphql';

export const GET_PRINT_HEADING_BY_PRINT_ISSUE = gql`
  query LocationPage_GetPrintHeadingByPrintIssue($printIssueId: ID!) {
    printIssue(where: { id: $printIssueId }) {
      id
      title
      printPublication {
        id
        key
      }
    }
    printHeadings(
      first: 1000
      orderBy: order_ASC
      where: { printIssue: { id: $printIssueId } }
    ) {
      ...PrintHeadingFragment
    }
  }
  ${PRINT_HEADING_FRAGMENT}
`;

export const useGetPrintHeadingByPrintIssue = (
  variables: LocationPage_GetPrintHeadingByPrintIssueQueryVariables,
) =>
  useQuery<
    LocationPage_GetPrintHeadingByPrintIssueQuery,
    LocationPage_GetPrintHeadingByPrintIssueQueryVariables
  >(GET_PRINT_HEADING_BY_PRINT_ISSUE, {
    variables,
    skip: !variables.printIssueId,
  });
