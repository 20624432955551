import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  LoadingButton,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { FC, useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import {
  LocationPage_GetPrintHeadingByPrintIssueQuery,
  LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading,
} from '../../../../__generated__/queries-topic';
import { GET_PRINT_HEADING_BY_PRINT_ISSUE } from '../getPrintHeadingsByPrintIssue.topic.graphql';
import { usePrintHeadingDeleter } from './deletePrintHeading.topic.graphql';
import { useGetPrintHeadingDeleteInformation } from './getPrintHeadingDeleteInformation.topic.graphql';
import { useStyles } from './styles';

interface PrintHeadingDialogFormProps {
  open: boolean;
  printHeading?: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading;
  handleClose: () => void;
  printIssueId: string;
}

interface PrintHeadingFormModel {
  title: string;
}

export const DeletePrintHeadingDialog: FC<PrintHeadingDialogFormProps> = ({
  printIssueId,
  open,
  printHeading,
  handleClose,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletePrintHeading] = usePrintHeadingDeleter({
    update: (cache, { data }) => {
      if (!data?.deletePrintHeading?.id) {
        return;
      }
      const result = cache.readQuery<LocationPage_GetPrintHeadingByPrintIssueQuery>(
        {
          query: GET_PRINT_HEADING_BY_PRINT_ISSUE,
          variables: { printIssueId },
        },
      );
      if (result && result.printIssue) {
        cache.writeQuery({
          query: GET_PRINT_HEADING_BY_PRINT_ISSUE,
          variables: { printIssueId },
          data: {
            ...result.printIssue,
            printHeadings: [
              ...result.printHeadings.filter(
                (pi) => pi.id !== printHeading?.id,
              ),
            ],
          },
        });
      }
    },
    onCompleted: () => {
      handleClose();
      pushNotification({
        message: `La têtière ${printHeading?.title} a bien été supprimée.`,
        type: NotificationTypeEnum.success,
      });
    },
    onError: () => {
      handleClose();
      pushNotification({
        message: `Erreur lors de la suppression de la têtière ${printHeading?.title}.`,
        type: NotificationTypeEnum.error,
      });
    },
  });
  const {
    data: printHeadingData = null,
    loading: printHeadingLoading = false,
  } = useGetPrintHeadingDeleteInformation({ id: printHeading?.id || '' });

  const handleDeletePrintheading = useCallback(async () => {
    setIsDeleting(true);
    await deletePrintHeading({
      variables: { id: printHeading?.id || '' },
    });
    setIsDeleting(false);
  }, [printHeading, deletePrintHeading]);

  if (!printHeading) {
    return <div />;
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.paper }}
    >
      {printHeadingLoading ? (
        <div>LOADING</div>
      ) : !printHeadingData || !printHeadingData.printHeading ? (
        <div>ERROR</div>
      ) : (
        <Form
          initialValues={{
            title: printHeading ? printHeading.title : '',
          }}
          validate={
            ((values: PrintHeadingFormModel) => {
              const errors: { title?: string } = {};
              if (!values.title) {
                // eslint-disable-next-line immutable/no-mutation
                errors.title = 'Titre obligatoire';
              }
              return errors;
            }) as any
          }
          onSubmit={handleDeletePrintheading}
          render={({ form }) => {
            if (!printHeadingData.printHeading) {
              return <div>toto</div>;
            }
            return (
              <>
                <DialogTitle id="form-dialog-title">
                  Supprimer une têtière dans{' '}
                  <strong>
                    {
                      printHeadingData.printHeading.printIssue.printPublication
                        ?.title
                    }{' '}
                    {printHeadingData.printHeading.printIssue.title}
                  </strong>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Êtes-vous sûr(e) de vouloir supprimer la têtière:
                  </DialogContentText>
                  <Typography variant="subtitle1">
                    {printHeadingData.printHeading.title}
                  </Typography>
                </DialogContent>
                <DialogContent>
                  {printHeadingData.printHeading.assignments.length === 0 ? (
                    <DialogContentText>
                      Cette têtière ne contient pas de sujet.
                    </DialogContentText>
                  ) : (
                    <>
                      <DialogContentText>
                        {printHeadingData.printHeading.assignments.length === 1
                          ? 'Cette têtière contient le sujet suivant'
                          : `Cette têtière contient les ${printHeadingData.printHeading.assignments.length} sujets suivants :`}
                      </DialogContentText>
                      <List>
                        {printHeadingData.printHeading.assignments.map(
                          (assignment) => (
                            <ListItem key={assignment.subject.title}>
                              <ListItemText
                                primary={assignment.subject.title}
                              />
                            </ListItem>
                          ),
                        )}
                      </List>
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Annuler
                  </Button>
                  <LoadingButton
                    onClick={form.submit}
                    loading={isDeleting}
                    color="secondary"
                    variant="contained"
                  >
                    Confirmer suppression
                  </LoadingButton>
                </DialogActions>
              </>
            );
          }}
        />
      )}
    </Dialog>
  );
};
