import {
  CheckCircle,
  CheckCircleOutline,
  Circle,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { SimpleCheckBox } from '@prismamedia/one-components';
import { useGetUnderSubjects } from 'apollo/queries/photoMetadatas.photo.graphql';
import { Dispatch, FC, SetStateAction } from 'react';
import { GetSubjectQuery_query_subject_Subject } from '../../../__generated__/queries-topic';
import {
  EditStatus,
  Filters,
  NO_COLOR,
  NO_UNDERSUBJECT,
  Resolution,
  colors,
  priceLevels,
} from '../utils';
import { useStyles } from './styles';

interface PhotoFiltersProps {
  subject: GetSubjectQuery_query_subject_Subject | undefined | null;
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}

export const PhotoFilters: FC<PhotoFiltersProps> = ({
  subject,
  filters,
  setFilters,
}) => {
  const classes = useStyles();
  const { metadatas: undersubjects, loading, error } = useGetUnderSubjects(
    {
      photo: {
        Metadata_ID_subject_some: {
          value: subject?.id,
        },
      },
    },
    !subject,
  );

  return (
    <>
      <div className={classes.resetButton}>
        <Button variant="contained" onClick={() => setFilters({})}>
          Réinitialiser les filtres
        </Button>
      </div>

      <Divider />

      <div className={classes.filterWrapper}>
        <Typography className={classes.filterTitle}>Couleur</Typography>
        <Tooltip title="Aucune">
          <IconButton
            className={classes.color}
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                colors: prev.colors?.includes(NO_COLOR)
                  ? prev.colors.filter((col) => col !== NO_COLOR)
                  : [...(prev.colors || []), NO_COLOR],
              }))
            }
          >
            {filters.colors?.includes(NO_COLOR) ? (
              <CheckCircleOutline />
            ) : (
              <RadioButtonUnchecked />
            )}
          </IconButton>
        </Tooltip>
        {colors.map(({ color, value, label }) => (
          <Tooltip key={value} title={label}>
            <IconButton
              className={classes.color}
              style={{ color }}
              onClick={() =>
                setFilters((prev) => ({
                  ...prev,
                  colors: prev.colors?.includes(value)
                    ? prev.colors.filter((col) => col !== value)
                    : [...(prev.colors || []), value],
                }))
              }
            >
              {filters.colors?.includes(value) ? <CheckCircle /> : <Circle />}
            </IconButton>
          </Tooltip>
        ))}
      </div>

      <Divider />

      <div className={classes.filterWrapper}>
        <div className={classes.filterTitleWrapper}>
          <Typography className={classes.filterTitle}>Sous-sujet</Typography>
          {loading && (
            <CircularProgress size={20} className={classes.filterLoader} />
          )}
        </div>
        {!error ? (
          undersubjects?.length ? (
            [
              { id: NO_UNDERSUBJECT, value: NO_UNDERSUBJECT, label: 'Aucun' },
              ...undersubjects.map(({ id, value }) => ({
                id,
                value,
                label: value || '',
              })),
            ].map(
              ({ id, value, label }) =>
                value && (
                  <SimpleCheckBox
                    key={id}
                    label={label}
                    checked={filters.underSubjects?.includes(value) || false}
                    onChange={(checked) =>
                      setFilters((prev) => ({
                        ...prev,
                        underSubjects: checked
                          ? [...(prev.underSubjects || []), value]
                          : prev.underSubjects?.filter(
                              (underSubject) => underSubject !== value,
                            ),
                      }))
                    }
                    className={classes.checkBox}
                  />
                ),
            )
          ) : (
            <Typography variant="caption">Aucun sous-sujet</Typography>
          )
        ) : (
          <Typography variant="caption" color="error">
            {error.message}
          </Typography>
        )}
      </div>

      <Divider />

      <div className={classes.filterWrapper}>
        <Typography className={classes.filterTitle}>Tarif</Typography>
        {Object.entries(priceLevels).map(([key, { label }]) => (
          <SimpleCheckBox
            key={key}
            label={label}
            checked={filters.priceLevels?.includes(key) || false}
            onChange={(checked) =>
              setFilters((prev) => ({
                ...prev,
                priceLevels: checked
                  ? [...(prev.priceLevels || []), key]
                  : prev.priceLevels?.filter(
                      (priceLevel) => priceLevel !== key,
                    ),
              }))
            }
            className={classes.checkBox}
          />
        ))}
      </div>

      <Divider />

      <div className={classes.filterWrapper}>
        <Typography className={classes.filterTitle}>Qualité</Typography>
        {Object.values(Resolution).map((value) => (
          <SimpleCheckBox
            key={value}
            label={value}
            checked={filters.resolutions?.includes(value) || false}
            onChange={(checked) =>
              setFilters((prev) => ({
                ...prev,
                resolutions: checked
                  ? [...(prev.resolutions || []), value]
                  : prev.resolutions?.filter(
                      (resolution) => resolution !== value,
                    ),
              }))
            }
            className={classes.checkBox}
          />
        ))}
      </div>

      <Divider />

      <div className={classes.filterWrapper}>
        <Typography className={classes.filterTitle}>Statut</Typography>
        {Object.values(EditStatus).map((value) => (
          <SimpleCheckBox
            key={value}
            label={value}
            checked={filters.editStatus?.includes(value) || false}
            onChange={(checked) =>
              setFilters((prev) => ({
                ...prev,
                editStatus: checked
                  ? [...(prev.editStatus || []), value]
                  : prev.editStatus?.filter((status) => status !== value),
              }))
            }
            className={classes.checkBox}
          />
        ))}
      </div>
    </>
  );
};
