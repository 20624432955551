import { convertToRaw, EditorState } from 'draft-js';
import { editorStateFromRaw } from 'megadraft';
import { omit } from 'ramda';
import uuid from 'uuid/v4';
import {
  AssignmentUpdateRawArticleInput,
  GetRawArticleQuery,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';

interface EditorModel {
  id: string;
  name?: string | null;
  avatarUrl?: string | null;
}

export interface ArticleFormModel {
  id: string;
  title: string;
  lead: EditorState;
  body: EditorState;
  signature: string;
  lastVersionId?: string;
  status: RawArticleStatus;
  assignmentId?: string;
  textIsReadOnly: boolean;
  editor?: EditorModel | null;
  createdAt?: Date;
  allowedStatuses: RawArticleStatus[];
}

const getEmptyArticleForm = (): ArticleFormModel => ({
  id: '',
  title: '',
  lead: editorStateFromRaw(null),
  body: editorStateFromRaw(null),
  signature: '',
  status: RawArticleStatus.Editing,
  editor: null,
  textIsReadOnly: false,
  allowedStatuses: [],
});

export const apiToForm = (
  queryData: GetRawArticleQuery | { rawArticle: null },
): ArticleFormModel => {
  if (!queryData?.rawArticle) {
    return getEmptyArticleForm();
  }

  const { rawArticle } = queryData;

  let formVersionPart = getEmptyArticleForm();

  if (rawArticle.versions.length > 0) {
    const lastVersion = rawArticle.versions[rawArticle.versions.length - 1];
    formVersionPart = {
      ...formVersionPart,
      allowedStatuses: lastVersion.allowedStatuses,
      title: lastVersion.title || '',
      signature: lastVersion.signature || '',
      lastVersionId: lastVersion.id,
      lead: editorStateFromRaw(
        lastVersion.lead ? JSON.parse(lastVersion.lead) : lastVersion.lead,
      ),
      body: editorStateFromRaw(
        lastVersion.body ? JSON.parse(lastVersion.body) : lastVersion.body,
      ),
      status: lastVersion.status,
      textIsReadOnly: lastVersion.textIsReadOnly,
      editor: lastVersion.editor || null,
      createdAt: new Date(lastVersion.createdAt),
    };
  }

  return {
    ...formVersionPart,
    id: rawArticle.id,
    assignmentId: rawArticle.assignment?.id,
  };
};

export const formToApi = (
  data: ArticleFormModel,
  userId: string,
): AssignmentUpdateRawArticleInput => {
  const versionData = {
    id: uuid(),
    editorId: userId,
    title: data.title,
    lead: JSON.stringify(convertToRaw(data.lead.getCurrentContent())),
    body: JSON.stringify(convertToRaw(data.body.getCurrentContent())),
    signature: data.signature,
    status: data.status,
    ...(data.lastVersionId
      ? {
          previousVersion: {
            connect: { id: data.lastVersionId },
          },
        }
      : {}),
  };

  const readOnlyBlacklist = ['title', 'lead', 'body', 'signature'];

  const model = {
    versions: {
      create: [
        data.textIsReadOnly
          ? omit(readOnlyBlacklist, versionData)
          : versionData,
      ],
    },
  };

  return {
    create: {
      ...model,
    },
    update: {
      ...model,
    },
  };
};
