import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';
import { Assignment } from '.';
import { AssignmentFormModel } from '../../pages/SubjectForm/Form/dataTransformer';
import {
  RawArticleStatus,
  TemplateType,
} from '../../__generated__/queries-topic';
import { ArticleLink } from '../Link/ArticleLink';

interface AssignmentDialogProps {
  assignmentIndex: number;
  assignment: AssignmentFormModel;
  unitId: string;
  closeDialog: (e: React.SyntheticEvent) => void;
  handleNewAssignment: (e: React.SyntheticEvent) => void;
  saveAssignment: (e: React.SyntheticEvent) => void;
  dialogStep: number;
  excludedIssues: string[];
}

const couldSaveAssignment = (assignment: AssignmentFormModel) =>
  assignment?.rawArticle?.versions?.[0]?.status === RawArticleStatus.Mockup &&
  (!assignment.printHeadingId ||
    !assignment.supportId ||
    !assignment.printIssueId);

export const AssignmentDialog: FC<AssignmentDialogProps> = ({
  unitId,
  assignment,
  closeDialog,
  saveAssignment,
  dialogStep,
  excludedIssues,
  assignmentIndex,
}) => {
  const isMultiSubject =
    Boolean(assignment?.printHeading?.autoPrototype) &&
    assignment.printHeading?.printTemplates[0]?.printTemplate.type ===
      TemplateType.MULTIPLE_ARTICLE_TEMPLATE;
  const creationSteps = [
    {
      title: "Ajout d'un support",
      content: (
        <Assignment
          unitId={unitId}
          assignment={assignment}
          assignmentIndex={assignmentIndex}
          excludedIssues={excludedIssues}
        />
      ),
      actions: [
        <Button onClick={closeDialog} key="cancel">
          Annuler
        </Button>,
        <Button
          variant="contained"
          color="primary"
          key="validate"
          disabled={couldSaveAssignment(assignment)}
          onClick={saveAssignment}
        >
          CONFIRMER
        </Button>,
      ],
    },
    {
      title: `Votre parution a bien été créée ! ${
        isMultiSubject
          ? 'Souhaitez-vous configurer votre Multi-sujet ?'
          : 'Souhaitez-vous y rédiger un article ?'
      }`,
      content: null,
      actions: [
        <Button onClick={closeDialog} key="cancel">
          Non plus tard
        </Button>,
        <ArticleLink
          key="validate"
          subjectId={assignment.subject.id}
          assignment={{
            isMultiSubject,
            printTemplateId: assignment.printTemplateId,
            printHeadingId: assignment.printHeadingId,
            autoPrototype: assignment.autoPrototype,
            id: assignment.id,
            rawArticle: assignment.rawArticle,
            website:
              assignment.supportId && assignment.supportKey
                ? {
                    id: assignment.supportId,
                    key: assignment.supportKey,
                  }
                : null,
          }}
        >
          <Button variant="contained" color="primary">
            Oui, maintenant
          </Button>
        </ArticleLink>,
      ],
    },
  ];
  return (
    <Dialog fullWidth maxWidth="md" onClose={closeDialog} open>
      <DialogTitle>{creationSteps[dialogStep].title}</DialogTitle>
      <DialogContent>{creationSteps[dialogStep].content}</DialogContent>
      <DialogActions>
        {creationSteps[dialogStep].actions.map((action) => action)}
      </DialogActions>
    </Dialog>
  );
};
