import { gql } from '@apollo/client';

export const PRINT_PUBLICATION_FRAGMENT = gql`
  fragment PrintPublicationFragment on PrintPublication {
    id
    title
    key
    brand {
      id
    }
  }
`;
