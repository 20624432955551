import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  LocationPage_UpsertPrintIssueMutation,
  LocationPage_UpsertPrintIssueMutationVariables,
} from '../../../../__generated__/queries-topic';
import { PRINT_ISSUE_FRAGMENT } from '../printIssueFragment.topic.graphql';

const UPSERT_PRINT_ISSUE = gql`
  mutation LocationPage_UpsertPrintIssue(
    $create: PrintIssueCreationInput!
    $update: PrintIssueUpdateInput!
    $where: PrintIssueUniqueFilterInput!
  ) {
    upsertPrintIssue(create: $create, update: $update, where: $where) {
      ...PrintIssueFragment
    }
  }
  ${PRINT_ISSUE_FRAGMENT}
`;

export const usePrintIssueUpsert = (
  options?: MutationHookOptions<
    LocationPage_UpsertPrintIssueMutation,
    LocationPage_UpsertPrintIssueMutationVariables
  >,
) =>
  useMutation<
    LocationPage_UpsertPrintIssueMutation,
    LocationPage_UpsertPrintIssueMutationVariables
  >(UPSERT_PRINT_ISSUE, options);
