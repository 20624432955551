import { useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow } from '@mui/material';
import {
  NotificationTypeEnum,
  PushNotificationFn,
  RemoveNotificationFn,
} from '@prismamedia/one-components';
import axios from 'axios';
import downloadFile from 'js-file-download';
import { FC } from 'react';
import { GetSubjectQuery_query_subject_Subject_attachments_Attachment } from '../../../__generated__/queries-topic';
import { format } from '../../../utils/dateFnsFormat';
import { getSize } from '../../../utils/getSize';
import { AttachmentIcon } from '../../AttachmentIcon';
import { getDownloadUrlAttachment } from './getDownloadUrlAttachment.topic.graphql';
import { useStyles } from './styles';

interface AttachmentItemProps {
  attachment: GetSubjectQuery_query_subject_Subject_attachments_Attachment;
  setDeleteAttachmentData: () => void;
  pushNotification: PushNotificationFn;
  removeNotification: RemoveNotificationFn;
}

export const AttachmentItem: FC<AttachmentItemProps> = ({
  attachment,
  setDeleteAttachmentData,
  pushNotification,
  removeNotification,
}) => {
  const client = useApolloClient();
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell
        className={classes.downloadCss}
        onClick={() => {
          const notificationKey = pushNotification({
            message: 'Téléchargement en cours, veuillez patienter...',
            type: NotificationTypeEnum.info,
            autoHideDuration: false,
          });

          window.setTimeout(async () => {
            try {
              const { downloadUrl } = await getDownloadUrlAttachment(
                client,
                attachment.id,
              );
              const res = await axios.get(downloadUrl, {
                headers: {
                  Accept: 'application/octet-stream',
                },
                responseType: 'blob',
              });
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              downloadFile(res.data, attachment.filename!);
              removeNotification(notificationKey);
              pushNotification({
                message: 'Téléchargement terminé.',
                type: NotificationTypeEnum.success,
              });
            } catch (e) {
              removeNotification(notificationKey);
              pushNotification({
                message: 'Echec du téléchargement. Merci de réessayer.',
                type: NotificationTypeEnum.error,
              });
            }
          }, 2000);
        }}
      >
        <AttachmentIcon contentType={attachment.contentType} />
        {attachment.filename}
      </TableCell>
      <TableCell>{attachment.createdBy && attachment.createdBy.name}</TableCell>
      <TableCell>
        {format(attachment.createdAt, 'd MMMM yyyy - hh:mm')}
      </TableCell>
      <TableCell>
        {attachment.filesize
          ? `${getSize(attachment.filesize).size} ${
              getSize(attachment.filesize).unit
            }`
          : '-'}
        <div className={classes.blockICon}>
          <DeleteIcon
            className={classes.iconCss}
            onClick={setDeleteAttachmentData}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};
