import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  labelAvatarStepOwner: {
    marginLeft: theme.spacing(2),
  },
  labelIconStepOwner: {
    marginLeft: 30,
  },
  icon: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
}));
