import React from 'react';
import { event, exception } from 'react-ga';

export const gaSendException = (error: Error, info: React.ErrorInfo) => {
  exception({
    description: `Message : ${error.toString()} / Stack : ${
      info.componentStack
    }`,
    fatal: true,
  });
};

export const gaSendEvent = (
  category: string,
  action: string,
  label: string,
  value?: number,
) => {
  event({
    category,
    action,
    label,
    value,
  });
};
