import { Lens, RadioButtonUnchecked } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useMenu } from '@prismamedia/one-components';
import React, { FC } from 'react';
import { colors } from '../utils';

interface ColorMenuProps {
  onSelect: (value: string | undefined) => void;
}

export const ColorMenu: FC<ColorMenuProps> = ({ onSelect }) => {
  const { closeMenu } = useMenu();

  return (
    <>
      {[{ color: '', label: 'Aucune', value: undefined }, ...colors].map(
        ({ color, label, value }, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              onSelect(value);
              closeMenu();
            }}
          >
            <ListItemIcon>
              {color ? <Lens style={{ color }} /> : <RadioButtonUnchecked />}
            </ListItemIcon>
            {label}
          </MenuItem>
        ),
      )}
    </>
  );
};
