import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ColoredCheckboxButton } from '../../../components/Button/ColoredCheckboxButton';
import {
  getColorByStatus,
  getOrderedStatusList,
  getStatusName,
} from '../../../utils/rawArticleStatus';
import { useStyles } from './styles';

interface TextFilterProps {
  selectedTextStatuses: string[];
  onChange: (statuses: string[]) => void;
}

export const TextFilter: FC<TextFilterProps> = ({
  selectedTextStatuses,
  onChange,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setSelected(
      getOrderedStatusList().reduce((acc, status) => {
        // eslint-disable-next-line immutable/no-mutation
        acc[status] = selectedTextStatuses.includes(status);
        return acc;
      }, {} as Record<string, boolean>),
    );
  }, [selectedTextStatuses, setSelected]);

  const onCheckboxChange = (checked: boolean, value?: string) => {
    if (!value) {
      return;
    }
    const nextSelectedState = {
      ...selected,
      ...{ [value]: !checked },
    };
    setSelected(nextSelectedState);
    onChange(
      Object.entries(nextSelectedState)
        .filter(([, isChecked]) => isChecked)
        .map(([statusName]) => statusName),
    );
  };
  return (
    <div className={classes.wrapper}>
      {getOrderedStatusList().map((status) => (
        <ColoredCheckboxButton
          key={status}
          value={status}
          checked={selected[status]}
          onChange={onCheckboxChange}
          backgroundColor={getColorByStatus(status)}
          color="white"
          buttonProps={{ style: { marginTop: 8 } }}
        >
          <Typography color="inherit" variant="body2">
            {getStatusName(status)}
          </Typography>
        </ColoredCheckboxButton>
      ))}
    </div>
  );
};
