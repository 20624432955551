import moment from 'moment';
import 'moment/locale/fr';

interface RawArticleForTooltipTitle {
  versions?: {
    title?: string | null;
    createdAt?: string;
    editor?: {
      name: string;
    } | null;
  }[];
}

export const getAssignmentTooltipTitle = (
  rawArticle: RawArticleForTooltipTitle,
) => {
  if (!rawArticle || rawArticle.versions?.length === 0) {
    return;
  }

  const version = rawArticle.versions?.[rawArticle.versions.length - 1];

  const lastModificationDate =
    version?.createdAt &&
    `le ${moment(version?.createdAt).format('D MMM yyyy, HH[h]mm')}`;
  const user = version?.editor && `par ${version?.editor.name}`;

  if (!lastModificationDate && !user) {
    return;
  }

  return `Dernière modification ${lastModificationDate} ${user}`;
};
