import formatDate from 'date-fns/format';

import fr from 'date-fns/locale/fr';

const capitlizeString = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const format = (date: Date | string, formatStr: string) =>
  capitlizeString(formatDate(new Date(date), formatStr, { locale: fr }));
