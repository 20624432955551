import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  inputProps: {
    fontSize: '0.875rem',
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.grey[900],
  },
  typo: {
    color: theme.palette.grey[900],
    cursor: 'pointer',
    textDecoration: 'underline',
    minHeight: '25px',
  },
}));
