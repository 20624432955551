import Delete from '@mui/icons-material/Delete';
import {
  Divider,
  Fab,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import { editorStateFromRaw, MegadraftEditor } from 'megadraft';
import { FC, useState } from 'react';
import {
  getCountFromText,
  getCountWithAsideBlockFromEditorState,
} from '../../../../components/CharCounter/utils';
import { pluralize } from '../../../../utils/addPlural';
import { bodyDraftConfig } from '../../Form/megadraftActionsConfig';
import { useStyles } from './styles';

interface AsideData {
  asideDraft: RawDraftContentState;
  asideTitle: string;
}

interface AsideBlockProps {
  blockProps: {
    setReadOnly: (readOnly: boolean) => void;
    getInitialReadOnly: () => boolean;
  };
  data: AsideData;
  container: { updateData: (data: AsideData) => void; remove: () => void };
}

export const AsideBlock: FC<AsideBlockProps> = ({
  data,
  blockProps,
  container,
}) => {
  const classes = useStyles();
  const [showDelete, setShowDelete] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(
    editorStateFromRaw(data.asideDraft),
  );
  const remove = () => {
    container.remove();
    blockProps.setReadOnly(false);
  };

  const onChangeTitle = (title: string): void => {
    const newData = { ...data };
    // eslint-disable-next-line immutable/no-mutation
    newData.asideTitle = title;
    container.updateData(newData);
  };

  const onChangeContent = (e: EditorState) => {
    setEditorState(e);
    const newData = { ...data };
    // eslint-disable-next-line immutable/no-mutation
    newData.asideDraft = convertToRaw(e.getCurrentContent());
    container.updateData(newData);
  };
  const onMouseOver = () => setShowDelete(true);
  const onMouseOut = () => setShowDelete(false);

  const { wordCount, charCount } = getCountWithAsideBlockFromEditorState(
    editorState,
  );
  const asideTitleCount = getCountFromText(data.asideTitle);

  return (
    <Paper
      data-cy="article-form-aside-block"
      className={classes.wrapper}
      elevation={0}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseOut={onMouseOut}
      onBlur={onMouseOut}
    >
      {!blockProps.getInitialReadOnly() && (
        <Fab
          className={classes.deleteButton}
          style={{ display: showDelete ? 'block' : 'none' }}
          onClick={remove}
        >
          <Delete className={classes.avatarIcon} />
        </Fab>
      )}
      <TextField
        label="Titre de l'encadré"
        fullWidth={true}
        multiline
        name="BlockTitle"
        onChange={(e) => onChangeTitle(e.target.value)}
        value={data.asideTitle || ''}
        className={classes.field}
        disabled={blockProps.getInitialReadOnly()}
        variant="standard"
      />
      {(editorState as EditorState).getCurrentContent().getPlainText() && (
        <InputLabel>Contenu de l'encadré</InputLabel>
      )}
      <MegadraftEditor
        sidebarRendererFn={() => <span />}
        placeholder="Contenu de l'encadré"
        editorState={editorState}
        onChange={onChangeContent}
        plugins={[]}
        actions={bodyDraftConfig}
        readOnly={blockProps.getInitialReadOnly()}
      />
      <Divider className={classes.divider} />
      <Typography variant="caption" className={classes.charCount}>{`${
        charCount + asideTitleCount.charCount
      } ${pluralize(
        'Caractère',
        'Caractères',
        charCount + asideTitleCount.charCount,
      )} - ${wordCount + asideTitleCount.wordCount} ${pluralize(
        'Mot',
        'Mots',
        wordCount + asideTitleCount.wordCount,
      )}`}</Typography>
    </Paper>
  );
};
