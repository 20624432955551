import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing(1, 2, 0, 2),
    position: 'relative',
  },
  filterTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  filterLoader: {
    margin: '0 0 3px 10px',
  },
  filterTitle: {
    marginBottom: theme.spacing(0.5),
  },
  resetButton: {
    margin: theme.spacing(2, 0, 2),
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  checkBox: {
    display: 'block',
    height: 35,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  color: {
    cursor: 'pointer',
    height: 35,
    width: 35,
  },
}));
