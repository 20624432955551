import { ApolloQueryResult } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { FC } from 'react';
import { Form } from 'react-final-form';
import {
  GetRawArticleQuery,
  GetRawArticleQueryVariables,
} from '../../../__generated__/queries-topic';
import { Assignment } from '../../../components/Assignment';
import { AssignmentFormModel } from '../../SubjectForm/Form/dataTransformer';
import { apiToForm, formToApi } from './dataTransformer';
import { useGetAssignmentByRawArticleId } from './getAssignmentByRawArticleId.topic.graphql';
import { useStyles } from './styles';
import { useAssignmentByRawArticleIdUpdater } from './updateAssignmentByRawArticleId.topic.graphql';

const assignmentStyle = {
  display: 'flex',
  flexDirection: 'row' as const,
  marginBottom: 24,
};

interface AssignmentModalProps {
  rawArticleId: string;
  unitId: string;
  open: boolean;
  createNewVersion: () => void;
  refetch?: (
    variables?: GetRawArticleQueryVariables,
  ) => Promise<ApolloQueryResult<GetRawArticleQuery>>;
  toggleAssignmentModal: () => void;
}

export const AssignmentModal: FC<AssignmentModalProps> = ({
  unitId,
  rawArticleId,
  createNewVersion,
  refetch,
  open,
  toggleAssignmentModal,
}) => {
  const classes = useStyles();
  const {
    data: assignmentData,
    loading: assignmentLoading,
  } = useGetAssignmentByRawArticleId({ id: rawArticleId });
  const [updateAssignmentByRawArticleId] = useAssignmentByRawArticleIdUpdater({
    onCompleted: async () => {
      toggleAssignmentModal();
      if (refetch) {
        await refetch();
      }
      createNewVersion();
    },
  });
  const assignment = apiToForm(assignmentData);

  return (
    <Dialog open={open} maxWidth={false} onClose={toggleAssignmentModal}>
      <DialogTitle>Attention</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          Pour passer à l'étape À MAQUETTER, l'affectation du sujet doit être
          complete
        </DialogContentText>
        {assignmentLoading ? (
          <div>LOADING</div>
        ) : !assignmentData ? (
          <div>Error</div>
        ) : !assignment ? (
          <div>Error</div>
        ) : (
          <Form
            mutators={{ ...arrayMutators }}
            initialValues={{ assignments: [assignment] }}
            onSubmit={
              ((values: { assignments: AssignmentFormModel[] }) => {
                if (
                  !values.assignments[0].printHeadingId ||
                  !values.assignments[0].supportId ||
                  !values.assignments[0].printIssueId
                ) {
                  return;
                }
                const variables = formToApi(
                  rawArticleId,
                  values.assignments[0].printHeadingId,
                );
                updateAssignmentByRawArticleId(variables);
              }) as any
            }
            render={({ form, values }: any) => (
              <>
                <div className={classes.wrapperAssignment}>
                  <Assignment
                    unitId={unitId}
                    websiteAvailable={false}
                    assignmentWrapperStyle={assignmentStyle}
                    assignment={values.assignments[0]}
                    assignmentIndex={0}
                  />
                </div>
                <DialogActions>
                  <Button onClick={toggleAssignmentModal}>Annuler</Button>
                  <Button
                    disabled={
                      !(
                        !!values.assignments[0].printHeadingId &&
                        values.assignments[0].printHeadingId.length > 0
                      )
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Maquetter
                  </Button>
                </DialogActions>
              </>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
