import { IconButton, Paper, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useContext } from 'react';
import { MultiSubjectAutoContext } from '..';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../components/Loader/Circular';
import { useStyles } from './styles';

export const MultiSubjectHeader = () => {
  const classes = useStyles();
  const {
    togglePreview,
    showPreview,
    isPreviewLoading,
    previewResponseTime,
  } = useContext(MultiSubjectAutoContext);

  return (
    <Paper square elevation={1} className={classes.paperWrapper}>
      <div
        data-cy="article-form-subject-header"
        className={classes.articleHeader}
      >
        <div className={classes.assignment}></div>
      </div>
      <div className={classes.headerRightGroup}>
        <div>
          {isPreviewLoading && <CircularLoader size={LoaderSizes.xsmall} />}
        </div>
        {isPreviewLoading && ' Chargement en cours...'}
        {!isPreviewLoading && `Temps de réponse ${previewResponseTime}s`}
        <IconButton onClick={togglePreview} size="large">
          <Tooltip title={showPreview ? "Fermer l'aperçu" : 'Aperçu'}>
            {showPreview ? (
              <VisibilityIcon color="primary" />
            ) : (
              <VisibilityOffIcon />
            )}
          </Tooltip>
        </IconButton>
      </div>
    </Paper>
  );
};
