import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';

interface DialogSubjectTitleProps {
  title: string;
  handleClose: () => void;
  changeStep: (index: number) => void;
}

export const DialogSubjectTitle: FC<DialogSubjectTitleProps> = ({
  title,
  handleClose,
  changeStep,
}) => {
  return (
    <>
      <DialogTitle>Voici le titre de votre sujet auto :</DialogTitle>
      <DialogContent>
        <DialogContentText>{title}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => changeStep(2)}
        >
          Suivant
        </Button>
      </DialogActions>
    </>
  );
};
