import MuiTextField from '@mui/material/TextField';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

export const TextField: FC<FieldRenderProps> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => (
  <MuiTextField
    {...rest}
    name={name}
    helperText={meta.touched ? meta.error : undefined}
    error={meta.error && meta.touched}
    inputProps={restInput}
    onChange={onChange}
    value={value}
    variant="standard"
  />
);
