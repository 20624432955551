import { gql, useQuery } from '@apollo/client';
import {
  GetSubjectQuery,
  GetSubjectQueryVariables,
} from '../../__generated__/queries-topic';
import { SUBJECT_USER_FRAGMENT } from '../../components/ShareDialog/ShareSubjectDialog/getSubjectUsers.topic.graphql';
import { ATTACHMENT_FRAGMENT } from './Attachments/attachmentFragment.topic.graphql';

export const SUBJECT_FORM_FLAT_ASSIGNMENT_FRAGMENT = gql`
  fragment SubjectForm_FlatAssignment on FlatAssignment {
    assignment {
      id
      printTemplate {
        id
      }
      articleTemplate {
        id
      }
      mediaSearchStatus
      pageNumber
    }
    printPublication {
      id
      key
      title
    }
    printIssue {
      id
      title
      printPublication {
        id
        title
      }
    }
    printHeading {
      id
      title
      autoPrototype
      printTemplates(first: 1, where: { active: true }) {
        printTemplate {
          id
          type
        }
      }
      printIssue {
        id
        title
        printPublication {
          id
          title
        }
      }
    }
    rawArticle {
      id
      versions(first: 1, where: { nextVersion_is_null: true }) {
        id
        status
        title
        editor {
          id
          name
        }
        createdAt
      }
    }
    website {
      id
      key
      title
    }
  }
`;

const GET_SUBJECT = gql`
  query GetSubject($id: ID!) {
    subject(where: { id: $id }) {
      id
      title
      synopsis
      comment
      mediaRequest
      createdBy {
        name
      }
      unit {
        id
        title
        photoArchiveId
      }
      subjectCategory {
        id
        parent {
          id
        }
      }
      assignments: flatAssignments(first: 20) {
        ...SubjectForm_FlatAssignment
      }
      isClassified
      subjectUsers(first: 100) {
        ...SubjectUser
      }
      attachments(first: 100, where: { isUploaded: true }) {
        ...AttachmentFragment
      }
      isTitleWritable
    }
  }
  ${SUBJECT_FORM_FLAT_ASSIGNMENT_FRAGMENT}
  ${SUBJECT_USER_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export const useGetSubject = (variables: GetSubjectQueryVariables) =>
  useQuery<GetSubjectQuery, GetSubjectQueryVariables>(GET_SUBJECT, {
    variables,
    fetchPolicy: 'network-only',
  });
