import { GetPrintSubjectQuery } from '../../../__generated__/queries-topic';

interface PrintAssignmentFormModel {
  fullTitle: string;
}

export interface PrintSubjectFormModel {
  title: string;
  synopsis: string;
  mediaRequest: string;
  comment: string;
  subjectCategory: string;
  subSubjectCategory: string;
  assignments: PrintAssignmentFormModel[];
}

export const apiToForm = (
  queryData: GetPrintSubjectQuery,
): PrintSubjectFormModel => {
  if (!queryData.subject) {
    return {
      title: '',
      synopsis: '',
      comment: '',
      subjectCategory: '',
      subSubjectCategory: '',
      mediaRequest: '',
      assignments: [],
    };
  }
  const { subject } = queryData;

  return {
    title: subject.title,
    synopsis: subject.synopsis || '',
    mediaRequest: subject.mediaRequest || '',
    comment: subject.comment || '',
    assignments: subject.assignments.map(
      ({ printPublication, printIssue, printHeading, website }) => {
        let fullTitle = 'Affection existante mais vide';

        if (printPublication) {
          fullTitle = printPublication.title;
        }

        if (printIssue) {
          fullTitle = [fullTitle, printIssue.title].join(' • ');
        }

        if (printHeading) {
          fullTitle = [fullTitle, printHeading.title].join(' • ');
        }

        if (website) {
          fullTitle = `${website.title} - WEB`;
        }

        return { fullTitle };
      },
    ),
    subjectCategory: subject.subjectCategory
      ? subject.subjectCategory.parent
        ? subject.subjectCategory.parent.title
        : subject.subjectCategory.title
      : '',
    subSubjectCategory:
      subject.subjectCategory && subject.subjectCategory.parent
        ? subject.subjectCategory.title
        : '',
  };
};
