import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  dialog: {
    padding: 0,
  },
  dialogContent: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  img: {
    '& img': {
      width: 'auto',
      height: '100%',
      maxHeight: '80vh',
    },
  },
});
