import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0,
  },
  subtitle: {
    padding: theme.spacing(0, 3, 2),
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  column: {
    flexBasis: `calc(50% - ${theme.spacing(2)})`,
    '&:first-child': {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      marginRight: '0 !important',
    },
  },
  field: {
    marginBottom: theme.spacing(3),
  },
}));
