import { gql, useQuery } from '@apollo/client';
import {
  GetRawArticleVersionArticleHistoryQuery,
  GetRawArticleVersionArticleHistoryQueryVariables,
} from '../../../__generated__/queries-topic';

const GET_RAW_ARTICLE_VERSION_ARTICLE_HISTORY = gql`
  query GetRawArticleVersionArticleHistory($id: ID!) {
    rawArticleVersion(where: { id: $id }) {
      id
      title
      lead
      body
      signature
      createdAt
      status
      editor {
        id
        name
        avatarUrl
      }
      rawArticle {
        id
        lastVersion {
          id
        }
        assignment {
          printTemplate {
            fields {
              id
              name
              type
            }
          }
        }
      }
    }
  }
`;

export const useGetRawArticleVersionArticleHistory = (
  variables: GetRawArticleVersionArticleHistoryQueryVariables,
) =>
  useQuery<
    GetRawArticleVersionArticleHistoryQuery,
    GetRawArticleVersionArticleHistoryQueryVariables
  >(GET_RAW_ARTICLE_VERSION_ARTICLE_HISTORY, {
    variables,
    skip: !variables.id,
  });
