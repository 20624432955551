import { gql } from '@apollo/client';

export const CATEGORY_FRAGMENT = gql`
  fragment Category on Category {
    id
    label
    parent {
      id
    }
  }
`;
