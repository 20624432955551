import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  error: {
    margin: theme.spacing(3, 0),
    textAlign: 'center',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'relative',
  },
  newTextField: {
    marginTop: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
