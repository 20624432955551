import React, { FC } from 'react';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { insertDataBlock } from 'megadraft';
import makeStyles from '@mui/styles/makeStyles';
import { EditorState } from 'draft-js';

const useStyles = makeStyles(() => ({
  button: {
    right: 0,
    backgroundColor: 'white',
    cursor: 'pointer',
  },
}));

interface AsideButtonProps {
  editorState: EditorState;
  onChange: (editorState: () => void) => void;
}

export const AsideButton: FC<AsideButtonProps> = ({
  editorState,
  onChange,
}) => {
  const onClick = () => {
    const data = { type: 'aside' };
    onChange(insertDataBlock(editorState, data));
  };

  const classes = useStyles();
  return <InsertDriveFile className={classes.button} onClick={onClick} />;
};
