import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import SplitPane, { Pane } from 'react-split-pane';
import { ArticleAutoContext } from '..';
import { DrafPreview } from '../Preview';
import './index.css';
import { useStyles } from './styles';
import { useAutoFormComponentsGenerator } from './useAutoFormComponentsGenerator';
import { useAutoFormFieldsGenerator } from './useAutoFormFieldsGenerator';

export const ArticleAutoForm: FC = () => {
  const classes = useStyles();
  const {
    showPreview,
    rawArticleData,
    articleTemplate,
    printTemplates,
    selectedPrintTemplateId,
    setFormStructure,
    setSelectedPrintTemplateId,
    setSelectedPrintTemplate,
  } = useContext(ArticleAutoContext);
  const [mainPanelSize, setMainPanelSize] = useState(() => {
    const storedSize = parseInt(localStorage.getItem('splitPos') || '400', 10);
    return storedSize < window.innerWidth ? storedSize : window.innerWidth / 2;
  });

  // prepare form fields states
  useAutoFormFieldsGenerator();

  // get form components based on form fields states
  const formComponents = useAutoFormComponentsGenerator();

  const handleSelect = (event: SelectChangeEvent<string>) => {
    setFormStructure(null);
    setSelectedPrintTemplateId(event.target.value as string);
  };

  useEffect(() => {
    setSelectedPrintTemplate(
      printTemplates.find(
        (template) => template?.id === selectedPrintTemplateId,
      ),
    );
  }, [selectedPrintTemplateId, printTemplates, setSelectedPrintTemplate]);

  return (
    <SplitPane
      split="vertical"
      minSize={400}
      size={showPreview ? mainPanelSize : window.innerWidth}
      onChange={(size) => {
        setMainPanelSize(size);
        localStorage.setItem('splitPos', String(size));
      }}
    >
      <Pane className={classes.panelForm}>
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <FormControl
              className={`${classes.selectControl} ${classes.formTextItem}`}
              variant="standard"
            >
              <InputLabel>Gabarits</InputLabel>
              <Select
                value={selectedPrintTemplateId}
                onChange={handleSelect}
                displayEmpty
                disabled={rawArticleData.textIsReadOnly || !!articleTemplate}
                variant="standard"
              >
                {printTemplates?.map((printTemplate, index) => (
                  <MenuItem key={index} value={printTemplate?.id}>
                    {printTemplate?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {articleTemplate && (
              <FormControl
                className={`${classes.selectControl} ${classes.formTextItem}`}
                variant="standard"
              >
                <InputLabel>Sujet dans le Gabarit</InputLabel>
                <Select
                  value={articleTemplate.label}
                  disabled={true}
                  variant="standard"
                >
                  <MenuItem value={articleTemplate.label || ''}>
                    {articleTemplate.label}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={8}>
            {formComponents}
          </Grid>
        </Grid>
      </Pane>
      <Pane
        className={classes.panelPreview}
        style={{ width: window.innerWidth - mainPanelSize - 10 }}
      >
        <DrafPreview />
      </Pane>
    </SplitPane>
  );
};
