import { FormControlLabel, Switch, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { Field } from 'react-final-form';
import { GetSubjectQuery_query_subject_Subject_subjectUsers_SubjectUser } from '../../../__generated__/queries-topic';
import { UsersList } from './UsersList';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
    },
    switchLabel: {
      color: theme.palette.common.white,
      fontSize: '1.1rem',
      fontWeight: 'bold',
    },
  });

type ConfidentialityProps = {
  subjectId: string;
  confidentialUsersList: GetSubjectQuery_query_subject_Subject_subjectUsers_SubjectUser[];
  isClassified: boolean;
} & WithStyles<typeof styles>;

const ConfidentialityWithoutStyle: React.FC<ConfidentialityProps> = React.memo(
  ({ classes, subjectId, isClassified, confidentialUsersList }) => (
    <div className={classes.root}>
      {isClassified && (
        <UsersList
          subjectId={subjectId}
          confidentialUsersList={confidentialUsersList}
        />
      )}
      <Field name="isClassified">
        {({ input }) => (
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={input.value}
                value="multiple"
                onChange={() => {
                  input.onChange(!input.value);
                }}
                color="secondary"
              />
            }
            label="Confidentiel"
            classes={{
              label: classes.switchLabel,
            }}
          />
        )}
      </Field>
    </div>
  ),
);

export const Confidentiality = withStyles(styles)(ConfidentialityWithoutStyle);
