import { Avatar, Typography } from '@mui/material';
import CompareArrows from '@mui/icons-material/CompareArrows';
import PeopleIcon from '@mui/icons-material/People';
import React, { FC, useState } from 'react';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import { auth } from '../../../utils/auth';
import { Version } from '../../../utils/rawArticleStatus';
import { SearchUser } from '../SearchUser';
import { useStyles } from './styles';

interface WorkflowStepOwnerProps {
  rawArticleId: string;
  currentStatus: RawArticleStatus;
  versionByStatus: Record<RawArticleStatus, Version>;
}

export const WorkflowStepOwner: FC<WorkflowStepOwnerProps> = ({
  rawArticleId,
  currentStatus,
  versionByStatus,
}) => {
  const classes = useStyles();
  const [isSearchTransferOpen, setIsSearchTransferOpen] = useState(false);
  if (currentStatus === RawArticleStatus.Validation) {
    return (
      <>
        <PeopleIcon />
        <Typography variant="subtitle1" className={classes.labelIconStepOwner}>
          Chefs de service et rédaction en chef
        </Typography>
      </>
    );
  }
  if (currentStatus === RawArticleStatus.Correction) {
    return (
      <>
        <PeopleIcon />
        <Typography variant="subtitle1" className={classes.labelIconStepOwner}>
          Secrétariat de rédaction
        </Typography>
      </>
    );
  }

  if (versionByStatus[currentStatus]) {
    const user = versionByStatus[currentStatus].editor;

    if (user) {
      const isCurrentUserOwner = user.id === auth.user?.id;
      return (
        <>
          {isSearchTransferOpen ? (
            <SearchUser
              versionId={versionByStatus[currentStatus].id}
              close={() => setIsSearchTransferOpen(false)}
              rawArticleId={rawArticleId}
            />
          ) : (
            <>
              {user.avatarUrl && <Avatar src={user.avatarUrl} />}
              <Typography
                className={classes.labelAvatarStepOwner}
                variant="subtitle1"
              >
                {user.name}
              </Typography>
              {isCurrentUserOwner && (
                <CompareArrows
                  onClick={() => setIsSearchTransferOpen(true)}
                  className={classes.icon}
                />
              )}
            </>
          )}
        </>
      );
    }
  }
  return <div />;
};
