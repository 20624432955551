import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  checkboxButton: {
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'white',
  },
  checkboxRoot: {
    color: theme.palette.common.white,
  },
}));
