import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  button: {
    width: '2em',
    height: '2em',
    padding: theme.spacing(1),
  },
}));
