import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(4),
    width: '-webkit-fill-available',
  },
  formField: {
    marginTop: theme.spacing(2),
  },
  formSubtitle: {
    marginTop: theme.spacing(2),
  },
  categoryField: {
    margin: theme.spacing(1, 2, 0),
  },
}));
