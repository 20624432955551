import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import React, { useState } from 'react';
import { GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate } from '../../../__generated__/queries-topic';
import { useUpdatePrintTemplateLabel } from '../../../apollo/queries/printTemplate.topic.graphql';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../components/Loader/Circular';
import { useCommonStyles } from '../common.styles';
import { CustomDialog } from '../Dialog';
import { InputLabel } from '../InputLabel';

interface EditPrintTemplateDialogProps {
  open?: boolean;
  printTemplate: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate;
  onClose: () => void;
}

export const EditPrintTemplateDialog: React.FC<EditPrintTemplateDialogProps> = ({
  open = false,
  printTemplate,
  onClose,
}) => {
  const commonClasses = useCommonStyles();
  const { pushNotification } = useNotification();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [label, setLabel] = useState(printTemplate.label);
  const updatePrintTemplateLabel = useUpdatePrintTemplateLabel(
    printTemplate.id,
  );

  const handleUpdatePrintTemplate = async () => {
    setIsSubmiting(true);
    try {
      const { errors } = await updatePrintTemplateLabel(label.trim());

      if (errors) {
        throw Error(errors[0].message);
      }

      pushNotification({
        message: `Le label gabarit ${label.trim()} a été mise à jour avec succès.`,
        type: NotificationTypeEnum.success,
      });
      onClose();
    } catch (err) {
      pushNotification({
        message:
          'Un problème est survenu lors de la mise à jour du label gabarit',
        type: NotificationTypeEnum.error,
      });
    }
    setIsSubmiting(false);
  };

  return (
    <CustomDialog
      open={open}
      aria-labelledby="dialog-edit-printTemplate"
      maxWidth="md"
    >
      <DialogTitle id="dialog-edit-printTemplate">
        Editer Gabarit {printTemplate.label}
      </DialogTitle>
      <DialogContent>
        <InputLabel value={label} onChange={setLabel} disabled={isSubmiting} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button
          className={commonClasses.buttonWithLeftIcon}
          onClick={handleUpdatePrintTemplate}
          color="primary"
          autoFocus
          disabled={
            !(label && printTemplate.label !== label.trim() && !isSubmiting)
          }
        >
          {isSubmiting && <CircularLoader size={LoaderSizes.xsmall} />}Valider
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
