import { Head } from 'components/Head';
import { parse } from 'query-string';
import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CircularLoader } from '../../components/Loader/Circular';
import { SubjectForm } from './Form';
import { SubjectFormModel, apiToForm } from './Form/dataTransformer';
import { useGetSubject } from './getSubjectQuery.topic.graphql';

export const CreateSubject: FC = () => {
  const location = useLocation();
  const parsedSearch = parse(location.search) as { [key: string]: string };
  const { unit } = parsedSearch;
  const initialValuesFromApi: SubjectFormModel = {
    ...apiToForm({ subject: null }),
    unit,
  };
  return (
    <SubjectForm
      titleDisabled={false}
      initialValues={initialValuesFromApi}
      isClassified={false}
    />
  );
};

export const EditSubject: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: subjectData,
    loading: subjectLoading,
    refetch,
  } = useGetSubject({ id });

  if (subjectLoading) {
    return <CircularLoader />;
  }
  if (!subjectData || !subjectData.subject) {
    return <div>ERROR</div>;
  }

  const initialValuesFromApi: SubjectFormModel = apiToForm(subjectData);
  const { attachments } = subjectData.subject;

  return (
    <>
      <Head title={subjectData.subject.title} />
      <SubjectForm
        titleDisabled={!subjectData.subject.isTitleWritable}
        refetchSubject={refetch}
        initialValues={initialValuesFromApi}
        attachments={attachments}
        confidentialUsersList={subjectData.subject.subjectUsers}
        isClassified={subjectData.subject.isClassified || false}
      />
    </>
  );
};
