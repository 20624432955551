import * as React from 'react';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: theme.spacing(11.75),
      right: 30,
      width: 150,
      border: `1px solid ${theme.palette.grey[100]}`,
      zIndex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    rootShift: {
      right: 330,
      transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    imageContent: {
      position: 'relative',
      width: '100%',
      height: 130,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mask: {
      overflow: 'hidden',
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      opacity: 0.8,
    },
    imageZoom: {
      position: 'absolute',
      opacity: 0.5,
      pointerEvents: 'none',
    },
    controller: {
      height: 30,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    zoomBtn: {
      width: 20,
      textAlign: 'center',
      color: theme.palette.common.white,
      margin: 0,
    },
    thumb: {
      width: 110,
      '& > p': {
        width: 15,
        height: 30,
        backgroundColor: theme.palette.common.white,
        margin: 0,
        cursor: 'ew-resize',
      },
    },
  });

type ZoomCommandProps = {
  thumbnail: string;
  zoomMaxScale?: number;
  onZoomChange: (value: number) => void;
  showInfoDrawer: boolean;
} & WithStyles<typeof styles>;

interface ZoomCommandState {
  width: string;
  height: string;
  maskScale: number;
  x: number;
  y: number;
}

const ZoomCommandWithoutStyle: React.FunctionComponent<ZoomCommandProps> = ({
  classes,
  thumbnail,
  onZoomChange,
  zoomMaxScale = 3,
  showInfoDrawer,
}) => {
  const imageEl = React.useRef<HTMLImageElement>(null);
  const [zoomState, setZoom] = React.useState<ZoomCommandState>({
    width: '',
    height: '',
    maskScale: 1,
    x: 0,
    y: 0,
  });

  const imageLoaded = () => {
    const baseHeight = 130;
    const baseWidth = 150;
    if (imageEl.current) {
      const currentImageHeight = imageEl.current.height;
      const currentImageWidth = imageEl.current.width;
      if (currentImageWidth > currentImageHeight) {
        setZoom({
          width: `${baseWidth}px`,
          height: `${(currentImageHeight * baseWidth) / currentImageWidth}px`,
          maskScale: zoomState.maskScale,
          x: zoomState.x,
          y: zoomState.y,
        });
      } else if (currentImageHeight > currentImageWidth) {
        setZoom({
          width: `${(currentImageWidth * baseHeight) / currentImageHeight}px`,
          height: `${baseHeight}px`,
          maskScale: zoomState.maskScale,
          x: zoomState.x,
          y: zoomState.y,
        });
      }
    }
  };

  const normalizeBetweenTwoRanges = (
    val: number,
    minVal: number,
    maxVal: number,
    newMin: number,
    newMax: number,
  ) => {
    return newMin + ((val - minVal) * (newMax - newMin)) / (maxVal - minVal);
  };

  const handleDrag = (event: DraggableEvent, data: DraggableData) => {
    const normalizedDragValue = data.x / 95;
    const zoomValue = normalizeBetweenTwoRanges(
      normalizedDragValue,
      0,
      1,
      1,
      zoomMaxScale,
    );
    onZoomChange(zoomValue);
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootShift]: showInfoDrawer,
      })}
      data-testid="zoom-command"
    >
      <div className={classes.imageContent}>
        <img
          ref={imageEl}
          src={thumbnail}
          className={classes.imageZoom}
          style={{
            width: zoomState.width,
            height: zoomState.height,
          }}
          onLoad={imageLoaded}
        />
      </div>
      <div className={classes.controller}>
        <Typography className={classes.zoomBtn}>-</Typography>
        <div className={classes.thumb}>
          <Draggable
            axis="x"
            bounds={{ left: 0, right: 95, top: 0, bottom: 0 }}
            onDrag={handleDrag}
          >
            <p />
          </Draggable>
        </div>
        <Typography className={classes.zoomBtn}>+</Typography>
      </div>
    </div>
  );
};

export const ZoomCommand = withStyles(styles)(ZoomCommandWithoutStyle);
