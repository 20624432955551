import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion } from '../../../../__generated__/queries-topic';
import { Assignment } from '../../../../components/Assignment';
import { AssignmentFormModel } from '../../Form/dataTransformer';
import {
  createWebDuplicate,
  duplicateArticle,
  useRawArticleCreator,
} from '../createRawArticleVersionMutation.topic.graphql';
import { useStyles } from './styles';

interface DuplicateNewAssignmentProps {
  subjectId: string;
  assignmentOriginIndex: number;
  refetchSubject?: () => void;
  closeDialog: (e: React.SyntheticEvent) => void;
  rawArticleId: string;
  versionSelected: GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion | null;
  assignmentIndex: number;
  notificateSuccess: () => void;
}

export const DuplicateNewAssignment: React.FC<DuplicateNewAssignmentProps> = ({
  refetchSubject,
  closeDialog,
  subjectId,
  rawArticleId,
  versionSelected,
  assignmentIndex,
  notificateSuccess,
  assignmentOriginIndex,
}) => {
  const classes = useStyles();
  const [createArticle] = useRawArticleCreator({
    onCompleted: async () => {
      if (refetchSubject) {
        await refetchSubject();
        notificateSuccess();
      }
    },
  });

  return (
    <FormSpy subscription={{ values: true }}>
      {({ form, values }: any) => {
        const assignment: AssignmentFormModel =
          values.assignments[assignmentIndex];
        const assignmentOrigin: AssignmentFormModel =
          values.assignments[assignmentOriginIndex];

        const isForWeb =
          assignment &&
          assignment.supportId &&
          assignment.supportId.startsWith('web');
        return (
          <>
            <DialogTitle className={classes.dialogTitle}>
              Ajout d'un nouveau support pour la duplication
            </DialogTitle>
            <DialogContent>
              <Assignment
                unitId={values.unit}
                assignment={assignment}
                assignmentIndex={assignmentIndex}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Annuler</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  if (isForWeb) {
                    createWebDuplicate(
                      form,
                      values,
                      versionSelected,
                      rawArticleId,
                    );
                  } else {
                    if (versionSelected !== null) {
                      const payload = duplicateArticle(
                        subjectId,
                        versionSelected,
                        assignment,
                        assignmentOrigin,
                        false,
                      );
                      createArticle({ variables: payload });
                    }
                  }
                  closeDialog(e);
                }}
              >
                {isForWeb ? 'DUPLIQUER POUR LE WEB' : 'DUPLIQUER'}
              </Button>
            </DialogActions>
          </>
        );
      }}
    </FormSpy>
  );
};
