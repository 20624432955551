import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: 'white',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
  },
  photoBox: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey['100'],
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  mainForm: {
    width: '100%',
    paddingTop: theme.spacing(3),
  },
  addSupport: {
    display: 'flex',
    cursor: 'pointer',
  },
  subjectCategory: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    '& > div': {
      paddingBottom: theme.spacing(2),
    },
  },
  otherFields: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    backgroundColor: 'transparent',
    '& > div': {
      paddingBottom: theme.spacing(2),
    },
  },
}));
