import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: '40vw',
    minHeight: '300px',
    padding: `${theme.spacing(1)} 0`,
  },
  dialogContent: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  dialogActions: {
    paddingRight: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  bigIcon: {
    fontSize: '5rem',
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  dropzoneContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '40vh',
    maxHeight: '300px',
    minHeight: '200px',
    color: 'rgb(112 156 210)',
  },
  dropzone: {
    color: 'rgb(176 180 185)',
    '&:hover': {
      color: 'rgb(22 111 222)',
      cursor: 'pointer',
    },
    '&::-moz-drag-over': {
      color: 'rgb(22 111 222)',
      cursor: 'pointer',
    },
  },
}));
