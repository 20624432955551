import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  input: {
    width: '100%',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
