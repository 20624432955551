import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { VERSION_FRAGMENT } from 'pages/ArticleForm/version.fragment.topic.graphql';
import {
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables,
} from '../../__generated__/queries-topic';

const UPDATE_ASSIGNMENT = gql`
  mutation UpdateAssignment(
    $where: AssignmentUniqueFilterInput!
    $data: AssignmentUpdateInput!
  ) {
    updateAssignment(where: $where, data: $data) {
      id
      rawArticle {
        id
        versions(first: 1, where: { nextVersion_is_null: true }) {
          ...ArticleForm_RawArticleVersionFragment
        }
        versionList: versions(first: 100, orderBy: _id_DESC) {
          id
          status
          editorId
          editor {
            id
            name
            avatarUrl
          }
        }
      }
    }
  }
  ${VERSION_FRAGMENT}
`;

export const useAssignmentUpdate = (
  options?: MutationHookOptions<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >,
) =>
  useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(
    UPDATE_ASSIGNMENT,
    options,
  );
