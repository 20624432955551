import React, { FC } from 'react';
import ReactDOM from 'react-dom';

interface PrintPortalProps {
  children: React.ReactNode;
}

export const PrintPortal: FC<PrintPortalProps> = ({ children }) => {
  const containerEl = document.querySelector(
    '.print-portal-wrapper',
  ) as HTMLElement;
  return containerEl && ReactDOM.createPortal(children, containerEl);
};
