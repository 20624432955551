import { SearchPhotosQuery_searchPhotos_Photo } from '__generated__/queries-photo';
import { getPreview, PreviewSize } from 'utils/getPreview';
import { CustomReactImageGalleryItem } from '../OneFullscreenGallery';

export const getImagesForGallery = (
  photos: SearchPhotosQuery_searchPhotos_Photo[],
): CustomReactImageGalleryItem[] =>
  photos.map((photo, i) => ({
    original: getPreview(photo, PreviewSize.XL) || '',
    thumbnail: getPreview(photo) || '',
    index: i,
    fallbacks: [{ src: photo.downloadUrl }],
  }));
