import React from 'react';
import { MegadraftIcons } from 'megadraft';

export const bodyDraftConfig = [
  {
    type: 'inline',
    label: 'B',
    style: 'BOLD',
    icon: () => (
      <div title="Gras">
        <MegadraftIcons.BoldIcon />
      </div>
    ),
  },
  {
    type: 'inline',
    label: 'I',
    style: 'ITALIC',
    icon: () => (
      <div title="Italique">
        <MegadraftIcons.ItalicIcon />
      </div>
    ),
  },
  { type: 'separator' },
  {
    type: 'block',
    label: 'H2',
    style: 'header-two',
    icon: () => (
      <div title="Intertitre">
        <MegadraftIcons.H2Icon />
      </div>
    ),
  },
  {
    type: 'block',
    label: 'UL',
    style: 'unordered-list-item',
    icon: () => (
      <div title="Liste à puces">
        <MegadraftIcons.ULIcon />
      </div>
    ),
  },
  {
    type: 'block',
    label: 'OL',
    style: 'ordered-list-item',
    icon: () => (
      <div title="List à chiffre">
        <MegadraftIcons.OLIcon />
      </div>
    ),
  },
];

export const leadDraftConfig = [
  {
    type: 'inline',
    label: 'B',
    style: 'BOLD',
    icon: () => (
      <div title="Gras">
        <MegadraftIcons.BoldIcon />
      </div>
    ),
  },
  {
    type: 'inline',
    label: 'I',
    style: 'ITALIC',
    icon: () => (
      <div title="Italique">
        <MegadraftIcons.ItalicIcon />
      </div>
    ),
  },
];
