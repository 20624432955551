import { ApolloQueryResult } from '@apollo/client';
import RefreshIcon from '@mui/icons-material/RefreshTwoTone';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import React from 'react';
import {
  GetPrintHeadingQuery,
  GetPrintHeadingQuery_query_printHeading_PrintHeading,
  GetPrintHeadingQueryVariables,
} from '../../../__generated__/queries-topic';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../components/Loader/Circular';
import { createHrefFromPrintHeading, ViewVariant } from '../utils';
import { useStyles } from './styles';

interface ToolbarProps {
  loading: boolean;
  printHeading?: GetPrintHeadingQuery_query_printHeading_PrintHeading | null;
  refetch: (
    variables?: Partial<GetPrintHeadingQueryVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetPrintHeadingQuery>>;
  viewVariant: ViewVariant;
  onViewChange: (view: ViewVariant) => void;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  loading,
  printHeading,
  refetch,
  viewVariant = 'grid',
  onViewChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.toolbarContainer}>
      {printHeading && (
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link
            color="inherit"
            href={createHrefFromPrintHeading(printHeading).unitHref}
          >
            {printHeading.printIssue.printPublication.brand?.title}
          </Link>
          <Link
            color="inherit"
            href={createHrefFromPrintHeading(printHeading).printPublicationHref}
          >
            {printHeading.printIssue.printPublication.title}
          </Link>
          <Link
            color="primary"
            href={createHrefFromPrintHeading(printHeading).printIssueHref}
          >
            {printHeading.printIssue.title}
          </Link>
          <Typography color="primary">{printHeading.title}</Typography>
        </Breadcrumbs>
      )}
      <div className={classes.toolbarActions}>
        <Typography>
          {printHeading?.printTemplateCount || 0} Gabarit(s)
        </Typography>
        <IconButton aria-label="delete" onClick={() => refetch()} size="large">
          {loading ? (
            <CircularLoader size={LoaderSizes.xsmall} />
          ) : (
            <RefreshIcon />
          )}
        </IconButton>
        <ToggleButtonGroup
          size="small"
          value={viewVariant}
          onChange={(_, view: ViewVariant) => onViewChange(view)}
          exclusive
        >
          <ToggleButton value="grid">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="list">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};
