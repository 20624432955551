import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  LocationPage_DeletePrintIssueMutation,
  LocationPage_DeletePrintIssueMutationVariables,
  LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading,
} from '../../../../__generated__/queries-topic';

const disconnectPrintHeadingFromPrintTemplate = (
  PrintTemplate: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading['printTemplates'],
  i: number,
) => {
  return PrintTemplate.length < 1
    ? ''
    : PrintTemplate.reduce(
        (prev: string, { id }: { id: string }, j: number) => `${prev}
      delete${i}x${j}: deletePrintHeadingPrintTemplate(
        where: {id: "${id}"},
      ) {
        status
      }`,
        '',
      );
};

// TODO - this is a temporary hack in order to delete multiple
// printHeadings, when BatchMutation will be implemented change the DELETE_PRINT_ISSUE mutation.
// See : https://www.opencrud.org/#sec-Mutationa-Batch-Operations
const deletePrintIssuesAndHeadings = (
  printHeadings?: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading[],
): string => {
  if (!printHeadings) {
    return '';
  }
  return printHeadings
    ? printHeadings.reduce(
        (
          prev: string,
          {
            id,
            printTemplates,
          }: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading,
          i: number,
        ) => {
          return `${prev}
          ${disconnectPrintHeadingFromPrintTemplate(printTemplates, i)}
      delete${i}: deletePrintHeading(
        where: {id: "${id}"},
      ) {
        id
      }`;
        },
        '',
      )
    : '';
};

const DELETE_PRINT_ISSUE = (
  printHeadings?: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading[],
) => {
  const headingsToDelete = deletePrintIssuesAndHeadings(printHeadings);
  return gql`
    mutation LocationPage_DeletePrintIssue($id: ID!) {
      ${headingsToDelete}
      deletePrintIssue(where: {id: $id}) {
        id
      }
    }
  `;
};

export const usePrintIssueDeleter = (
  printHeadings?: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading[],
  options?: Omit<MutationHookOptions, 'variables'>,
) =>
  useMutation<
    LocationPage_DeletePrintIssueMutation,
    LocationPage_DeletePrintIssueMutationVariables
  >(DELETE_PRINT_ISSUE(printHeadings), options);
