import { Button, DialogActions, DialogContent } from '@mui/material';
import { RawUser } from '@prismamedia/one-user';
import React, { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { UserInput } from '../../Field/UserInput';
import { TextField } from '../../FinalFormMaterial/TextField';
import { useStyles } from './styles';

interface EmailFormProps {
  onCancel: (values?: string) => void;
  onSubmit: (values: EmailFormModel) => void;
}

export interface EmailFormModel {
  message: string;
  selectedUsers: RawUser[];
}

export const EmailForm: FC<EmailFormProps> = ({ onSubmit, onCancel }) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState<RawUser[]>([]);

  return (
    <Form
      initialValues={{ message: '' }}
      onSubmit={
        (({ message }: EmailFormModel) =>
          onSubmit({ message, selectedUsers })) as any
      }
    >
      {({ form }) => (
        <>
          <DialogContent className={classes.root}>
            <UserInput
              onChange={setSelectedUsers}
              value={selectedUsers}
              textFieldProps={{
                placeholder: "Entrez le nom ou l'email du destinataire",
              }}
              multiple
            />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                form.submit();
              }}
              className={classes.commentForm}
            >
              <Field
                component={TextField}
                fullWidth
                autoFocus
                margin="dense"
                type="text"
                label="Ajouter un commentaire (facultatif)"
                name="message"
                multiline
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCancel()} color="primary">
              Annuler
            </Button>
            <Button
              onClick={form.submit}
              color="primary"
              disabled={selectedUsers.length === 0}
              variant="contained"
            >
              Envoyer
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
};
