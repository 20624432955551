import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'calc(100% + 2px)',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    marginTop: theme.spacing(4),
    // Because of borders in tableBody. Else, scrollbar appears in table
    width: 'calc(100% - 2px)',
  },
  tableBody: {
    backgroundColor: theme.palette.common.white,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.divider,
    '& td': {
      borderColor: theme.palette.divider,
    },
  },
  tableHead: {
    '& th': {
      fontSize: '0.85rem',
    },
  },
  tableCellSmall: {
    width: '1%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: '1',
    flexDirection: 'column',
    textAlign: 'center',
  },
  tooltip: {
    width: 200,
    fontSize: 12,
  },
  alignCenter: {
    textAlign: 'center',
  },
  noContentText: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  subjectLink: {
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.grey[600],
    },
    marginRight: 5,
  },
  greenRow: {
    backgroundColor: '#EFF9F8',
  },
  whiteRow: {
    backgroundColor: '#FFFFFF',
  },
  comment: {
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  linkPointer: {
    cursor: 'pointer',
  },
  tooltipCss: {
    display: 'inline-block',
  },
}));
