import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { lighten } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    textAlign: 'center',
  },
  assignment: {
    cursor: 'pointer',
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 5,
    border: `2px solid ${theme.palette.grey[300]}`,
    '&:hover, &.selected': {
      border: `2px solid ${theme.palette.primary.light}`,
      backgroundColor: `${lighten(theme.palette.primary.light, 0.85)}`,
    },
    marginBottom: 10,
  },
  addSupport: {
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.primary.light,
    alignItems: 'center',
  },
  dialogActions: {
    justifyContent: 'space-between',
    margin: '8px 24px',
  },
}));
