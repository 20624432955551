import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  gridList: {
    height: '100vh',
    marginBottom: 50,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridItemFullHeight: {
    height: '100vh',
  },
  gridItemHalfHeight: {
    height: '50%',
  },
  photoImageWrapper: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    paddingBottom: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
