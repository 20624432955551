import Grid from '@mui/material/Grid';
import { parse } from 'query-string';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar } from '../../components/AppBar';
import { addUrlFilters } from '../../utils/addUrlFilters';
import { auth } from '../../utils/auth';
import { getUrlFilter } from '../../utils/getUrlFilter';
import { PrintHeadingList } from './PrintHeading';
import { PrintIssueList } from './PrintIssue';
import { PrintPublicationList } from './PrintPublication';
import { useStyles } from './styles';

export const LocationPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = parse(location.search) as { [key: string]: string };
  const unitId = params.unit;
  const addFilters = addUrlFilters(history, location);
  const getFilter = getUrlFilter(location);
  const printPublicationId = getFilter('printPublication');
  const printIssueId = getFilter('printIssue');

  return (
    <div className={classes.root}>
      <AppBar
        title="Gestion des parutions et des têtières."
        avatarUrl={auth.user?.avatarUrl}
      />
      <div className={classes.toolbar} />
      <Grid container spacing={2} className={classes.columnContainer}>
        <PrintPublicationList
          unitId={unitId}
          printPublicationId={printPublicationId}
          addFilters={addFilters}
        />
        <PrintIssueList
          unitId={unitId}
          printIssueId={printIssueId}
          printPublicationId={printPublicationId}
          addFilters={addFilters}
        />
        <PrintHeadingList printIssueId={printIssueId} unitId={unitId} />
      </Grid>
    </div>
  );
};
