import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme) => ({
  statusPaper: {
    color: 'white',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  statusPaperDisable: {
    backgroundColor: `${grey[500]} !important`,
    cursor: 'not-allowed',
  },
  statusWrapperPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  status: {
    fontWeight: 'bold',
  },
  currentStatus: {
    fontWeight: 'bold',
    borderBottom: '2px solid white',
  },
  statusesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '500px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '80%',
  },
  statusOwnerWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '25vw',
    height: '100vh',
  },
  drawerModal: {
    '@media print': {
      display: 'none',
    },
  },
  titleWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
