import { gql } from '@apollo/client';

const RawArticleFragment = gql`
  fragment RawArticleItem on RawArticle {
    id
    versions(where: { nextVersion_is_null: true }, first: 1) {
      id
      title
      status
      createdAt
      editor {
        id
        name
      }
    }
  }
`;

export const ASSIGNMENT_FRAGMENT = gql`
  fragment AssignmentItem on Assignment {
    id
    printTemplate {
      id
    }
    articleTemplate {
      id
    }
    mediaSearchStatus
    rawArticle {
      ...RawArticleItem
    }
    printHeading {
      id
      title
      autoPrototype
      printTemplates(first: 1, where: { active: true }) {
        printTemplate {
          type
        }
      }
      printIssue {
        id
        title
        printPublication {
          id
          title
        }
      }
    }
    printIssue {
      id
      title
      printPublication {
        id
        title
      }
    }
    printPublication {
      id
      title
    }
    website {
      id
      title
      key
    }
    subject {
      id
    }
  }
  ${RawArticleFragment}
`;
