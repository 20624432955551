import { ImageLoader, ImageLoaderFallback } from '@prismamedia/one-components';
import clsx from 'clsx';
import { FC, ReactNode, useEffect, useState } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import {
  default as LibImageGallery,
  ReactImageGalleryItem,
} from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  CallbackDeleteType,
  GalleryToolBar,
  GalleryToolsType,
} from './GalleryToolBar';
import { InfoDrawer } from './InfoDrawer';
import { ZoomCommand } from './ZoomCommand';
import { useStyles } from './styles';

export interface CustomReactImageGalleryItem extends ReactImageGalleryItem {
  index: number;
  fallbacks?: ImageLoaderFallback[];
}

interface ImageGalleryProps {
  photos: CustomReactImageGalleryItem[];
  startIndex?: number;
  onSlide?: (index: number) => void;
  zoomMaxScale?: number;
  leftToolBarTools?: GalleryToolsType[];
  centerToolBarTools?: GalleryToolsType[];
  rightToolBarTools?: GalleryToolsType[];
  renderInfoDrawer?: (index: number) => ReactNode;
  lazyLoad?: boolean;
  slideDuration?: number;
  isInfinite?: boolean;
}

interface ImageGalleryState {
  selectedIndex: number;
  images: CustomReactImageGalleryItem[];
  zoomValue: number;
  x: number;
  y: number;
  showZoom: boolean;
  showInfoDrawer: boolean;
}

export const OneFullscreenGallery: FC<ImageGalleryProps> = ({
  photos,
  zoomMaxScale,
  startIndex = 0,
  onSlide,
  leftToolBarTools = [],
  centerToolBarTools = [],
  rightToolBarTools = [],
  renderInfoDrawer,
  lazyLoad,
  slideDuration,
  isInfinite,
}) => {
  const classes = useStyles();
  const [galleryState, setGalleryState] = useState<ImageGalleryState>({
    selectedIndex: startIndex,
    images: photos,
    zoomValue: 1,
    x: 0,
    y: 0,
    showZoom: false,
    showInfoDrawer: false,
  });

  useEffect(() => {
    setGalleryState((prevState: ImageGalleryState) => ({
      ...prevState,
      images: photos,
    }));
  }, [photos]);

  useEffect(() => {
    if (photos?.length && galleryState.selectedIndex >= photos.length) {
      setGalleryState((prevState: ImageGalleryState) => ({
        ...prevState,
        selectedIndex: photos.length - 1,
      }));
    }
  }, [galleryState.selectedIndex, photos]);

  const deleteImageGallery = (
    deleteIndex: number,
    callBack?: CallbackDeleteType,
  ) => {
    setGalleryState((prevState: ImageGalleryState) => ({
      ...prevState,
      images: prevState.images.filter(
        (_, index: number) => index !== deleteIndex,
      ),
      selectedIndex:
        prevState.images.length - 1 === deleteIndex ? 0 : deleteIndex,
    }));
    callBack && callBack(galleryState.images.length - 1);
  };

  const enableZoom = () => {
    setGalleryState((prevState: ImageGalleryState) => ({
      ...prevState,
      showZoom: !prevState.showZoom,
      zoomValue: 1,
      x: 0,
      y: 0,
    }));
  };

  const showDrawer = () => {
    setGalleryState((prevState: ImageGalleryState) => ({
      ...prevState,
      showInfoDrawer: !prevState.showInfoDrawer,
    }));
  };

  const handleDrag = (event: DraggableEvent, data: DraggableData) => {
    setGalleryState((prevState: ImageGalleryState) => ({
      ...prevState,
      x: prevState.x + data.deltaX,
      y: prevState.y + data.deltaY,
    }));
  };

  const renderItem = (item: ReactImageGalleryItem) => {
    const customItem = item as CustomReactImageGalleryItem;
    const { x, y, zoomValue, selectedIndex } = galleryState;
    const transform = `
      translate(${x}px,${y}px)
      scale(${zoomValue})
    `;

    return customItem.index === selectedIndex ? (
      <div className={classes.imageWrapper}>
        <DraggableCore onDrag={handleDrag} disabled={zoomValue === 1}>
          <div
            style={{ transform, cursor: zoomValue === 1 ? 'default' : 'move' }}
            className={classes.imageWrapper}
          >
            <ImageLoader
              className={classes.image}
              src={customItem.original}
              fallbacks={customItem.fallbacks}
              fitting="contain"
              loaderSize={80}
            />
          </div>
        </DraggableCore>
      </div>
    ) : (
      <div data-testid="image-gallery" className={classes.imageWrapper}>
        <ImageLoader
          className={classes.image}
          src={item.original}
          fitting="contain"
          loaderSize={80}
        />
      </div>
    );
  };

  const onZoomChange = (zoomValue: number) => {
    if (zoomValue === 1) {
      setGalleryState((prevState: ImageGalleryState) => ({
        ...prevState,
        zoomValue,
        x: 0,
        y: 0,
      }));
    } else {
      setGalleryState((prevState: ImageGalleryState) => ({
        ...prevState,
        zoomValue,
      }));
    }
  };

  return (
    <>
      {galleryState.showZoom && (
        <ZoomCommand
          thumbnail={
            galleryState.images[galleryState.selectedIndex].thumbnail || ''
          }
          onZoomChange={onZoomChange}
          zoomMaxScale={zoomMaxScale}
          showInfoDrawer={galleryState.showInfoDrawer}
        />
      )}
      {galleryState.images ? (
        <>
          <div
            className={clsx(classes.galleryContent, {
              [classes.galleryContentShift]: galleryState.showInfoDrawer,
            })}
          >
            <div className={classes.carouselContainer}>
              <LibImageGallery
                items={galleryState.images}
                showPlayButton={false}
                startIndex={galleryState.selectedIndex}
                onSlide={(index) => {
                  setGalleryState((prev) => ({
                    ...prev,
                    selectedIndex: index,
                  }));
                  if (onSlide) {
                    onSlide(index);
                  }
                }}
                lazyLoad={lazyLoad}
                showFullscreenButton={!galleryState.showZoom}
                showNav={!galleryState.showZoom}
                renderItem={renderItem}
                slideDuration={slideDuration}
                infinite={isInfinite}
                renderThumbInner={(item) => {
                  const customItem = item as CustomReactImageGalleryItem;
                  return (
                    <ImageLoader
                      src={item.thumbnail}
                      fallbacks={customItem.fallbacks}
                      className={clsx(
                        classes.thumbnail,
                        customItem.index === galleryState.selectedIndex &&
                          classes.thumbnailSelected,
                      )}
                    />
                  );
                }}
              />
            </div>
            {(leftToolBarTools.length > 0 ||
              centerToolBarTools.length > 0 ||
              rightToolBarTools.length > 0) && (
              <GalleryToolBar
                leftTools={leftToolBarTools}
                centerTools={centerToolBarTools}
                rightTools={rightToolBarTools}
                enableZoom={enableZoom}
                showDrawer={showDrawer}
                selectedIndex={galleryState.selectedIndex}
                deleteImageGallery={deleteImageGallery}
              />
            )}
          </div>
          <InfoDrawer
            showDrawer={showDrawer}
            drawerOpen={galleryState.showInfoDrawer}
          >
            {renderInfoDrawer && renderInfoDrawer(galleryState.selectedIndex)}
          </InfoDrawer>
        </>
      ) : null}
    </>
  );
};
