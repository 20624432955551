import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(5),
  },
  paperWrapper: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  selectedPaper: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    backgroundColor: theme.palette.primary.light,
  },
  auto: {
    paddingTop: 4,
    paddingRight: 6,
  },
  assignmentButton: {
    height: '36px',
    width: '36px',
  },
  title: {
    flexGrow: 1,
  },
}));
