import Popover, { PopoverOrigin } from '@mui/material/Popover';
import React, { FC, MouseEvent, useState } from 'react';
import { useStyles } from './styles';

interface TooltipProps {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  TooltipedElement: (state: { hover: boolean }) => JSX.Element;
  TooltipContent: () => JSX.Element;
}

export const Tooltip: FC<TooltipProps> = ({
  TooltipedElement,
  TooltipContent,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.target as HTMLElement);

  const handlePopoverClose = () => setAnchorEl(undefined);

  return (
    <>
      <span onMouseOver={handlePopoverOpen} onMouseOut={handlePopoverClose}>
        <TooltipedElement hover={!!anchorEl} />
      </span>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <TooltipContent />
      </Popover>
    </>
  );
};
