import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  children: {
    flex: '1 1 auto',
  },
  subjectInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    textOverflow: 'ellipsis',
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(9),
    borderLeft: 'solid 1px',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    marginLeft: '10px',
  },
  appBar: {
    display: 'flex',
    height: theme.spacing(9),
  },
  assignment: {
    marginLeft: '10px',
  },
  assignmentTitle: {
    color: theme.palette.common.white,
  },
}));
