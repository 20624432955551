import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `2px solid ${theme.palette.common.black}`,
    fontSize: '0.8em',
  },
  disabled: {
    color: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
  },
  hoverable: {
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
      borderColor: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
  },
}));
