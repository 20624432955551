import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import React from 'react';
import { useStyles } from './styles';

export enum LoaderSizes {
  big = 150,
  small = 50,
  xsmall = 25,
}

interface CircularLoaderProps {
  size?: LoaderSizes;
  fullScreen?: boolean;
  color?: CircularProgressProps['color'];
}

export const CircularLoader: React.FC<CircularLoaderProps> = ({
  size,
  fullScreen,
  color = 'primary',
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} ${fullScreen && classes.fullScreen}`}>
      <CircularProgress size={size || LoaderSizes.big} color={color} />
    </div>
  );
};
