import { gql, useQuery } from '@apollo/client';
import {
  GetPrintHeadingByIdQuery,
  GetPrintHeadingByIdQueryVariables,
} from '../../__generated__/queries-topic';

const GET_PRINT_HEADING = gql`
  query GetPrintHeadingById($id: ID!) {
    printHeading(where: { id: $id }) {
      title
      createdAt
      assignments(first: 20) {
        id
        articleTemplate {
          id
        }
        subject {
          id
          title
        }
        rawArticle {
          id
          lastVersion {
            id
            title
            lead
            body
            signature
            leftOver
            status
            textIsReadOnly
          }
        }
      }
      printTemplates(first: 50) {
        id
        active
        printTemplate {
          id
          label
          type
          articleTemplates(first: 10) {
            id
            label
            block
            fields {
              id
              name
              type
              data
              enabled
              page {
                offset
                number
                coordinates {
                  x
                  y
                  width
                  height
                  rotation
                }
              }
            }
            assignments(first: 1, where: { printHeading: { id: $id } }) {
              id
              subject {
                id
                title
              }
              rawArticle {
                id
                lastVersion {
                  id
                  status
                  body
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetPrintHeadingById = (
  variables: GetPrintHeadingByIdQueryVariables,
) =>
  useQuery<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables>(
    GET_PRINT_HEADING,
    { variables },
  );
