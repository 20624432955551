import { Typography } from '@mui/material';
import React, { FC } from 'react';
import notFoundSvg from '../../assets/svg/undraw_lost_bqr2.svg';
import { useStyles } from './styles';

interface ContentNotFoundProps {
  title: string;
}

export const ContentNotFound: FC<ContentNotFoundProps> = ({
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}
      {children}
      <img src={notFoundSvg} alt="not-found" />
    </div>
  );
};
