import { ButtonBase, Checkbox } from '@mui/material';
import { ButtonBaseProps } from '@mui/material/ButtonBase';
import { CheckboxProps } from '@mui/material/Checkbox';
import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './styles';

export interface CheckboxButtonProps {
  checkboxProps?: CheckboxProps;
  buttonProps?: ButtonBaseProps;
  checked?: boolean;
  value?: string;
  onChange: (checked: boolean, value?: string) => void;
}

export const CheckboxButton: FC<CheckboxButtonProps> = ({
  children,
  buttonProps,
  checkboxProps,
  value,
  onChange,
  checked: propsChecked,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (propsChecked !== undefined && propsChecked !== checked) {
      setChecked(propsChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsChecked, setChecked]);

  return (
    <ButtonBase
      disableRipple
      className={classes.button}
      onClick={() => {
        const newChecked = !checked;
        setChecked(newChecked);
        onChange(checked, value);
      }}
      {...buttonProps}
    >
      <Checkbox checked={checked} value={value} {...checkboxProps} />
      {children}
    </ButtonBase>
  );
};
