import FaceIcon from '@mui/icons-material/Face';
import {
  Avatar,
  Checkbox,
  debounce,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { RawUser } from '@prismamedia/one-user';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useUsersGetter } from '../../../apollo/queries/users.auth.graphql';
import { ChipList } from '../../List/ChipList';
import { useStyles } from './styles';

interface UserInputProps {
  onChange?: (users: RawUser[]) => void;
  onDelete?: (userId: string, users: RawUser[]) => void;
  defaultValue?: string;
  value: RawUser[];
  textFieldProps?: TextFieldProps;
  multiple?: boolean;
  textBeforeChips?: string;
  columns?: ('name' | 'roles' | 'email')[];
}

interface UserInputState {
  stateValue: RawUser[];
  users: RawUser[];
  page: number;
  rowsPerPage: number;
}

export const UserInput: FC<UserInputProps> = ({
  value,
  textFieldProps,
  multiple,
  textBeforeChips,
  columns = ['name', 'email'],
  onChange,
  onDelete,
}) => {
  const classes = useStyles();
  const getUsers = useUsersGetter();
  const [
    { stateValue = [], users, page, rowsPerPage },
    setState,
  ] = useState<UserInputState>({
    stateValue: value,
    users: [],
    page: 0,
    rowsPerPage: 10,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, stateValue: value }));
  }, [value]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const handleChange = debounce(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const eventValue = event.target.value;
      const newUsers = await getUsers(eventValue);
      setState((prev) => ({ ...prev, users: newUsers }));
    },
    1000,
  );

  const handleDelete = (deletedUserId: string) => {
    const updatedValue = stateValue.filter((user) => user.id !== deletedUserId);
    setState((prev) => ({ ...prev, value: updatedValue }));

    onDelete && onDelete(deletedUserId, updatedValue);
  };

  const selectUser = (user: RawUser) => {
    const isSelected = userIsSelected(user);

    const updatedValue = !isSelected
      ? [...stateValue, user]
      : stateValue.filter((u) => u.id !== user.id);
    setState((prev) => ({ ...prev, value: updatedValue }));
    if (onChange) {
      onChange(updatedValue);
    }
  };

  const userIsSelected = (user: RawUser) =>
    stateValue.some(({ id }) => id === user.id);

  const handleChangePage = (_: any, newPage: number) => {
    setState((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  };

  return (
    <div className={classes.wrapper}>
      {multiple && value.length > 0 && (
        <>
          {textBeforeChips && (
            <Typography variant="body1" gutterBottom>
              {textBeforeChips}
            </Typography>
          )}
          <ChipList
            items={value.map((user) => ({
              id: user.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              label: user.name!,
              avatarUrl: user.avatarUrl,
            }))}
            onDelete={(item) => handleDelete(item.id)}
            defaultAvatar={
              <Avatar>
                <FaceIcon />
              </Avatar>
            }
          />
        </>
      )}
      <TextField
        placeholder="Chercher un utilisateur"
        fullWidth
        margin="normal"
        onChange={handleChange}
        variant="standard"
        {...textFieldProps}
      />
      {!!users.length && (
        <>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {multiple && (
                    <TableCell
                      padding="checkbox"
                      className={classes.tableCellSmall}
                    />
                  )}
                  {columns.includes('name') && (
                    <TableCell classes={{ head: classes.tableHeadCell }}>
                      Utilisateur
                    </TableCell>
                  )}
                  {columns.includes('email') && (
                    <TableCell classes={{ head: classes.tableHeadCell }}>
                      Email
                    </TableCell>
                  )}
                  {columns.includes('roles') && (
                    <TableCell classes={{ head: classes.tableHeadCell }}>
                      Role
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    const isSelected = userIsSelected(user);
                    return (
                      <TableRow
                        key={user.id}
                        className={classes.tableRow}
                        onClick={() => selectUser(user)}
                        selected={isSelected}
                      >
                        {multiple && (
                          <TableCell
                            padding="checkbox"
                            className={classes.tableCellSmall}
                          >
                            <Checkbox checked={isSelected} />
                          </TableCell>
                        )}
                        {columns.includes('name') && (
                          <TableCell classes={{ body: classes.tableCellMain }}>
                            {user.name}
                          </TableCell>
                        )}
                        {columns.includes('email') && (
                          <TableCell>{user.email}</TableCell>
                        )}
                        {columns.includes('roles') && (
                          <TableCell>{user.roles.join(' ')}</TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell
                      colSpan={multiple ? columns.length + 1 : columns.length}
                    />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Page précédente',
            }}
            nextIconButtonProps={{
              'aria-label': 'Page suivante',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Nombre d'éléments par page"
          />
        </>
      )}
    </div>
  );
};
