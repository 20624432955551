import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  ShareSubjectMutation,
  ShareSubjectMutationVariables,
} from '../../../__generated__/queries-topic';

export const SHARE_SUBJECT_MUTATION = gql`
  mutation ShareSubject($subject: ID!, $userIds: [String!]!) {
    shareSubject(subject: { id: $subject }, userIds: $userIds) {
      id
    }
  }
`;

export const useSubjectSharer = (
  options?: MutationHookOptions<
    ShareSubjectMutation,
    ShareSubjectMutationVariables
  >,
) =>
  useMutation<ShareSubjectMutation, ShareSubjectMutationVariables>(
    SHARE_SUBJECT_MUTATION,
    options,
  );
