import { ApolloClient, gql } from '@apollo/client';
import { DOWNLOAD_FRAGMENT } from '../../../pages/SubjectForm/Attachments/attachmentFragment.topic.graphql';

const GET_DOWNLOAD_URL_ATTACHMENT = gql`
  query getDownloadUrlAttachment($id: ID!) {
    attachment(where: { id: $id }) {
      ...DownloadAttachmentFragment
    }
  }
  ${DOWNLOAD_FRAGMENT}
`;

export const getDownloadUrlAttachment = (
  client: ApolloClient<any>,
  attachmentId: string,
) =>
  client
    .query({
      query: GET_DOWNLOAD_URL_ATTACHMENT,
      variables: {
        id: attachmentId,
      },
    })
    .then(({ data, errors }) => {
      if (!data || errors) {
        throw new Error('Echec du téléchargement. Merci de réessayer.');
      }
      return data.attachment;
    });
