import { gql, useQuery } from '@apollo/client';
import {
  GetPrintIssuesByPrintPublicationQuery,
  GetPrintIssuesByPrintPublicationQueryVariables,
} from '../../__generated__/queries-topic';
import { useGetter } from '../useGetter';

const GET_PRINT_ISSUES_BY_PRINT_PUBLICATION = gql`
  query GetPrintIssuesByPrintPublication($id: ID!, $excludeIds: [ID!]) {
    printPublication(where: { id: $id }) {
      id
      title
      printIssues(
        first: 1000
        orderBy: createdAt_DESC
        where: { id_not_in: $excludeIds }
      ) {
        id
        title
      }
    }
  }
`;

export const useGetPrintIssuesByPrintPublication = (
  variables: GetPrintIssuesByPrintPublicationQueryVariables,
) =>
  useQuery<
    GetPrintIssuesByPrintPublicationQuery,
    GetPrintIssuesByPrintPublicationQueryVariables
  >(GET_PRINT_ISSUES_BY_PRINT_PUBLICATION, {
    variables,
    skip: variables.id.length < 1,
  });

export const useGetPrintIssuesByPrintPublicationGetter = () =>
  useGetter<
    GetPrintIssuesByPrintPublicationQuery,
    GetPrintIssuesByPrintPublicationQueryVariables
  >(GET_PRINT_ISSUES_BY_PRINT_PUBLICATION);
