import { Drawer, Paper, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import {
  checkIfStatusIsDisable,
  getColorByStatus,
  getLatestVersionByStatus,
  getOrderedStatusList,
  getStatusName,
  Version,
} from '../../../utils/rawArticleStatus';
import { WorkflowStepOwner } from '../WorkflowStepOwner';
import { useStyles } from './styles';

interface WorkflowDrawerProps {
  open: boolean;
  onClose: () => void;
  currentStatus: RawArticleStatus;
  rawArticleId: string;
  versions: Version[];
  updateStatus: (
    nextStatus: RawArticleStatus,
    currentStatus: RawArticleStatus,
  ) => void;
  allowedStatuses: RawArticleStatus[];
}

export const WorkflowDrawer: FC<WorkflowDrawerProps> = ({
  open,
  onClose,
  currentStatus,
  versions,
  updateStatus,
  allowedStatuses,
  rawArticleId,
}) => {
  const classes = useStyles();
  const versionByStatus = getLatestVersionByStatus(versions, currentStatus);
  const orderedStatusList = getOrderedStatusList();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
        modal: classes.drawerModal,
      }}
    >
      <div className={classes.wrapper}>
        <span className={classes.titleWrapper}>
          <Typography variant="h5">Workflow</Typography>
        </span>
        <div className={classes.statusesWrapper}>
          {Object.values(orderedStatusList).map((status) => {
            const isStatusDisable = checkIfStatusIsDisable(
              status,
              allowedStatuses,
              versions[versions.length - 1].editorId,
              currentStatus,
            );
            return (
              <Paper
                key={status}
                className={classes.statusWrapperPaper}
                elevation={0}
              >
                <Paper
                  className={classNames(
                    classes.statusPaper,
                    isStatusDisable && classes.statusPaperDisable,
                  )}
                  style={{ backgroundColor: getColorByStatus(status) }}
                  onClick={() => {
                    if (isStatusDisable) {
                      return;
                    }
                    updateStatus(status, currentStatus);
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className={
                      status === currentStatus
                        ? classes.currentStatus
                        : classes.status
                    }
                  >
                    {getStatusName(status)}
                  </Typography>
                </Paper>
                <div className={classes.statusOwnerWrapper}>
                  <WorkflowStepOwner
                    rawArticleId={rawArticleId}
                    currentStatus={status}
                    versionByStatus={versionByStatus}
                  />
                </div>
              </Paper>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};
