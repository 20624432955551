import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  paperWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    top: '72px',
    position: 'fixed',
    zIndex: theme.zIndex.appBar - 1,
  },
  articleHeader: {
    width: '50%',
  },
  assignment: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  previewLoaderContainer: {
    alignItems: 'center',
    overflow: 'visible',
    textAlign: 'center',
  },
  headerRightGroup: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
  },
}));
