import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './styles';

interface CollapsableListItemProps {
  open?: boolean;
  title: string;
  disableGutters?: boolean;
  maxHeight?: number;
  className?: string;
}

export const CollapsableListItem: FC<CollapsableListItemProps> = ({
  open,
  title,
  children,
  disableGutters,
  maxHeight,
  className,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (open !== isOpen) {
      setIsOpen(open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setIsOpen]);

  return (
    <div className={className}>
      <ListItem
        button
        onClick={() => setIsOpen((prev) => !prev)}
        disableGutters={disableGutters}
      >
        <ListItemText primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={isOpen}
        classes={{ root: classes.collapseContainer }}
        style={{ maxHeight }}
      >
        {children}
      </Collapse>
    </div>
  );
};
