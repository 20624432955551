import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { useStyles } from './styles';

interface InputLabelProps {
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
}

const MAX_LABEL_CHAR = 26;

export const InputLabel: React.FC<InputLabelProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();

  const handleLabelChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      evt.currentTarget && onChange(evt.currentTarget.value.toUpperCase());
    },
    [onChange],
  );

  return (
    <TextField
      label="Label Gabarit"
      placeholder="Nouveau Gabarit"
      helperText={`${MAX_LABEL_CHAR} caractères maximum`}
      inputProps={{
        maxLength: MAX_LABEL_CHAR,
        className: classes.inputLabel,
      }}
      className={classes.label}
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      onChange={handleLabelChange}
      disabled={disabled}
      variant="standard"
    />
  );
};
