import { MenuItem, Paper, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash.debounce';
import React, { FC, useState } from 'react';
import Autosuggest, {
  ChangeEvent,
  RenderSuggestionParams,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { useGetUserBySearch } from '../WorkflowStepOwner/getUsersBySearchQuery.topic.graphql';
import { GET_RAW_ARTICLE } from '../../../apollo/queries/rawArticle.topic.graphql';
import { useRawArticleVersionEditorTransferCreator } from './createRawArticleVersion.topic.graphql';
import { useStyles } from './styles';

interface SearchUserProps {
  rawArticleId: string;
  versionId: string;
  close: () => void;
}

interface User {
  name: string;
  id: string;
}

const renderInputComponent = (inputProps: any) => {
  const { classes, inputRef = () => undefined, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      variant="standard"
      {...other}
    />
  );
};

const renderSuggestion = (
  suggestion: User,
  { query, isHighlighted }: RenderSuggestionParams,
) => {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
};

export const SearchUser: FC<SearchUserProps> = ({
  versionId,
  rawArticleId,
  close,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<
    { name: string; id: string }[]
  >([]);
  // TODO WTF data wasn't used
  const { refetch } = useGetUserBySearch({ id: versionId, search: value });
  const [
    createRawArticleVersionEditorTransfer,
  ] = useRawArticleVersionEditorTransferCreator({
    refetchQueries: [
      {
        query: GET_RAW_ARTICLE,
        variables: { id: rawArticleId },
      },
    ],
  });

  const onChange = (
    event: React.FormEvent<HTMLInputElement>,
    params: ChangeEvent,
  ) => {
    setValue(params.newValue);
  };

  const onSuggestionsFetchRequested = async ({
    value: search,
  }: SuggestionsFetchRequestedParams) => {
    const refetchedData = await refetch({
      id: versionId,
      search,
    });
    if (refetchedData.data?.rawArticleVersion?.allowedTransfers.length) {
      const mappedResponse = refetchedData.data?.rawArticleVersion.allowedTransfers.map(
        (al) => {
          return {
            name: al.name,
            id: al.id,
          };
        },
      );
      setSuggestions(mappedResponse);
    }
  };

  const inputProps = {
    placeholder: 'Rechercher',
    value,
    classes,
    onChange,
  };

  return (
    <Autosuggest
      onSuggestionsFetchRequested={debounce(onSuggestionsFetchRequested, 500)}
      onSuggestionSelected={(e, { suggestion }) => {
        createRawArticleVersionEditorTransfer({
          variables: {
            data: {
              editorId: suggestion.id,
              rawArticle: {
                connect: { id: rawArticleId },
              },
              previousVersion: {
                connect: { id: versionId },
              },
            },
          },
        });
        close();
      }}
      inputProps={inputProps}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderInputComponent={renderInputComponent}
      suggestions={suggestions}
      renderSuggestion={renderSuggestion}
      getSuggestionValue={(suggestion: User) => suggestion.name}
      renderSuggestionsContainer={(options) => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
    />
  );
};
