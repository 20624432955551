import { ErrorOutline, Public } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { FC } from 'react';
import { Metadata, getMetadata } from 'utils/getMetadata';
import {
  EDIT_STATUS,
  EXPORT_TYPE,
  PROCESS_STATUS,
  PriceLevel,
  getColorClass,
  priceLevels,
} from '../utils';
import { useStyles } from './styles';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';

interface GalleryItemInfosProps {
  photo?: Pick<SearchPhotosPhoto, 'metadataByName' | 'updatedAt'>;
}

export const GalleryItemInfos: FC<GalleryItemInfosProps> = ({ photo }) => {
  const classes = useStyles();

  const priceMetadata = getMetadata(Metadata.PriceLevel, photo)?.toUpperCase();

  const priceLevel = priceMetadata
    ? priceLevels[priceMetadata as PriceLevel]
    : undefined;

  const getBadges = () => {
    return (
      <>
        {getMetadata(Metadata.EditStatus, photo) === EDIT_STATUS.PUBLISHED &&
          getMetadata(Metadata.ExportTypePrint, photo) === EXPORT_TYPE.PRINT &&
          getMetadata(Metadata.ProcessStatus, photo) ===
            PROCESS_STATUS.ATTENTE && (
            <ErrorOutline className={classes.badge} color="error" />
          )}
        {getMetadata(Metadata.EditStatus, photo) === EDIT_STATUS.PUBLISHED &&
          getMetadata(Metadata.ExportTypePrint, photo) === EXPORT_TYPE.PRINT &&
          getMetadata(Metadata.ProcessStatus, photo) ===
            PROCESS_STATUS.TRAITE && (
            <CheckCircleIcon className={classes.badge} color="primary" />
          )}
        {getMetadata(Metadata.EditStatus, photo) === EDIT_STATUS.PUBLISHED &&
          getMetadata(Metadata.ExportTypeWeb, photo) === EXPORT_TYPE.WEB && (
            <Public className={classes.badge} color="primary" />
          )}
        {getMetadata(Metadata.ResolutionKind, photo) && (
          <span className={clsx(classes.badge, classes.resolution)}>
            {getMetadata(Metadata.ResolutionKind, photo)}
          </span>
        )}
        {priceLevel?.renderIcon({ fontSize: 16, lineHeight: '16px', ml: 1 })}
      </>
    );
  };

  return (
    <div className={classes.wrapper}>
      <Typography variant="caption" className={classes.secondary}>
        {moment(photo?.updatedAt).format('D MMM yyyy - HH:mm')}
      </Typography>
      <div className={classes.badges}>
        {photo && getColorClass(photo) && (
          <div
            className={classes.color}
            style={{ backgroundColor: getColorClass(photo) }}
          ></div>
        )}
        {getBadges()}
      </div>
    </div>
  );
};
