import {
  getCropZoneParam,
  OneMedia,
  resizer,
} from '@prismamedia/one-components';

export const getResizedUrl = (media: OneMedia) => {
  const { iframely, focusPoint, crop } = media;
  let { height, width } = iframely.meta;
  let parameters = { quality: 100 } as any;
  if (crop) {
    parameters = {
      ...parameters,
      ...getCropZoneParam(crop),
    };
    const { width: cropWidth, height: cropHeight } = crop;
    width = cropWidth;
    height = cropHeight;
  }
  if (focusPoint?.x && focusPoint?.y) {
    // eslint-disable-next-line immutable/no-mutation
    parameters['focus-point'] = `${focusPoint.x},${focusPoint.y}`;
  }

  return resizer.fit({
    height,
    width,
    origin: iframely.meta.canonical,
    parameters,
  });
};
