import { ApolloClient, useApolloClient } from '@apollo/client';
import { Input, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { UPDATE_ASSIGNMENT_PAGENUMBER } from '../../../apollo/mutations/updateAssignmentPageNumber.topic.graphql';
import { useStyles } from './styles';

interface AssignmentPageNumberProps {
  value?: number | null;
  assignmentId: string;
  client: ApolloClient<any>;
}

export const AssignmentPageNumber: FC<AssignmentPageNumberProps> = ({
  assignmentId,
  value,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [stateValue, setStateValue] = useState(value);
  const [showInput, setShowInput] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length) {
      const pageNumber = +e.target.value;
      client
        .mutate({
          mutation: UPDATE_ASSIGNMENT_PAGENUMBER,
          variables: {
            assignmentId,
            pageNumber,
          },
        })
        .then(() => setStateValue(pageNumber));
    }
  };

  return (
    <>
      {!showInput && (
        <Typography onClick={() => setShowInput(true)} className={classes.typo}>
          {stateValue}
        </Typography>
      )}
      {showInput && (
        <Input
          inputProps={{ className: classes.inputProps, min: '0' }}
          defaultValue={`${stateValue}`}
          onBlur={() => setShowInput(false)}
          onChange={handleChange}
          type="number"
        />
      )}
    </>
  );
};
