import { Typography } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';

interface DateProps {
  date: Date | string;
  format?: formats;
}

enum formats {
  DETAILLED = 'D MMMM yyyy, hh:mm',
}

export const FormattedDate: FC<DateProps> = ({
  date,
  format = formats.DETAILLED,
}) => <Typography variant="body2">{moment(date).format(format)}</Typography>;
