import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  checkbox: {
    height: 'auto',
  },
  separator: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
  },
  label: {
    fontSize: '0.875rem',
  },
}));
