import InputLabel from '@mui/material/InputLabel';
import { EditorState } from 'draft-js';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { DraftEditor } from '../../Draft/Editor';

interface FinalFormMaterialDraftProps extends FieldRenderProps {
  label: string;
  resize?: () => void;
}

export const FinalFormMaterialDraft: FC<FinalFormMaterialDraftProps> = ({
  input: { name, onChange, value },
  meta,
  label,
  resize,
  ...rest
}) => {
  const text = (value as EditorState).getCurrentContent().getPlainText();
  return (
    <>
      {label && text && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <DraftEditor
        {...rest}
        onChange={(e) => {
          if (resize) {
            resize();
          }
          onChange(e);
        }}
        editorState={value}
      />
    </>
  );
};
