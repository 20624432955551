import { Card } from '@mui/material';
import {
  Folder,
  FolderList,
  NotificationTypeEnum,
  SearchBarVariant,
  useNotification,
} from '@prismamedia/one-components';
import { CategoryOrderByInput } from '__generated__/queries-photo';
import {
  useCreateCategory,
  useUpdateCategoryCache,
} from 'apollo/mutations/categories.photo.graphql';
import { useGetCategories } from 'apollo/queries/categories.photo.graphql';
import { Dispatch, FC, SetStateAction } from 'react';

interface CategorySelectProps {
  archiveId: string;
  selectedCategoryId: string | undefined;
  setSelectedCategoryId: Dispatch<SetStateAction<string | undefined>>;
  className?: string;
}

export const CategorySelect: FC<CategorySelectProps> = ({
  archiveId,
  selectedCategoryId,
  setSelectedCategoryId,
  className,
}) => {
  const { pushNotification } = useNotification();
  const createCategory = useCreateCategory();
  const updateCache = useUpdateCategoryCache();

  const { data, loading, error } = useGetCategories({
    where: {
      archive: {
        id: archiveId,
      },
    },
    first: 10000,
    orderBy: [CategoryOrderByInput.label_ASC],
  });

  const onCreateFolder = async (folder: Omit<Folder, 'id'>) => {
    try {
      const silblingCategories = data?.categories.filter(
        ({ parent }) => parent?.id === folder.parent?.id,
      );

      if (
        silblingCategories?.map(({ label }) => label).includes(folder.label)
      ) {
        throw new Error(
          `La catégorie "${folder.label}" existe déjà à cet emplacement`,
        );
      }

      const res = await createCategory({
        data: {
          archive: { connect: { id: archiveId } },
          label: folder.label,
          parent: folder.parent
            ? { connect: { id: folder.parent.id } }
            : undefined,
        },
      });

      await updateCache();

      if (!res.data) throw new Error('Could not create category');

      return res.data.createCategory.id;
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
      throw e;
    }
  };

  return (
    <Card sx={{ px: 3, py: 2 }} className={className}>
      <FolderList
        folders={data?.categories || []}
        loading={loading}
        error={error}
        selected={selectedCategoryId}
        setSelected={setSelectedCategoryId}
        maxLevel={2}
        onCreateFolder={onCreateFolder}
        searchBarVariant={SearchBarVariant.TERTIARY}
      />
    </Card>
  );
};
