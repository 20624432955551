import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      borderRight: '1px solid rgba(0,0,0,0.2)',
      borderColor: 'rgba(0,0,0,0.2)',
    },
    '&:last-child': {
      borderLeft: '1px solid rgba(0,0,0,0.2)',
      borderColor: 'rgba(0,0,0,0.2)',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'stretch',
    whiteSpace: 'nowrap',
    borderLeft: '1px solid rgba(0,0,0,0.2)',
    borderRight: '1px solid rgba(0,0,0,0.2)',
    borderColor: 'rgba(0,0,0,0.2)',
    height: '100%',
  },
  status: {},
}));
