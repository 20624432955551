import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '--zoom-value': 1,
  },
  charCounter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    fontSize: '12px',
  },
  loader: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  panelForm: {
    height: '-webkit-fill-available',
    backgroundColor: 'white',
    overflowY: 'scroll',
  },
  panelPreview: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  selectControl: {
    minWidth: '200px !important',
  },
  formTextItem: {
    marginTop: '20px',
    marginBottom: '20px',
    '& .toolbar__list': {
      margin: '5px',
      padding: '12px',
    },
    '& .toolbar__item': {
      display: 'block',
    },
    '& .toolbar__button': {
      width: 'min-content',
      height: 'min-content',
    },
    '& .toolbar__item--separator': {
      borderBottom: '1px solid #333',
      height: '5px',
      margin: '8px 0',
    },
  },
  formImageItem: {
    maxWidth: 800,
    margin: 'auto',
    marginBottom: '50px',
  },
  label: {
    fontSize: '1.1rem',
    color: 'gray',
  },
}));
