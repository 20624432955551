import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import React, { FC } from 'react';
import { MoreActionsMenu, MenuAction } from '../../Menu/MoreActionsMenu';
import { useStyles } from './styles';

const NORMAL_ELEVATION = 4;
const SELECTED_ELEVATION = 1;

interface LocationItemProps {
  selected?: boolean;
  text: string;
  onClick?: () => void;
  header?: boolean;
  menuActions?: MenuAction[];
  paperStyle?: React.CSSProperties;
  sticky?: boolean;
  hasAutomaticSubject?: boolean | null;
  autoPrototype?: boolean | null;
}

export const LocationItem: FC<LocationItemProps> = ({
  text,
  onClick,
  selected,
  header,
  menuActions,
  paperStyle,
  sticky,
  hasAutomaticSubject,
  autoPrototype,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={selected ? classes.selectedPaper : classes.paperWrapper}
      style={
        sticky
          ? {
              position: 'sticky',
              top: 0,
              alignSelf: 'flex-start',
              zIndex: 1000,
            }
          : {}
      }
    >
      <Paper
        elevation={selected ? SELECTED_ELEVATION : NORMAL_ELEVATION}
        className={header ? classes.buttonPaper : classes.paper}
        style={paperStyle || {}}
        onClick={() => onClick && onClick()}
      >
        {hasAutomaticSubject && (
          <div className={classes.auto}>
            <AutorenewIcon />
          </div>
        )}
        {autoPrototype && (
          <div className={classes.auto}>
            <MenuBookIcon />
          </div>
        )}
        <Typography
          style={header ? { color: 'white' } : { flexGrow: 1 }}
          variant="subtitle1"
        >
          {text}
        </Typography>
        {menuActions && menuActions.length > 0 && (
          <MoreActionsMenu menuActions={menuActions} />
        )}
      </Paper>
    </div>
  );
};
