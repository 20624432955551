import { gql, useQuery } from '@apollo/client';
import {
  SubjectListPage_GetFilteredLocationTreeQuery,
  SubjectListPage_GetFilteredLocationTreeQueryVariables,
  SubjectListPage_GetLocationTreeQuery,
  SubjectListPage_GetLocationTreeQueryVariables,
} from '../../../../__generated__/queries-topic';

const GET_FILTERED_LOCATION_TREE = gql`
  query SubjectListPage_GetFilteredLocationTree(
    $searchString: NonEmptyString!
    $where: BrandFilterInput!
  ) {
    brands(
      first: 20
      where: {
        AND: [
          $where
          {
            OR: [
              { title_contains: $searchString }
              { printPublications_some: { title_contains: $searchString } }
              {
                printPublications_some: {
                  printIssues_some: { title_contains: $searchString }
                }
              }
            ]
          }
        ]
      }
    ) {
      id
      title
      printPublications(
        first: 20
        where: {
          OR: [
            { title_contains: $searchString }
            { printIssues_some: { title_contains: $searchString } }
          ]
        }
      ) {
        id
        title
        printIssues(where: { title_contains: $searchString }, first: 10) {
          id
          title
        }
      }
      websites(where: { title_contains: $searchString }, first: 10) {
        id
        title
        key
      }
    }
  }
`;

const GET_LOCATION_TREE = gql`
  query SubjectListPage_GetLocationTree($where: BrandFilterInput!) {
    brands(first: 100, where: $where) {
      id
      title
      printPublications(first: 100) {
        id
        title
        printIssues(first: 100, orderBy: createdAt_DESC) {
          id
          title
        }
      }
      websites(first: 10) {
        id
        title
        key
      }
    }
  }
`;

export const useGetLocationTree = (
  variables: SubjectListPage_GetLocationTreeQueryVariables,
) =>
  useQuery<
    SubjectListPage_GetLocationTreeQuery,
    SubjectListPage_GetLocationTreeQueryVariables
  >(GET_LOCATION_TREE, {
    variables,
  });

export const useGetFilteredLocationTree = (
  variables: SubjectListPage_GetFilteredLocationTreeQueryVariables,
) =>
  useQuery<
    SubjectListPage_GetFilteredLocationTreeQuery,
    SubjectListPage_GetFilteredLocationTreeQueryVariables
  >(GET_FILTERED_LOCATION_TREE, {
    variables,
    skip: variables.searchString.length < 1,
  });
