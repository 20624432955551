import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC } from 'react';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import { getStatusName } from '../../../utils/rawArticleStatus';

interface WorkflowConfirmStatusChangeModalProps {
  open: boolean;
  createNewVersion: () => void;
  currentStatus: RawArticleStatus;
  nextStatus: RawArticleStatus;
  toggleConfirmModal: () => void;
  dialogContentText?: string;
}

export const WorkflowConfirmStatusChangeModal: FC<WorkflowConfirmStatusChangeModalProps> = ({
  open,
  createNewVersion,
  toggleConfirmModal,
  currentStatus,
  dialogContentText,
  nextStatus,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Changement de statut</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogContentText ||
            `Passer l'article de : ${getStatusName(
              currentStatus,
            )} à ${getStatusName(nextStatus)}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleConfirmModal} color="primary">
          Annuler
        </Button>
        <Button
          data-cy="assignment-next-status-confirm"
          onClick={() => {
            createNewVersion();
            toggleConfirmModal();
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};
