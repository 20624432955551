import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FileCopyIcon from '@mui/icons-material/FileCopyTwoTone';
import React from 'react';
import { getSize } from '../../../utils/getSize';
import { useStyles } from './styles';

export interface FileInfo {
  filename: string;
  filesize: number;
  content: any;
}

interface SelectedFileInfoProps {
  disabled?: boolean;
  fileInfo: FileInfo;
  onDelete?: () => void;
}

export const SelectedFileInfo: React.FC<SelectedFileInfoProps> = ({
  disabled = false,
  fileInfo,
  onDelete,
}) => {
  const classes = useStyles();
  const { size, unit } = getSize(fileInfo.filesize);

  return (
    <div className={classes.dropzoneContainer}>
      <FileCopyIcon className={classes.bigIcon} />
      <Typography>{fileInfo.filename}</Typography>
      <Typography>
        {size}
        {unit}
      </Typography>
      <Button
        variant="outlined"
        onClick={onDelete}
        className={classes.button}
        disabled={disabled}
      >
        Désélectionner Fichier
      </Button>
    </div>
  );
};
