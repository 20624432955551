import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  userInput: {
    flex: '1 1 50%',
  },
  commentForm: {
    flex: '1 1 50%',
    maxHeight: '50vh',
  },
});
