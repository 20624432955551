import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    '& img': {
      width: '50%',
    },
    '& > h1': {
      marginBottom: theme.spacing(6),
    },
  },
}));
