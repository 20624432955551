import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  title: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  noSelection: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    alignSelf: 'center',
  },
}));
