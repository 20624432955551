import { makeStyles } from '@mui/styles';

const thumbnailWrapperSize = 150;

export const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.black,
  },
  image: {
    height: '100%',
    width: '100%',
  },
  thumbnail: {
    height: 150,
    width: 150,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvent: 'none',
      borderWidth: 0,
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
    },
  },
  thumbnailSelected: {
    '&::after': {
      borderWidth: 4,
    },
  },
  carouselContainer: {
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.common.black,
    '& .image-gallery-slide-wrapper': {
      height: `calc(100vh - ${thumbnailWrapperSize}px)`,
    },
    '& .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide': {
      height: '100%',
    },
    '& .image-gallery-image': {
      height: '100%',
      background: theme.palette.common.black,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .image-gallery-slide img': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
    },
    '& .image-gallery-thumbnails-wrapper': {
      overflow: 'hidden',
      height: `${thumbnailWrapperSize}px`,
    },
    '& .image-gallery-thumbnails': {
      padding: '0',
      backgroundColor: theme.palette.common.black,
    },
    '& .image-gallery-thumbnail': {
      border: 'none',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      width: `${thumbnailWrapperSize}px`,
      overflow: 'hidden',
    },
    '& .image-gallery-thumbnail img': {
      width: `${thumbnailWrapperSize}px`,
    },
    '& .image-gallery-thumbnail.active': {
      width: `${thumbnailWrapperSize}px`,
    },
    '& .image-gallery-thumbnail.active img': {
      transform: 'translateX(0)',
      border: `3px solid ${theme.palette.primary.main}`,
    },
    '& .image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before': {
      color: theme.palette.primary.main,
    },
    '& .center .image-gallery-image div': {
      height: '100%',
      cursor: 'move',
      transition: 'all 0.25s ease-out',
    },
    '& .center .image-gallery-image div img': {
      pointerEvents: 'none',
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  galleryContent: {
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
  },
  galleryContentShift: {
    width: `calc(100% - 300px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
