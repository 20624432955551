import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    '--zoom-value': 1,
  },
  appToolbarChildren: {
    '& > *': {
      alignSelf: 'stretch',
    },
  },
}));
