import { EditorState } from 'draft-js';
import { editorStateFromRaw } from 'megadraft';
import punycode from 'punycode';

export interface CountType {
  wordCount: number;
  charCount: number;
}

export const getCountFromText = (
  text: string,
): { wordCount: number; charCount: number } => {
  if (!text) {
    return { wordCount: 0, charCount: 0 };
  }
  const charCount = punycode.ucs2.decode(text).length;
  const words = text.split(' ').filter((word: string) => word.length > 0);
  const wordCount =
    words.length === 1 ? (words[0].length === 0 ? 0 : 1) : words.length;
  return { charCount, wordCount };
};

export const getCountFromEditorState = (e: EditorState): CountType => {
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const contentState = e.getCurrentContent();
  const plainText = contentState.getPlainText('');
  const text = plainText.replace(regex, '').trim(); // replace above characters w/ nothing
  const charCount = punycode.ucs2.decode(text).length;
  const words = text.split(' ').filter((word: string) => word.length > 0);
  const wordCount =
    words.length === 1 ? (words[0].length === 0 ? 0 : 1) : words.length;
  return {
    charCount,
    wordCount,
  };
};

export const getCountWithAsideBlockFromEditorState = (
  e: EditorState,
): CountType => {
  const contentState = e.getCurrentContent();
  const result = getCountFromEditorState(e);

  return contentState
    .getBlocksAsArray()
    .filter(
      (block) =>
        block &&
        block.get('type') === 'atomic' &&
        block.getIn(['data', 'type']) === 'aside',
    )
    .reduce((count, block) => {
      const asideDraftContent = block.getIn(['data', 'asideDraft']);
      const asideTitle = block.getIn(['data', 'asideTitle']);
      const asideTitleCount = getCountFromText(asideTitle);
      let asideCount = { wordCount: 0, charCount: 0 };
      if (!asideDraftContent && !asideTitle) {
        // when we just instanciated the block
        return count;
      }
      // here we convert from immutable struct to plain JS because in the case of the aside block plugin
      // parent draft convert asideDraftContent to immutable Map.
      if (asideDraftContent) {
        asideCount = getCountWithAsideBlockFromEditorState(
          editorStateFromRaw(
            asideDraftContent.toJS
              ? asideDraftContent.toJS()
              : asideDraftContent,
          ),
        );
      }

      return {
        wordCount:
          count.wordCount + asideCount.wordCount + asideTitleCount.wordCount,
        charCount:
          count.charCount + asideCount.charCount + asideTitleCount.charCount,
      };
    }, result);
};
