import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    minWidth: 315,
    maxWidth: 360,
    margin: 'auto',
    height: 'fit-content',
  },
  list: {
    maxWidth: 'unset',
    width: '100%',
    '& .MuiCardContent-root': {
      display: 'none',
    },
    '& .MuiCardActions-root': {
      display: 'none',
    },
  },
  header: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1.3)}px`,
  },
  headerAction: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: '0',
    '& > *': {
      marginLeft: theme.spacing(0.5),
    },
  },
  contentContainer: {
    padding: `2px ${theme.spacing(1.5)}`,
    height: '190px',
    overflowY: 'auto',
  },
  actionsContainer: {
    flexDirection: 'row-reverse',
    padding: `4px ${theme.spacing(0.5)}`,
    '& > *': {
      padding: theme.spacing(1),
      margin: '0 2px',
    },
  },
  templateItemActive: {
    backgroundColor: 'rgb(5 208 5)',
  },
  templateItemActiveIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgb(5 208 5)',
    fontSize: '2rem',
  },
  statusLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    padding: 0,
    '& > *': {
      margin: '0 2px',
    },
  },
  optionsMenuItem: {
    fontSize: '0.9rem',
    minHeight: 'unset',
  },
}));
