import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Close, ZoomIn, InfoOutlined } from '@mui/icons-material';

export enum ToolsType {
  close = 'close',
  zoom = 'zoom',
  info = 'info',
}
export type CallbackDeleteType = (photosLength: number) => void;

type RenderToolsCallback = (
  index: number,
  deleteImageGallery?: (index: number) => void,
) => ReactNode;
type PossibleToolsType =
  | RenderToolsCallback
  | ToolsType.close
  | ToolsType.zoom
  | ToolsType.info
  | ReactNode;
export interface GalleryToolsType {
  type: PossibleToolsType;
  callback?: () => void;
  children?: RenderToolsCallback;
}

const styles = () =>
  createStyles({
    toolbar: {
      height: 64,
      padding: '0 24px',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    iconColor: {
      color: '#FFFFFF',
    },
  });

type GalleryToolBarProps = {
  leftTools?: GalleryToolsType[];
  centerTools?: GalleryToolsType[];
  rightTools?: GalleryToolsType[];
  enableZoom: () => void;
  showDrawer: () => void;
  selectedIndex: number;
  deleteImageGallery: (index: number, callback?: CallbackDeleteType) => void;
} & WithStyles<typeof styles>;

const GalleryToolBarWithoutStyle: React.FC<GalleryToolBarProps> = ({
  classes,
  leftTools,
  centerTools,
  rightTools,
  enableZoom,
  showDrawer,
  selectedIndex,
  deleteImageGallery,
}) => {
  const renderTools = (tools: GalleryToolsType[]) =>
    tools.map((tool: GalleryToolsType, i: number) => {
      if (typeof tool.type === 'function') {
        return tool.type(selectedIndex, deleteImageGallery);
      } else if (tool.type === ToolsType.close) {
        return (
          <IconButton
            className={classes.iconColor}
            aria-label="Close"
            key={i}
            onClick={tool.callback && tool.callback}
            size="large"
          >
            <Close className={classes.iconColor} />
          </IconButton>
        );
      } else if (tool.type === ToolsType.zoom) {
        return (
          <IconButton
            className={classes.iconColor}
            aria-label="Zoom"
            key={i}
            onClick={enableZoom}
            size="large"
          >
            <ZoomIn />
          </IconButton>
        );
      } else if (tool.type === ToolsType.info) {
        return (
          <IconButton
            className={classes.iconColor}
            aria-label="Info"
            key={i}
            onClick={showDrawer}
            size="large"
          >
            <InfoOutlined />
          </IconButton>
        );
      }

      return tool;
    });

  return (
    <div className={classes.toolbar}>
      <div>{leftTools && renderTools(leftTools)}</div>
      <div>{centerTools && renderTools(centerTools)}</div>
      <div>{rightTools && renderTools(rightTools)}</div>
    </div>
  );
};

export const GalleryToolBar = withStyles(styles)(GalleryToolBarWithoutStyle);
