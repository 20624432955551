import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1.3),
    position: 'relative',
    top: theme.spacing(0.4),
    maxWidth: theme.spacing(3),
  },
}));
