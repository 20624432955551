import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import FilterNone from '@mui/icons-material/FilterNone';

interface DuplicateIconProps {
  rawArticleId: string;
  subjectId: string;
  refetchSubject?: () => void;
  handleDuplicateDialog: (
    rawArticleId: string,
  ) => (e: React.SyntheticEvent) => void;
}

export const DuplicateIcon: React.FC<DuplicateIconProps> = ({
  rawArticleId,
  subjectId,
  refetchSubject,
  handleDuplicateDialog,
}) => {
  if (!rawArticleId || !subjectId || !refetchSubject) {
    return (
      <Tooltip title="Impossible de dupliquer cet article">
        <span>
          <IconButton data-cy="subject-disable-duplicate" disabled size="large">
            <FilterNone />
          </IconButton>
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Dupliquer un article">
      <IconButton
        data-cy="subject-toggle-duplicate-modal"
        onClick={handleDuplicateDialog(rawArticleId)}
        size="large"
      >
        <FilterNone />
      </IconButton>
    </Tooltip>
  );
};
