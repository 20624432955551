import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  assignmentItemWrapper: {
    display: 'flex',
  },
  selectOption: {
    display: 'flex',
  },
  select: {
    width: 300,
  },
});
