import { mergeDeepRight } from 'ramda';
import React, { FC } from 'react';
import { CheckboxButton, CheckboxButtonProps } from '../CheckboxButton';
import { useStyles } from './styles';

interface ColoredCheckboxButtonProps
  extends Omit<CheckboxButtonProps, 'classes'> {
  backgroundColor: string;
  color: string;
}

export const ColoredCheckboxButton: FC<ColoredCheckboxButtonProps> = ({
  color,
  backgroundColor,
  buttonProps,
  checkboxProps,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <CheckboxButton
      {...rest}
      buttonProps={mergeDeepRight(
        {
          style: {
            backgroundColor,
          },
          className: classes.checkboxButton,
        },
        buttonProps || {},
      )}
      checkboxProps={mergeDeepRight(
        {
          color: 'default' as const,
          style: {
            color,
            padding: '12px',
          },
        },
        checkboxProps || {},
      )}
    />
  );
};
