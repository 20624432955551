import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '--zoom-value': 1,
  },
  toolbarPlaceHolder: theme.mixins.toolbar,
  saveButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    position: 'relative',
    flex: '1 0 auto',
    '&:after': {
      content: '""',
      width: '1px',
      backgroundColor: 'rgba(0,0,0,0.2)',
      position: 'absolute',
      right: `-${theme.spacing(3)}`,
      ...theme.mixins.toolbar,
    },
  },
  menuTop: {
    marginLeft: theme.spacing(2),
  },
  appToolbarChildren: {
    '& > *': {
      alignSelf: 'stretch',
    },
  },
  main: {
    height: 'calc(100vh - 120px)',
    top: '120px',
    position: 'relative',
  },
  prolexisButton: {
    marginLeft: theme.spacing(2),
  },
}));
