import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    minWidth: '30vw',
  },
  content: {
    padding: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
}));
