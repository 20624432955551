import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';
import { GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion } from '../../../../__generated__/queries-topic';
import { MajorVersionsList } from '../MajorVersionsList';
import { useStyles } from './styles';

interface DuplicateVersionListProps {
  rawArticleId: string;
  versionSelected: GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion | null;
  closeDialog: (e: React.SyntheticEvent) => void;
  handleClick: (
    version: GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion,
    index: number,
  ) => void;
  updateStep: () => void;
}

export const DuplicateVersionList: FC<DuplicateVersionListProps> = ({
  rawArticleId,
  versionSelected,
  handleClick,
  closeDialog,
  updateStep,
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        Quelle version souhaitez-vous dupliquer ?
      </DialogTitle>
      <DialogContent>
        <MajorVersionsList
          rawArticleId={rawArticleId}
          versionSelected={versionSelected}
          onVersionClick={handleClick}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Annuler</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!versionSelected}
          onClick={updateStep}
        >
          CONFIRMER
        </Button>
      </DialogActions>
    </>
  );
};
