export interface SortablePrintIssue {
  id: string;
  title: string;
}

export const sortPrintIssues = (printIssues: SortablePrintIssue[]) => {
  return [
    ...printIssues.filter(({ title }) => isNaN(parseInt(title))),
    // eslint-disable-next-line fp/no-mutating-methods
    ...[...printIssues]
      .filter(({ title }) => !isNaN(parseInt(title)))
      .sort((a, b) => (parseInt(a.title, 10) > parseInt(b.title, 10) ? -1 : 1)),
  ];
};
