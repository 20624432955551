import React from 'react';
import { Theme, Drawer, IconButton, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Close } from '@mui/icons-material';

const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: 316,
      padding: 30,
    },
    drawerTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    iconColor: {
      color: '#FFFFFF',
    },
  });

type InfoDrawerProps = {
  children: React.ReactNode;
  showDrawer: () => void;
  drawerOpen: boolean;
} & WithStyles<typeof styles>;

const InfoDrawerWithoutStyle: React.FC<InfoDrawerProps> = ({
  showDrawer,
  drawerOpen,
  children,
  classes,
}) => (
  <Drawer
    variant="persistent"
    open={drawerOpen}
    anchor="right"
    classes={{
      paper: classes.drawerPaper,
    }}
    data-testid="info-drawer"
  >
    <Typography
      variant="h5"
      gutterBottom
      classes={{ root: classes.drawerTitle }}
    >
      <span>Informations</span>
      <IconButton onClick={showDrawer} size="large">
        <Close className={classes.iconColor} />
      </IconButton>
    </Typography>
    {children}
  </Drawer>
);

export const InfoDrawer = withStyles(styles)(InfoDrawerWithoutStyle);
