import { gql, useMutation } from '@apollo/client';

const UPDATE_ASSIGNMENT_MEDIA_SEARCH_STATUS = gql`
  mutation UpdateAssignmentMediaSearchStatus(
    $assignmentId: ID!
    $status: MediaSearchStatus!
  ) {
    updateAssignment(
      data: { mediaSearchStatus: $status }
      where: { id: $assignmentId }
    ) {
      id
      mediaSearchStatus
    }
  }
`;

export const useAssignmentMediaSearchStatusUpdater = () =>
  useMutation(UPDATE_ASSIGNMENT_MEDIA_SEARCH_STATUS);
