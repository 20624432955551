import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    '@media print': {
      // for multipage
      display: 'block',
    },
  },
  content: {
    marginTop: `calc(64px + ${theme.spacing(1)})`,
    display: 'flex',
    flexGrow: 1,
    minWidth: 0, // So the Typography noWrap works
    height: `calc(100vh - 64px - ${theme.spacing(1)})`,
    overflow: 'auto',
    '@media print': {
      // for multipage
      height: '100%',
      paddingTop: 0,
      display: 'block',
    },
  },
  contentWithGutters: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    position: 'relative',
    padding: '0',
    transition: theme.transitions.create(['width', 'transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'visible',
    height: '100%',
    background: 'transparent',
    '@media print': {
      // for multipage
      display: 'none',
    },
  },
  drawerPaper200: {
    width: '200px',
  },
  drawerPaper300: {
    width: '300px',
  },
  drawerPaper400: {
    width: '400px',
  },
  drawerWrapper: {
    height: '100vh',
    boxShadow: `1px 1px 10px ${theme.palette.grey[400]}`,
  },
  drawerContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  drawerContainerWithGutters: {
    padding: theme.spacing(4),
  },
  drawerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  drawerPaperCloseLeft: {
    transform: `translateX(-2px)`,
    width: theme.spacing(2),
    alignItems: 'flex-end',
    '& $drawerHandleLeft': {
      transform: 'rotate(180deg)',
    },
    overflow: 'visible',
  },
  drawerPaperCloseRight: {
    transform: `translateX(2px)`,
    width: theme.spacing(2),
    '& $drawerHandleRight': {
      transform: 'rotate(180deg)',
    },
    overflow: 'visible',
  },
  drawerHandleLeft: {
    position: 'absolute',
    top: '50%',
    fontSize: '2em',
    right: '-0.5em',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.grey['300']}`,
    cursor: 'pointer',
    background: theme.palette.common.white,
  },
  drawerHandleRight: {
    position: 'absolute',
    top: '50%',
    fontSize: '2em',
    left: '-0.5em',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.grey['300']}`,
    cursor: 'pointer',
    background: theme.palette.common.white,
  },
}));
