import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '40vw',
    '& .MuiDialogActions-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
  },
}));
