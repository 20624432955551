import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    '--zoom-value': 1,
  },
  panelForm: {
    height: '-webkit-fill-available',
    backgroundColor: 'white',
    overflowY: 'scroll',
  },
  panelPreview: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  selectControl: {
    minWidth: '200px !important',
  },
  formTextItem: {
    marginTop: '10px',
    marginBottom: '10px',
    '& .toolbar__list': {
      margin: '5px',
      padding: '12px',
    },
    '& .toolbar__item': {
      display: 'block',
    },
    '& .toolbar__button': {
      width: 'min-content',
      height: 'min-content',
    },
    '& .toolbar__item--separator': {
      borderBottom: '1px solid #333',
      height: '5px',
      margin: '8px 0',
    },
  },
  main: {
    height: 'calc(100vh - 120px)',
    top: '120px',
    position: 'relative',
  },
  highlightBlock: {
    backgroundColor: '#dcf5f1',
  },
  defaultColorBlock: {
    backgroundColor: '#f7f7f7',
  },
  subjectBlock: {
    marginBottom: '20px',
    borderRadius: '4px',
  },
  subjectLabel: {
    padding: '5px',
    color: '#423a3a',
    fontWeight: 'bold',
    textAlign: 'center',
    maxHeight: 'initial',
  },
  emptySubject: {
    color: '#a7a7a7',
    height: '38px',
    border: 'dashed',
    borderColor: '#a7a7a7',
    borderWidth: '1px',
    margin: '6px',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    textAlign: 'center',
    fontStyle: 'italic',
    padding: '7px',
  },
  subjectAssignment: {
    maxHeight: '48px',
  },
}));
