import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    height: 'auto',
    padding: theme.spacing(2),
  },
  rightWrapper: {
    marginLeft: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media print': {
      display: 'block',
    },
  },
  contentWrapper: {
    padding: theme.spacing(2),
  },
  contentBlock: {
    marginTop: theme.spacing(6),
  },
  content: {
    marginLeft: theme.spacing(6),
  },
  subjectWrapper: {
    marginTop: theme.spacing(6),
  },
}));
