import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, MenuItem } from '@mui/material';
import { useDialog, useMenu } from '@prismamedia/one-components';
import React, { FC } from 'react';
import { EmailArticleDialog } from '../../../components/EmailDialog/EmailArticleDialog';
import { TopicLink } from '../../../components/Link/TopicLink';
import { ShareSubjectDialog } from '../../../components/ShareDialog/ShareSubjectDialog';
import { paths } from '../../../routing';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import { useStyles } from './styles';

interface MenuTopProps {
  updateStatus: (currentStatus: RawArticleStatus) => void;
  subjectId: string;
  rawArticleId: string;
}

export const MenuTop: FC<MenuTopProps> = ({
  updateStatus,
  subjectId,
  rawArticleId,
}) => {
  const classes = useStyles();
  const { openDialog, closeDialog } = useDialog();
  const { openMenu, closeMenu } = useMenu();

  return (
    <div data-cy="assignment-top-menu-options" className={classes.menuTop}>
      <IconButton
        onClick={(e) =>
          openMenu(
            e.currentTarget,
            <div>
              <MenuItem
                onClick={() => {
                  setImmediate(window.print);
                  closeMenu();
                }}
              >
                Imprimer
              </MenuItem>
              <MenuItem
                onClick={() => {
                  updateStatus(RawArticleStatus.Editing);
                  closeMenu();
                }}
              >
                Nouvelle version
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openDialog(
                    <EmailArticleDialog
                      rawArticleId={rawArticleId}
                      closeDialog={closeDialog}
                    />,
                  ),
                    closeMenu();
                }}
              >
                Envoyer le lien par email
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openDialog(
                    <ShareSubjectDialog
                      subjectId={subjectId}
                      closeDialog={closeDialog}
                    />,
                  );
                  closeMenu();
                }}
              >
                Partager le sujet
              </MenuItem>
              <MenuItem>
                <TopicLink
                  to={paths.ARTICLE_HISTORY}
                  params={{ idRawArticle: rawArticleId }}
                  data-cy="assignment-top-menu-options-history"
                  onClick={() => closeMenu()}
                >
                  Historique
                </TopicLink>
              </MenuItem>
            </div>,
          )
        }
      >
        <MoreVertIcon />
      </IconButton>
    </div>
  );
};
