import { List } from '@mui/material';
import debounce from 'lodash.debounce';
import React, { FC, useEffect, useState } from 'react';
import { LinkButton } from '../../../../components/Button/LinkButton';
import { SearchInput } from '../../../../components/Field/SearchInput';
import { CollapsableListItem } from '../../../../components/ListItem/CollapsableListItem';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../../components/Loader/Circular';
import { paths } from '../../../../routing';
import { TextFilter } from '../../TextFilter';
import { CategoryTree } from '../CategoryTree';
import { LocationTree } from '../LocationTree';
import {
  useGetCategoryTree,
  useGetFilteredCategoryTree,
} from './getCategoryTree.topic.graphql';
import {
  useGetFilteredLocationTree,
  useGetLocationTree,
} from './getFilteredLocationTree.topic.graphql';
import { useStyles } from './styles';

interface GlobalFiltersProps {
  categoryIds: string[];
  locationIds: string[];
  textStatuses: string[];
  unitId?: string;
  addFilter: (values: Record<string, string | string[]>) => void;
  afterLocationSearchChange: () => void;
}

export const SubjectListFilters: FC<GlobalFiltersProps> = ({
  categoryIds,
  locationIds,
  afterLocationSearchChange,
  textStatuses,
  unitId,
  addFilter,
}) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    searchString && afterLocationSearchChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const onFilterChange = (filterName: string) => (ids: string[]) => {
    addFilter({ [filterName]: ids });
  };
  const categoryTreeData = useGetCategoryTree();
  const filteredCategoryTreeData = useGetFilteredCategoryTree({
    where: { unit: { id: unitId } },
  });
  const { data: categoryData } = unitId
    ? filteredCategoryTreeData
    : categoryTreeData;

  const {
    data: locationTreeData,
    loading: loadingNonFilteredData,
  } = useGetLocationTree({
    where: { ...(unitId ? { unit: { id: unitId } } : {}) },
  });

  const {
    data: filteredLocationTreeData,
    loading: loadingFilteredData,
  } = useGetFilteredLocationTree({
    searchString,
    where: { ...(unitId ? { unit: { id: unitId } } : {}) },
  });

  return (
    <>
      <div className={classes.separator}>
        <LinkButton to={paths.SUBJECT_LIST} whiteList={['unit']}>
          Réinitialiser les filtres
        </LinkButton>
      </div>
      <div className={classes.separator}>
        <List>
          <CollapsableListItem title="Thématiques" open={!!categoryIds.length}>
            {!categoryData ? (
              'No category data'
            ) : (
              <CategoryTree
                timeout="auto"
                treeList={categoryData}
                selected={categoryIds}
                onChange={onFilterChange('cat')}
              />
            )}
          </CollapsableListItem>
        </List>
      </div>
      <div className={classes.separator}>
        <List>
          <CollapsableListItem title="Supports" open={!!locationIds.length}>
            <div className={classes.searchWrapper}>
              <SearchInput
                initValue={searchString}
                onChange={setSearchString}
              />
            </div>
            {loadingNonFilteredData || loadingFilteredData ? (
              <CircularLoader size={LoaderSizes.small} />
            ) : !locationTreeData && !filteredLocationTreeData ? (
              <div>ERROR</div>
            ) : (
              locationTreeData && (
                <LocationTree
                  treeList={filteredLocationTreeData || locationTreeData}
                  open={searchString !== ''}
                  timeout={searchString !== '' ? 0 : 'auto'}
                  selected={locationIds}
                  onChange={onFilterChange('loc')}
                />
              )
            )}
          </CollapsableListItem>
        </List>
      </div>
      <div className={classes.separator}>
        <List>
          <CollapsableListItem title="Textes" open={!!textStatuses.length}>
            <div className={classes.textFilterWrapper}>
              <TextFilter
                selectedTextStatuses={textStatuses}
                onChange={debounce(onFilterChange('text'), 1000)}
              />
            </div>
          </CollapsableListItem>
        </List>
      </div>
    </>
  );
};
