import { Delete, EuroSymbol, Palette } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Badge, IconButton } from '@mui/material';
import {
  NotificationTypeEnum,
  useDialog,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import {
  MetadataNameEnumType,
  SearchPhotosQueryVariables,
} from '__generated__/queries-photo';
import { useUpdatePhoto } from 'apollo/mutations/photos.photo.graphql';
import { useGetPhotos } from 'apollo/queries/photos.photo.graphql';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Metadata, getMetadata } from 'utils/getMetadata';
import { PAGINATION } from '..';
import { ColorMenu } from '../ColorMenu';
import { DeleteDialog } from '../DeleteDialog';
import { OneFullscreenGallery, ToolsType } from '../OneFullscreenGallery';
import { PhotoInfos } from '../PhotoInfos';
import { PriceMenu } from '../PriceMenu';
import { AccessName, PriceLevel, getColorClass, priceLevels } from '../utils';
import { useStyles } from './styles';
import { getImagesForGallery } from './utils';

interface FullscreenGalleryProps {
  initFocusIndex: number;
  selectedPhotoIds: string[];
  photoVariables: SearchPhotosQueryVariables;
  setSelectedPhotoIds: Dispatch<SetStateAction<string[]>>;
  onClose?: () => void;
}

export const FullscreenGallery: FC<FullscreenGalleryProps> = ({
  initFocusIndex,
  selectedPhotoIds,
  photoVariables,
  setSelectedPhotoIds,
  onClose,
}) => {
  const classes = useStyles();
  const { openMenu } = useMenu();
  const { openDialog } = useDialog();
  const updatePhoto = useUpdatePhoto();
  const { pushNotification } = useNotification();
  const [focusIndex, setFocusIndex] = useState(
    initFocusIndex < 0 ? 0 : initFocusIndex,
  );

  const { data, fetchMore } = useGetPhotos(photoVariables);

  const photoList = data?.searchPhotos;

  useEffect(() => {
    if (!photoList?.length) {
      onClose?.();
    }
  }, [onClose, photoList?.length]);

  if (!photoList?.length) {
    return null;
  }

  const selectedPhoto = (index: number) =>
    photoList[index >= photoList.length ? 0 : index];

  const updatePhotoFromIndex = async (
    index: number,
    value: string | undefined,
    metadata: MetadataNameEnumType,
  ) => {
    const photo = photoList[index];
    try {
      updatePhoto({
        photo,
        metadatas: [{ metadata, value }],
      });
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }
  };

  return (
    <OneFullscreenGallery
      onSlide={(newIndex) => {
        if (setFocusIndex) {
          setFocusIndex(newIndex);
        }
        if (
          !data?.searchPhotos?.length ||
          data?.searchPhotos?.length < PAGINATION
        ) {
          return;
        }
        if (newIndex >= PAGINATION / 2) {
          fetchMore({ variables: { skip: data?.searchPhotos.length } });
        }
      }}
      leftToolBarTools={[
        {
          type: () => {
            if (!selectedPhotoIds.includes(photoList[focusIndex]?.id))
              return null;
            return <CheckCircleIcon color="primary" key="checkmark" />;
          },
        },
      ]}
      rightToolBarTools={[
        { type: ToolsType.info },
        { type: ToolsType.zoom },
        {
          type: (index) => {
            if (
              !selectedPhoto(index).archive.accesses?.includes(
                AccessName.DELETE,
              )
            ) {
              return null;
            }

            return (
              <IconButton
                key="deleteButton"
                className={classes.actionButton}
                onClick={() =>
                  openDialog(
                    <DeleteDialog
                      photos={[selectedPhoto(index)]}
                      setSelectedPhotoIds={setSelectedPhotoIds}
                    />,
                  )
                }
              >
                <Delete />
              </IconButton>
            );
          },
        },
        {
          type: (index) => {
            const priceMetadata = getMetadata(
              Metadata.PriceLevel,
              photoList[index],
            )?.toUpperCase();

            const priceLevel = priceMetadata
              ? priceLevels[priceMetadata as PriceLevel]
              : undefined;

            return (
              <IconButton
                key="priceButton"
                className={classes.actionButton}
                onClick={(e) =>
                  openMenu(
                    e.currentTarget,
                    <PriceMenu
                      onSelect={(value) =>
                        updatePhotoFromIndex(index, value, Metadata.PriceLevel)
                      }
                    />,
                  )
                }
              >
                {priceLevel ? priceLevel.renderIcon() : <EuroSymbol />}
              </IconButton>
            );
          },
        },
        {
          type: (index) => (
            <IconButton
              key="colorButton"
              className={classes.actionButton}
              onClick={(e) =>
                openMenu(
                  e.currentTarget,
                  <ColorMenu
                    onSelect={(color) =>
                      updatePhotoFromIndex(index, color, Metadata.Urgency)
                    }
                  />,
                )
              }
            >
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: getColorClass(photoList[index]),
                  },
                }}
                badgeContent=" "
              >
                <Palette />
              </Badge>
            </IconButton>
          ),
        },
        {
          type: ToolsType.close,
          callback: () => onClose?.(),
        },
      ]}
      lazyLoad={true}
      slideDuration={0}
      photos={getImagesForGallery(photoList)}
      startIndex={focusIndex}
      renderInfoDrawer={(index: number) =>
        photoList.length ? (
          <PhotoInfos photo={selectedPhoto(index)} editable={true} />
        ) : (
          <div />
        )
      }
      isInfinite={false}
    />
  );
};
