import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { splitEvery } from 'ramda';
import { PrintPortal } from 'components/PrintPortal/index';
import { useStyles } from './styles';
import {
  getMetadata,
  getMetadataAsArray,
  Metadata,
  MetadataAsArray,
} from 'utils/getMetadata';
import { getPreview, PreviewSize } from 'utils/getPreview';
import classNames from 'classnames';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';

interface PhotosPrintViewProps {
  photos: SearchPhotosPhoto[];
  wrapperRef?: any;
}

export const PhotosPrintView: FC<PhotosPrintViewProps> = ({
  photos,
  wrapperRef,
}) => {
  const classes = useStyles();
  const nbPhotos = photos.length;
  if (!photos || !nbPhotos) {
    return null;
  }
  let gridSize: GridSize = 1;
  let itemStyle = '';
  switch (nbPhotos) {
    case 1:
      gridSize = 12;
      itemStyle = classes.gridItemFullHeight;
      break;
    case 2:
      gridSize = 6;
      itemStyle = classes.gridItemFullHeight;
      break;
    case 4:
      gridSize = 6;
      itemStyle = classes.gridItemFullHeight;
      break;
    default:
      gridSize = 4;
      itemStyle = classes.gridItemHalfHeight;
      break;
  }

  const splittedPhotos: SearchPhotosPhoto[][] = splitEvery(6, photos);

  return (
    <PrintPortal>
      <div ref={wrapperRef}>
        {splittedPhotos.map((splittedPhoto, i) => (
          <Grid container spacing={3} className={classes.gridList} key={i}>
            {splittedPhoto.map((photo, j) => (
              <Grid
                item
                xs={gridSize}
                key={j}
                className={classNames(classes.gridItem, itemStyle)}
              >
                <div className={classes.photoImageWrapper}>
                  <img
                    src={getPreview(photo, PreviewSize.XL)}
                    className={classes.photoImage}
                  />
                </div>
                <Typography>
                  {getMetadataAsArray(MetadataAsArray.BackupName, photo).join(
                    ' ',
                  )}
                </Typography>
                <Typography>{getMetadata(Metadata.Credit, photo)}</Typography>
              </Grid>
            ))}
          </Grid>
        ))}
      </div>
    </PrintPortal>
  );
};
