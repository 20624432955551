import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  },
  appBar: {
    flexGrow: 1,
  },
  toolbarPlaceHolder: theme.mixins.toolbar,
}));
