/*
list of for validation rules:
    - no leading space
    - no trailing space
    - no special characters
    - allow only one sequence space
    - no leading "-"
    - no trailing "-"
    - allow only one sequence of "-"
*/
const validationRegex = /[^A-Za-z0-9\s-]|\s{2,}|^\s|\s$|-{2,}|^-|-$/;

const invalidMessage = (
  <>
    <span>saisie non valide, les entrées ci-dessous sont interdites:</span>
    <ul>
      <li>caractères spéciaux</li>
      <li>accents</li>
      <li>espace au début et à la fin</li>
      <li>doubles espaces</li>
      <li>doubles tirets</li>
    </ul>
  </>
);

export function mustbeValidName(value: any): any {
  return validationRegex.test(value) ? invalidMessage : '';
}
