import { TextField, Typography } from '@mui/material';
import { SimpleSelect } from '@prismamedia/one-components';
import { GetSubjectQuery_query_subject_Subject } from '__generated__/queries-topic';
import { useGetUnderSubjects } from 'apollo/queries/photoMetadatas.photo.graphql';
import clsx from 'clsx';
import { CircularLoader, LoaderSizes } from 'components/Loader/Circular';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useStyles } from './styles';

const NEW_UNDER_SUBJECT = 'NEW_UNDER_SUBJECT';

interface UnderSubjectSelectProps {
  value: string;
  onChange?: Dispatch<SetStateAction<string>>;
  subject: GetSubjectQuery_query_subject_Subject;
  className?: string;
}

export const UnderSubjectSelect: FC<UnderSubjectSelectProps> = ({
  subject,
  value,
  onChange,
  className,
}) => {
  const classes = useStyles();
  const [selectedUnderSubject, setSelectedUnderSubject] = useState(
    value ? NEW_UNDER_SUBJECT : '',
  );
  const [init, setInit] = useState(true);

  const { metadatas: undersubjects, loading, error } = useGetUnderSubjects({
    photo: {
      Metadata_ID_subject_some: {
        value: subject.id,
      },
    },
  });

  useEffect(() => {
    if (init && undersubjects?.map(({ value: val }) => val).includes(value)) {
      setSelectedUnderSubject(value);
    }
    setInit(false);
  }, [init, undersubjects, value]);

  if (error) {
    return (
      <Typography className={classes.error} color="error">
        {error.message}
      </Typography>
    );
  }

  return (
    <div className={clsx(classes.wrapper, className)}>
      <SimpleSelect
        label="Sous-sujet"
        options={[
          { label: '-', value: '' },
          ...(undersubjects?.map(({ value: val }) => ({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            label: val!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value: val!,
          })) || []),
          { label: 'Nouveau sous-sujet', value: NEW_UNDER_SUBJECT },
        ]}
        value={selectedUnderSubject}
        onChange={(val) => {
          setSelectedUnderSubject(val);
          onChange?.(val === NEW_UNDER_SUBJECT ? '' : val);
        }}
        disabled={loading}
      />
      {selectedUnderSubject === NEW_UNDER_SUBJECT && (
        <TextField
          className={classes.newTextField}
          label="Titre du nouveau sous-sujet"
          value={value}
          onChange={({ target }) => onChange?.(target.value)}
          disabled={loading}
        />
      )}
      {loading && (
        <div className={classes.loader}>
          <CircularLoader size={LoaderSizes.xsmall} />
        </div>
      )}
    </div>
  );
};
