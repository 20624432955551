import { MenuItem } from '@mui/material';
import {
  notificationQueue,
  NotificationTypeEnum,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import { MetadataNameEnumType } from '__generated__/queries-photo';
import { GetSubjectQuery_query_subject_Subject } from '__generated__/queries-topic';
import { useExportToCentshare } from 'apollo/mutations/exportToCentshare.topic.graphql';
import {
  useUpdatePhoto,
  useUpdatePhotoFile,
} from 'apollo/mutations/photos.photo.graphql';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import moment from 'moment';
import { FC } from 'react';
import slugify from 'slugify';
import {
  getMetadata,
  getMetadataAsArray,
  Metadata,
  MetadataAsArray,
} from 'utils/getMetadata';
import {
  EDIT_STATUS,
  EXPORT_TYPE,
  getAssignmentData,
  PROCESS_STATUS,
} from '../utils';

interface PublishPhotoMenuProps {
  photos: SearchPhotosPhoto[];
  subject: GetSubjectQuery_query_subject_Subject;
}

export const PublishPhotoMenu: FC<PublishPhotoMenuProps> = ({
  subject,
  photos,
}) => {
  const { closeMenu } = useMenu();
  const { pushNotification, removeNotification } = useNotification();
  const updatePhoto = useUpdatePhoto();
  const updatePhotoFile = useUpdatePhotoFile();
  const exportToCentshare = useExportToCentshare();

  const send = async (selectedAssignment: string) => {
    closeMenu();
    const assignment = subject.assignments[Number(selectedAssignment)];

    const queue = photos.map((photo) => async () => {
      const underSubject = getMetadata(Metadata.Undersubject, photo);
      let history = getMetadataAsArray(MetadataAsArray.ProcessHistory, photo);

      let metadatas: {
        metadata: MetadataNameEnumType;
        value: string | undefined;
      }[] = [
        {
          metadata: Metadata.EditStatus,
          value: EDIT_STATUS.PUBLISHED,
        },
        {
          metadata: Metadata.SubjectPrisma,
          value: subject.title,
        },
        {
          metadata: Metadata.SubjectSlug,
          value: slugify(subject.title),
        },
        {
          metadata: Metadata.UndersubjectSlug,
          value: underSubject ? slugify(underSubject) : undefined,
        },
      ];

      if (selectedAssignment === EXPORT_TYPE.WEB) {
        metadatas = [
          ...metadatas,
          {
            metadata: Metadata.ExportTypeWeb,
            value: EXPORT_TYPE.WEB,
          },
        ];

        history = [
          ...history,
          `WEB le ${moment().format('DD/MM/yyyy[ à ]HH[h]mm')}`,
        ];
      } else {
        if (
          !(
            assignment?.assignment &&
            assignment.printIssue &&
            assignment.printPublication &&
            assignment.printHeading
          )
        ) {
          // this should never happen
          throw Error('invalid assignment data');
        }

        metadatas = [
          ...metadatas,
          {
            metadata: Metadata.Product,
            value: assignment.printPublication.key,
          },
          {
            metadata: Metadata.ProcessStatus,
            value: PROCESS_STATUS.ATTENTE,
          },
          {
            metadata: Metadata.Header,
            value: assignment.printHeading.title,
          },
          {
            metadata: Metadata.ExportTypePrint,
            value: EXPORT_TYPE.PRINT,
          },
          {
            metadata: Metadata.Issue,
            value: assignment.printIssue.title,
          },
          {
            metadata: Metadata.IdAssignment,
            value: assignment.assignment.id,
          },
        ];

        history = [
          ...history,
          `${assignment.printPublication.key} ${
            assignment.printIssue.title
          } le ${moment().format('DD/MM/yyyy[ à ]HH[h]mm')}`,
        ];
      }

      // eslint-disable-next-line fp/no-mutating-methods
      metadatas.push(
        ...history.map((h) => ({
          metadata: MetadataAsArray.ProcessHistory,
          value: h,
        })),
      );

      await updatePhoto({
        photo,
        metadatas,
      });

      // write metadatas to target asset file
      const updatedPhoto = await updatePhotoFile({ photoId: photo.id });

      if (!updatedPhoto.data?.updatePhotoFile) {
        // this should never happen
        // TODO: fix backend query to be more assertive
        throw Error('invalid updatePhotoFile result data');
      }

      if (selectedAssignment !== EXPORT_TYPE.WEB) {
        if (
          !(
            assignment?.assignment &&
            assignment.printIssue &&
            assignment.printPublication &&
            assignment.printHeading
          )
        ) {
          // this should never happen
          throw Error('invalid assignment data');
        }

        await exportToCentshare({
          assignment: assignment.assignment.id,
          issue: assignment.printIssue.title,
          subject: subject.title,
          brand: assignment.printPublication.key,
          photos: [photo.id],
        });

        await updatePhoto({
          photo: updatedPhoto.data.updatePhotoFile,
          metadatas: [
            {
              metadata: Metadata.ProcessStatus,
              value: PROCESS_STATUS.TRAITE,
            },
          ],
        });
      }
    });

    try {
      await notificationQueue(
        queue,
        pushNotification,
        removeNotification,
        'Envoi',
      );
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }
  };

  return (
    <>
      {[
        ...subject.assignments.map((assignment, i) => ({
          value: String(i),
          label: getAssignmentData(assignment),
        })),
        { value: EXPORT_TYPE.WEB, label: 'WEB' },
      ].map((option) => (
        <MenuItem onClick={() => send(option.value)} key={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </>
  );
};
