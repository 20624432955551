import { Button, DialogActions, DialogContent } from '@mui/material';
import { RawUser } from '@prismamedia/one-user';
import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { GetSubjectQuery_query_subject_Subject_subjectUsers_SubjectUser } from '../../../__generated__/queries-topic';
import { UserInput } from '../../Field/UserInput';
import { useStyles } from './styles';

interface ShareFormProps {
  onCancel: (v?: string) => void;
  onSubmit: (v: ShareFormModel) => void;
  subjectUsers?: GetSubjectQuery_query_subject_Subject_subjectUsers_SubjectUser[];
}

export interface ShareFormModel {
  selectedUsers: RawUser[];
}

export const ShareForm: FC<ShareFormProps> = ({
  subjectUsers,
  onSubmit,
  onCancel,
}) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState<RawUser[]>(
    subjectUsers?.map(
      ({ user, userId }) =>
        ({
          name: user && user.name,
          email: user && user.email,
          id: userId,
          avatarUrl: user && user.avatarUrl,
        } as RawUser),
    ) || [],
  );

  return (
    <Form
      initialValues={{ message: '' }}
      onSubmit={() => onSubmit({ selectedUsers })}
    >
      {({ form }) => (
        <>
          <DialogContent className={classes.root}>
            <UserInput
              onChange={setSelectedUsers}
              onDelete={(_, users) => {
                setSelectedUsers(users);
                form.submit();
              }}
              value={selectedUsers}
              textFieldProps={{
                placeholder: "Entrez le nom ou l'email d'un collaborateur",
              }}
              multiple
              textBeforeChips="Le sujet est actuellement visible par :"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCancel()} color="primary">
              Annuler
            </Button>
            <Button
              onClick={async () => {
                await form.submit();
                onCancel();
              }}
              color="primary"
              disabled={selectedUsers.length === 0}
              variant="contained"
            >
              Enregistrer et fermer
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
};
