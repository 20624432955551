import React, { useEffect, useRef, useState } from 'react';
import {
  GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication_printIssues_PrintIssue,
  SubjectListPage_GetLocationTreeQuery,
} from '../../../../__generated__/queries-topic';
import { useGetPrintIssuesByPrintPublicationGetter } from '../../../../apollo/queries/getPrintIssuesByPrintPublication.topic.graphql';
import {
  checkboxStatus,
  mapFromTree,
  NodeMap,
  TreeList,
  TreeNodeRef,
} from '../../../../components/TreeList';
import { sortPrintIssues } from '../../../../utils/sortPrintIssues';

interface LocationTreeProps {
  open: boolean;
  timeout: 0 | 'auto';
  treeList: SubjectListPage_GetLocationTreeQuery;
  selected: string[];
  onChange: (ids: string[]) => void;
}

export const LocationTree: React.FC<LocationTreeProps> = ({
  timeout,
  onChange,
  selected,
  treeList,
  open,
}) => {
  const timerId = useRef<ReturnType<typeof setTimeout>>(null);
  const [locationNodeMap, setLocationNodeMap] = useState<NodeMap>(
    mapFromTree(),
  );
  const getMorePrintIssues = useGetPrintIssuesByPrintPublicationGetter();

  useEffect(() => {
    const updateLocationTreeState = () => {
      const getCheckedStatus = (id: string) => {
        return selected.includes(id)
          ? checkboxStatus.checked
          : checkboxStatus.unchecked;
      };

      const getLastSelectedPrintIssueIndex = (
        printIssues: GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication_printIssues_PrintIssue[],
        selectedIds: string[],
      ) => {
        const printIssueIds = printIssues.map((issue) => issue.id);
        return Math.max(...selectedIds.map((id) => printIssueIds.indexOf(id)));
      };

      const tree = treeList.brands.map((brand) => ({
        id: brand.id,
        title: brand.title,
        open: open && brand.printPublications.length > 0,
        checked: getCheckedStatus(brand.id),
        children: brand.printPublications
          .map((printPublication) => {
            const lastPrintIssueIndex = getLastSelectedPrintIssueIndex(
              printPublication.printIssues,
              selected,
            );
            const limitNodeDisplay =
              lastPrintIssueIndex > 10
                ? printPublication.printIssues.length
                : 10;

            return {
              id: printPublication.id,
              title: printPublication.title,
              open: open && printPublication.printIssues.length > 0,
              checked: getCheckedStatus(printPublication.id),
              children: sortPrintIssues(printPublication.printIssues)
                .slice(0, limitNodeDisplay)
                .map((printIssue) => ({
                  id: printIssue.id,
                  title: printIssue.title,
                  checked: getCheckedStatus(printIssue.id),
                })),
              more: limitNodeDisplay < printPublication.printIssues.length,
            };
          })
          .concat(
            brand.websites.map((website) => ({
              id: website.id,
              title: `WEB - ${website.title}`,
              open: false,
              checked: getCheckedStatus(website.id),
              children: [],
              more: false,
            })),
          ),
      }));
      setLocationNodeMap(mapFromTree(tree, true));
    };

    updateLocationTreeState();
  }, [treeList, selected, open]);

  return (
    <TreeList
      timeout={timeout}
      nodeMap={locationNodeMap}
      onChange={setLocationNodeMap}
      onNodeCheck={async (checkedNode: TreeNodeRef, nodeMap: NodeMap) => {
        const checkedIds = Object.values(nodeMap)
          .filter((node) => node.checked === checkboxStatus.checked)
          .map((node) => node.id);
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/21310#issuecomment-367919251
        timerId.current && window.clearTimeout(timerId.current);
        (timerId.current as any) = window.setTimeout(() => {
          onChange(checkedIds);
        }, 1000);
      }}
      onMoreClick={async (node: TreeNodeRef) => {
        const { printPublication } = await getMorePrintIssues({
          id: node.id,
          excludeIds: node.children,
        });

        return sortPrintIssues(
          printPublication?.printIssues.map((issue) => ({
            id: issue.id,
            title: issue.title,
          })) || [],
        );
      }}
    />
  );
};
