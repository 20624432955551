import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  column: {
    maxHeight: 'calc(100vh - 96px)',
    overflowY: 'scroll',
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  columnContainer: {
    height: 'calc(100vh - 96px)',
  },
}));
