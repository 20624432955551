import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const dropzoneActiveColor = '#4285F4';

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'block',
    backgroundColor: theme.palette.grey['100'],
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    borderRadius: '5px',
    position: 'relative',
  },
  dropzone: {
    width: '100% ',
  },
  dropzoneActive: {
    '&:after': {
      content: '""',
      boxShadow: `inset 2px 2px ${dropzoneActiveColor}, inset -2px -2px ${dropzoneActiveColor}`,
      width: `100%`,
      height: `100%`,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: theme.zIndex.modal,
    },
    '& $dropzoneNotificationContainer': {
      transform: 'translateY(+20px)',
      opacity: 1,
      zIndex: 'auto',
      paddingBottom: theme.spacing(3),
    },
  },
  dropzoneNotificationContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  Hightlight: {
    backgroundColor: 'rgb(188, 185, 236)',
  },
  subTitle: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    paddingTop: 0,
  },
  typo: {
    padding: theme.spacing(3),
    '& > input': {
      position: 'absolute',
      marginLeft: '100px',
      opacity: 0,
      height: '23px',
      cursor: 'pointer',
    },
    '& > span': {
      cursor: 'pointer',
      fontWeight: 500,
      textDecoration: 'underline',
    },
  },
  tableHead: {
    '& > tr > th': {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  tableBody: {
    position: 'relative',
    '& > tr': {
      borderRight: '1px solid transparent',
      borderLeft: '1px solid transparent',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        '& > td': {
          color: theme.palette.primary.light,
          '&:last-child > div': {
            display: 'block',
          },
        },
      },
      '& > td': {
        fontSize: '1rem',
        position: 'relative',
        '&:last-child > div': {
          display: 'none',
        },
      },
    },
  },
}));
