import ArrowBack from '@mui/icons-material/ArrowBack';
import React, { FC, useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useGetPrintHeading } from '../../apollo/queries/printTemplate.topic.graphql';
import { AppBar } from '../../components/AppBar';
import { auth } from '../../utils/auth';
import { ErrorView } from './ErrorView';
import { useStyles } from './index.styles';
import { TemplateList } from './TemplateList';
import { Toolbar } from './Toolbar';
import { ViewVariant, ViewVariantStorage } from './utils';

export const PrintTemplate: FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ printHeadingId: string }>();
  const printHeadingId = match ? match.params.printHeadingId : '';
  const classes = useStyles();
  const { loading, data, refetch } = useGetPrintHeading(printHeadingId);
  const [viewVariant, setViewVariant] = useState<ViewVariant>(
    ViewVariantStorage.get(),
  );

  const handleViewVariantChangle = useCallback((variantView: ViewVariant) => {
    ViewVariantStorage.set(variantView);
    setViewVariant(variantView);
  }, []);

  if (!loading && !data?.printHeading) {
    return (
      <ErrorView>
        Une erreur s'est produite lors du chargement des données de la têtière
      </ErrorView>
    );
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.toolbarPlaceHolder} />
      <AppBar
        title="Gestion des gabarits."
        icon={<ArrowBack />}
        withSearchBar={false}
        withUnitSelector={false}
        avatarUrl={auth.user?.avatarUrl}
        className={classes.appBar}
        onIconClick={history.goBack}
      />
      <Toolbar
        loading={loading}
        printHeading={data?.printHeading}
        refetch={refetch}
        viewVariant={viewVariant}
        onViewChange={handleViewVariantChangle}
      />
      <TemplateList
        loading={loading}
        printHeadingId={printHeadingId}
        printHeadingPrintTemplates={data?.printHeading?.printTemplates}
        viewVariant={viewVariant}
        count={data?.printHeading?.printTemplateCount}
      />
    </div>
  );
};
