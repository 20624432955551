import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectChangeEvent,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import classNames from 'classnames';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useStyles } from './styles';

enum selectVariant {
  default = 'default',
  white = 'white',
}

interface FinalFormMaterialSelectProps extends FieldRenderProps {
  name: string;
  label: string;
  selectProps?: SelectProps;
  required?: boolean;
  onChange?: (e: SelectChangeEvent<any>) => void;
  variant?: selectVariant;
}

export const Select: FC<FinalFormMaterialSelectProps> = ({
  input: { name, onChange, value },
  meta,
  children,
  variant = selectVariant.default,
  selectProps = {},
  ...rest
}) => {
  const classes = useStyles();
  const hasError = meta.error && meta.touched;

  return (
    <FormControl
      fullWidth
      required={rest.required}
      className={classNames(
        classes[`variant${variant}` as keyof typeof classes],
        classes.root,
      )}
      variant="standard"
    >
      {rest.label && (
        <InputLabel htmlFor={name} error={hasError}>
          {hasError ? meta.error : rest.label}
        </InputLabel>
      )}
      <MuiSelect
        {...selectProps}
        {...rest}
        id={name}
        value={value}
        onChange={(e) => {
          onChange(e);
          if (rest.onChange) {
            rest.onChange(e);
          }
        }}
        displayEmpty
        name={name}
        error={hasError}
        classes={{ select: classNames(!value && classes.placeholder) }}
        disableUnderline={variant === selectVariant.white && true}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
};
