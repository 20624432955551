import MenuItem from '@mui/material/MenuItem';
import React, { FC } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { Select } from '../../../components/FinalFormMaterial/Select';
import { useGetSubjectCategory } from './getSubjectCategoryQuery.topic.graphql';

interface AssignmentProps {
  subjectCategory: string;
  unitId: string;
}

export const SubjectCategory: React.FC<AssignmentProps> = ({
  subjectCategory,
  unitId,
}) => {
  const {
    data: subjectCategoryData,
    loading: subjectCategoryLoading,
  } = useGetSubjectCategory({ unitId });

  if (subjectCategoryLoading) {
    return <div>LOADING</div>;
  }
  if (!subjectCategoryData) {
    return <div>ERROR</div>;
  }

  const mainCategory = subjectCategoryData.subjectCategories.find(
    (cat) => cat.id === subjectCategory,
  );
  return (
    <>
      <Field
        component={Select as FC<FieldRenderProps>}
        label="Thématique"
        name="subjectCategory"
      >
        {subjectCategoryData.subjectCategories.map((sc) => (
          <MenuItem key={sc.id} value={sc.id}>
            {sc.title}
          </MenuItem>
        ))}
      </Field>

      <Field
        component={Select as FC<FieldRenderProps>}
        selectProps={{ disabled: !subjectCategory }}
        label="Sous-thématique"
        name="subSubjectCategory"
      >
        {mainCategory &&
          mainCategory.children.map((child) => (
            <MenuItem key={child.id} value={child.id}>
              {child.title}
            </MenuItem>
          ))}
      </Field>
    </>
  );
};
