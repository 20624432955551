import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '--zoom-value': 1,
  },
  form: {
    '@media print': {
      display: 'none',
    },
  },
  input: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textAlign: 'start',
  },
  inputSignature: {
    width: 350,
    margin: theme.spacing(3),
    textAlign: 'start',
  },
  main: {
    marginTop: theme.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .DraftEditor-root': {
      fontSize: 18,
    },
  },
  paperWrapper: {
    width: '100vw',
    overflow: 'hidden',
    position: 'relative',
    marginTop: '40px',
  },
  paper: {
    width: 'calc(100% - 80px)',
    maxWidth: '806px',
    minHeight: '80vh',
    paddingLeft: 105,
    paddingRight: 105,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    transformOrigin: 'top left',
    zoom: 'var(--zoom-value)',
    position: 'absolute',
  },
  charCounter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    fontSize: '12px',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: theme.palette.grey[500],
  },
  bodyEditorStyle: {
    '& .DraftEditor-root': {
      color: theme.palette.grey['800'],
    },
  },
  megadraftDisabled: {
    '& .megadraft-editor .DraftEditor-root': {
      color: theme.palette.text.disabled,
    },
  },
  saveButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    position: 'relative',
    flex: '1 0 auto',
    '&:after': {
      content: '""',
      width: '1px',
      backgroundColor: 'rgba(0,0,0,0.2)',
      position: 'absolute',
      right: `-${theme.spacing(3)}`,
      ...theme.mixins.toolbar,
    },
  },
  appToolbarChildren: {
    alignSelf: 'stretch',
  },
  prolexisButton: {
    marginLeft: theme.spacing(2),
  },
  loader: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}));
