import { CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import { EditInfoProps } from '../utils';
import { useStyles } from './styles';
import clsx from 'clsx';
import { SimpleCheckBox } from '@prismamedia/one-components';

export const CheckBoxEdit: FC<EditInfoProps & { checkedValue: string }> = ({
  value,
  onChange,
  loading,
  className,
  title,
  onValidate,
  checkedValue,
}) => {
  const classes = useStyles();

  if (Array.isArray(value)) return null;

  return (
    <div className={clsx(classes.wrapper, className)}>
      <SimpleCheckBox
        label={title}
        checked={value === checkedValue}
        onChange={(checked) => {
          onChange?.(checked ? checkedValue : '');
          onValidate?.(checked ? checkedValue : '');
        }}
      />
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
};
