import { gql, useQuery } from '@apollo/client';
import {
  LocationPage_GetDeletePrintIssueInformationQuery,
  LocationPage_GetDeletePrintIssueInformationQueryVariables,
} from '../../../../__generated__/queries-topic';

const GET_DELETE_PRINT_ISSUE_INFORMATION = gql`
  query LocationPage_GetDeletePrintIssueInformation($id: ID!) {
    printIssue(where: { id: $id }) {
      id
      assignmentCount
      title
      printHeadings(first: 100, where: { assignments_some: { id_not: "" } }) {
        title
        assignmentCount
      }
      printPublication {
        id
        title
      }
    }
  }
`;

export const useGetDeletePrintIssueInformation = (
  variables: LocationPage_GetDeletePrintIssueInformationQueryVariables,
) =>
  useQuery<
    LocationPage_GetDeletePrintIssueInformationQuery,
    LocationPage_GetDeletePrintIssueInformationQueryVariables
  >(GET_DELETE_PRINT_ISSUE_INFORMATION, { variables, skip: !variables.id });
