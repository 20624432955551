import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  assignmentBox: {
    display: 'flex',
    backgroundColor: theme.palette.grey['100'],
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: '5px',
  },
  title: {
    marginBottom: 10,
  },
  addSupport: {
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.primary.light,
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '&.disabled': {
      cursor: 'not-allowed',
    },
  },
  buttonsWrapper: {
    flexBasis: 220,
    minWidth: 220,
    textAlign: 'right',
  },
}));
