import { Button } from '@mui/material';
import React, { FC } from 'react';
import { TopicLink, TopicLinkProps } from '../../Link/TopicLink';
import { useStyles } from './styles';

export const LinkButton: FC<TopicLinkProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <TopicLink className={classes.link} {...rest}>
      <Button variant="contained" color="primary" className={classes.button}>
        {children}
      </Button>
    </TopicLink>
  );
};
