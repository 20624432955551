import { Grid, Typography } from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { useExportPrintIssueToCenshare } from 'apollo/mutations/exportToCentshare.topic.graphql';
import { FC, useCallback, useState } from 'react';
import { Duration, wait } from 'utils/wait';
import { LocationItem } from '../../../components/ListItem/LocationItem';
import { CircularLoader } from '../../../components/Loader/Circular';
import { SortablePrintIssue } from '../../../utils/sortPrintIssues';
import { useStyles } from '../styles';
import { DeletePrintIssueDialog } from './DeletePrintIssueDialog';
import { useGetPrintIssuesByPrintPublication } from './getPrintIssuesByPrintPublication.topic.graphql';
import { PrintIssueDialogForm } from './PrintIssueDialogForm';

interface PrintIssueListProps {
  unitId: string;
  printPublicationId: string;
  printIssueId: string;
  addFilters: (filters: { [key: string]: string | string[] | null }) => void;
}

interface PrintIssueState {
  printIssueFormOpen: boolean;
  editedPrintIssue?: SortablePrintIssue;
  editedPrintIssueFrom?: SortablePrintIssue;
  deletedPrintIssue?: SortablePrintIssue;
  printIssueDeleteFormOpen: boolean;
}

export const PrintIssueList: FC<PrintIssueListProps> = ({
  unitId,
  printPublicationId,
  printIssueId,
  addFilters,
}) => {
  const classes = useStyles();
  const [
    {
      printIssueFormOpen,
      editedPrintIssue,
      editedPrintIssueFrom,
      deletedPrintIssue,
      printIssueDeleteFormOpen,
    },
    setPrintIssueState,
  ] = useState<PrintIssueState>({
    printIssueFormOpen: false,
    editedPrintIssue: undefined,
    editedPrintIssueFrom: undefined,
    deletedPrintIssue: undefined,
    printIssueDeleteFormOpen: false,
  });
  const {
    data: printIssuesData,
    loading: printIssuesLoading,
  } = useGetPrintIssuesByPrintPublication({ printPublicationId });
  const exportPrintIssueToCenshare = useExportPrintIssueToCenshare();
  const notificate = useNotification();

  const exportPrintIssue = useCallback(
    async (printIssue: { id: string; title: string }) => {
      const startNotifId = '';
      try {
        notificate.pushNotification({
          message: `Envoie de(s) tétiére(s) de Support ${printIssue.title} est en cours...`,
          type: NotificationTypeEnum.info,
          autoHideDuration: false,
        });

        await exportPrintIssueToCenshare({ printIssueId: printIssue.id });

        notificate.removeNotification(startNotifId);
        notificate.pushNotification({
          message: `L'ensemble des tétiéres de Support ${printIssue.title} sont envoyés avec succés à Censhare`,
          type: NotificationTypeEnum.success,
        });
      } catch (err) {
        notificate.removeNotification(startNotifId);
        const errors = (err as Error).message.split('\n');
        // eslint-disable-next-line fp/no-loops
        for (const e of errors) {
          notificate.pushNotification({
            message: `Support ${printIssue.title}, ${e}`,
            type: NotificationTypeEnum.error,
          });
          await wait(200 * Duration.Millisecond);
        }
      }
    },
    [exportPrintIssueToCenshare, notificate],
  );

  return (
    <Grid
      item
      xs
      className={classes.column}
      data-cy="location-grid-publication"
    >
      {!printPublicationId ? (
        <div className={classes.placeholder}>
          <Typography variant="h5">Choisissez un support.</Typography>
        </div>
      ) : printIssuesLoading ? (
        <CircularLoader />
      ) : !printIssuesData || !printIssuesData.printIssues ? (
        <div>Choisissez un support.</div>
      ) : (
        <>
          <PrintIssueDialogForm
            open={printIssueFormOpen}
            printIssues={printIssuesData.printIssues}
            handleClose={() =>
              setPrintIssueState((prevState) => ({
                ...prevState,
                printIssueFormOpen: false,
                editedPrintIssue: undefined,
                editedPrintIssueFrom: undefined,
              }))
            }
            printPublicationId={printPublicationId}
            unitId={unitId}
            printIssue={editedPrintIssue}
            fromPrintIssue={editedPrintIssueFrom}
          />
          <DeletePrintIssueDialog
            open={printIssueDeleteFormOpen}
            printIssue={deletedPrintIssue}
            handleClose={() =>
              setPrintIssueState((prevState) => ({
                ...prevState,
                deletedPrintIssue: undefined,
                printIssueDeleteFormOpen: false,
              }))
            }
            printPublicationId={printPublicationId}
            printPublicationTitle={
              printIssuesData.printPublication
                ? printIssuesData.printPublication.title
                : ''
            }
          />
          <LocationItem
            sticky
            data-cy="location-create-item-unit"
            text="Créer une nouvelle parution"
            onClick={() =>
              setPrintIssueState((prevState) => ({
                ...prevState,
                printIssueFormOpen: true,
              }))
            }
            header
          />

          {printIssuesData.printIssues.map((printIssue) => (
            <LocationItem
              data-cy="location-item-unit"
              selected={printIssue.id === printIssueId}
              text={printIssue.title}
              onClick={() => addFilters({ printIssue: printIssue.id })}
              key={printIssue.id}
              menuActions={[
                {
                  label: 'modifier',
                  onClick: () => {
                    setPrintIssueState((prevState) => ({
                      ...prevState,
                      printIssueFormOpen: true,
                      editedPrintIssue: printIssue,
                    }));
                  },
                },
                {
                  label: 'dupliquer',
                  onClick: () => {
                    setPrintIssueState((prevState) => ({
                      ...prevState,
                      printIssueFormOpen: true,
                      editedPrintIssueFrom: printIssue,
                    }));
                  },
                },
                {
                  label: 'supprimer',
                  onClick: () => {
                    setPrintIssueState((prevState) => ({
                      ...prevState,
                      deletedPrintIssue: printIssue,
                      printIssueDeleteFormOpen: true,
                    }));
                  },
                },
                {
                  label: 'envoyer à Censhare',
                  onClick: () => {
                    exportPrintIssue(printIssue);
                  },
                },
              ]}
            />
          ))}
        </>
      )}
    </Grid>
  );
};
