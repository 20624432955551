import { Button } from '@mui/material';
import React, { FC, useState } from 'react';
import { AppToolbar } from '../../../components/AppToolbar';
import { CircularLoader } from '../../../components/Loader/Circular';
import { paths, replaceParams } from '../../../routing';
import { format } from '../../../utils/dateFnsFormat';
import { getColorByStatus } from '../../../utils/rawArticleStatus';
import { ArticleHistoryForm } from '../ArticleHistoryForm';
import { apiToForm } from '../ArticleHistoryForm/dataTransfomer';
import { useGetRawArticleVersionArticleHistory } from './getRawArticleVersionHistory.topic.graphql';
import { useStyles } from './styles';

interface ArticleWrapperProps {
  idRawArticleVersion: string;
  idRawArticle: string;
}

export const ArticleWrapper: FC<ArticleWrapperProps> = ({
  idRawArticleVersion,
  idRawArticle,
}) => {
  const classes = useStyles();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const {
    data: versionData,
    loading: versionLoading,
  } = useGetRawArticleVersionArticleHistory({ id: idRawArticleVersion });

  const toggleConfirmModal = () => setIsConfirmModalOpen((prev) => !prev);
  if (versionLoading) {
    return (
      <div className={classes.majorVersionsPaperWrapper}>
        <CircularLoader />
      </div>
    );
  }
  if (!versionData) {
    return <div className={classes.majorVersionsPaperWrapper}>ERROR</div>;
  }

  let versionDate = null;
  let colorStatus = '';
  if (versionData?.rawArticleVersion) {
    versionDate = new Date(versionData.rawArticleVersion.createdAt);
    colorStatus = getColorByStatus(versionData.rawArticleVersion.status);
  }

  const formValues = apiToForm(versionData);
  return (
    <div className={classes.majorVersionsPaperWrapper}>
      <>
        <AppToolbar
          className={classes.appToolbar}
          previousRoute={{
            pathname: replaceParams(paths.ARTICLE_EDIT, {
              id: idRawArticle,
            }),
          }}
          childrenClass={classes.children}
          color={colorStatus}
          title={
            versionDate !== null
              ? `${format(versionDate, 'EEEE d MMMM')} à ${format(
                  versionDate,
                  'H',
                )}h${format(versionDate, 'mm')}`
              : ''
          }
        >
          <Button variant="contained" onClick={toggleConfirmModal}>
            Restaurer cette version
          </Button>
        </AppToolbar>
        <ArticleHistoryForm
          idRawArticle={idRawArticle}
          formValues={formValues}
          isConfirmModalOpen={isConfirmModalOpen}
          toggleConfirmModal={toggleConfirmModal}
        />
      </>
    </div>
  );
};
