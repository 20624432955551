import { intersperse } from 'ramda';
import { FC } from 'react';
import {
  ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment,
  GetSubjectForMediaStatusDialogQuery_query_subject_Subject_assignments_Assignment,
  GetSubjectQuery_query_subject_Subject_assignments_FlatAssignment,
  GetSubjectsQuery_query_subjects_Subject_assignments_Assignment,
} from '../../__generated__/queries-topic';
import { PrintIssueLink } from '../Link/PrintIssueLink';

interface AssignmentTitleProps {
  assignment:
    | GetSubjectForMediaStatusDialogQuery_query_subject_Subject_assignments_Assignment
    | GetSubjectQuery_query_subject_Subject_assignments_FlatAssignment
    | ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment
    | null;
  withLinkOnPrintIssue?: boolean;
}

export const AssignmentTitle: FC<AssignmentTitleProps> = ({
  assignment,
  withLinkOnPrintIssue,
}) => {
  // This is a temporary workaround as we have to handle two different types
  // When all components will use the flatassignments it has to be removed
  const isFlatAssignment = (
    object: any,
  ): object is GetSubjectsQuery_query_subjects_Subject_assignments_Assignment => {
    return 'assignment' in object;
  };
  if (assignment && assignment.printHeading) {
    return (
      <>
        {intersperse(' • ', [
          !isFlatAssignment(assignment)
            ? assignment.printHeading?.printIssue.printPublication.title
            : assignment.printPublication?.title,
          withLinkOnPrintIssue ? (
            <PrintIssueLink
              key={
                !isFlatAssignment(assignment)
                  ? assignment.printHeading?.printIssue.id
                  : assignment.printIssue?.id
              }
              printIssueId={
                !isFlatAssignment(assignment)
                  ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    assignment.printHeading!.printIssue.id
                  : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    assignment.printIssue!.id
              }
              printIssueTitle={
                !isFlatAssignment(assignment)
                  ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    assignment.printHeading!.printIssue.title
                  : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    assignment.printIssue!.title
              }
              printPublicationId={
                !isFlatAssignment(assignment)
                  ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    assignment.printHeading!.printIssue.printPublication.id
                  : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    assignment.printPublication!.title
              }
            />
          ) : !isFlatAssignment(assignment) ? (
            assignment.printHeading?.printIssue.title
          ) : (
            assignment.printIssue?.title
          ),
          assignment.printHeading?.title,
        ])}
      </>
    );
  }

  if (assignment && assignment.printIssue) {
    return (
      <>
        {intersperse(' • ', [
          !isFlatAssignment(assignment)
            ? assignment.printIssue?.printPublication.title
            : assignment.printPublication?.title,
          withLinkOnPrintIssue ? (
            <PrintIssueLink
              key={assignment.printIssue?.id}
              printIssueId={assignment.printIssue?.id}
              printIssueTitle={assignment.printIssue?.title}
              printPublicationId={
                !isFlatAssignment(assignment)
                  ? assignment.printIssue.printPublication.id
                  : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    assignment.printPublication!.id
              }
            />
          ) : (
            assignment.printIssue?.title
          ),
        ])}
      </>
    );
  }

  if (assignment && assignment.printPublication) {
    return <>{assignment.printPublication.title}</>;
  }

  if (assignment && assignment.website) {
    return <>WEB - {assignment.website.title}</>;
  }
  return <>En attente d'affectation.</>;
};
