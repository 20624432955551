import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 340;

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbarDrawer: {
    ...theme.mixins.toolbar,
    backgroundColor: '#424242',
    alignItems: 'center',
    display: 'flex',
    fontSize: 20,
    color: 'white',
  },
  majorVersionPaper: {
    height: theme.spacing(8),
    width: '100%',
    paddingLeft: 0,
  },
  minorVersionItemList: {
    height: theme.spacing(6),
    width: '100%',
  },
  leftBorderMajorVersionPaper: {
    height: 64,
    width: theme.spacing(0.5),
  },
  majorVersionsPaperWrapper: {
    paddingTop: theme.spacing(6),
  },
  arrowWrapper: {
    marginLeft: theme.spacing(2),
  },
  avatarWrapper: {
    marginLeft: theme.spacing(2),
  },
  avatarWrapperMinor: {
    height: 24,
    width: 24,
    marginLeft: 12,
  },
  titleWrapper: {
    marginLeft: theme.spacing(2),
  },
  typographyTitleDrawer: {
    fontSize: 16,
    fontWeight: 500,
    color: '#FFFFFF',
  },
  titleVersion: {
    color: 'white !important',
  },
  textMinorVersion: {
    fontSize: 14,
  },
  textMinorVersionMargin: {
    marginLeft: 24,
  },
}));
