import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArticleWrapper } from './ArticleWrapper';
import { MajorVersionDrawer } from './MajorVersionsDrawer';

export const ArticleHistory: FC = () => {
  const { idRawArticle } = useParams<{ idRawArticle: string }>();
  const [currentVersionId, setCurrentVersionId] = useState('');

  return (
    <>
      <MajorVersionDrawer
        onChange={setCurrentVersionId}
        idRawArticle={idRawArticle}
      />
      <ArticleWrapper
        idRawArticleVersion={currentVersionId || ''}
        idRawArticle={idRawArticle}
      />
    </>
  );
};
