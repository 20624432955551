import { gql, useMutation } from '@apollo/client';
import {
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables,
} from '../../../../__generated__/queries-topic';

const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: ID!) {
    deleteAttachment(where: { id: $id }) {
      id
    }
  }
`;

export const useAttachmentDeleter = () =>
  useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(
    DELETE_ATTACHMENT,
  );
