import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { FC } from 'react';
import { LinkButton } from '../../../../components/Button/LinkButton';
import { paths } from '../../../../routing';
import { MediaSearchStatusFilter } from '../../MediaSearchStatusFilter';
import { TextFilter } from '../../TextFilter';
import { useStyles } from './styles';

interface FiltersProps {
  selectedTexts: string[];
  onTextChange: (statuses: string[]) => void;
  selectedMediaSearchStatus: string[];
  onMediaSearchStatusChange: (statuses: string[]) => void;
  showEmpty: boolean;
  onEmptyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  printPublicationId?: string;
  printIssueId?: string;
}

export const SubjectListByIssueFilters: FC<FiltersProps> = ({
  selectedTexts,
  onTextChange,
  showEmpty,
  onEmptyChange,
  onMediaSearchStatusChange,
  selectedMediaSearchStatus,
  printIssueId,
  printPublicationId,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.separator}>
        <LinkButton
          to={{
            pathname: paths.SUBJECT_LIST,
            search: `?SubjectList_type=issue${
              printPublicationId
                ? `&SubjectList_printPublication=${printPublicationId}`
                : ''
            }${printIssueId ? `&SubjectList_printIssue=${printIssueId}` : ''}`,
          }}
          whiteList={['unit']}
        >
          Réinitialiser les filtres
        </LinkButton>
      </div>
      <div className={classes.separator}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              checked={showEmpty}
              onChange={onEmptyChange}
              color="primary"
              value="empty"
            />
          }
          label={
            <Typography className={classes.label}>
              Voir les tétières sans sujet.
            </Typography>
          }
        />
      </div>
      <div className={classes.separator}>
        <Typography variant="h5">Textes</Typography>
        <TextFilter
          onChange={onTextChange}
          selectedTextStatuses={selectedTexts}
        />
      </div>
      <div className={classes.separator}>
        <Typography variant="h5">Recherche media</Typography>
        <MediaSearchStatusFilter
          onChange={onMediaSearchStatusChange}
          selectedMediaSearchStatuses={selectedMediaSearchStatus}
        />
      </div>
    </>
  );
};
