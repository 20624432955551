import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  content: {
    overflowY: 'initial',
  },
  selectionWrapper: {
    width: `calc(100% + ${theme.spacing(3)} * 2)`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    margin: `0 -${theme.spacing(3)}`,
  },
  gridList: {
    flexWrap: 'nowrap',
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    overflowY: 'auto',
  },
  photoItem: {
    height: 220,
    flexBasis: 180,
    flexShrink: 0,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
}));
