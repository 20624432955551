import { gql } from '@apollo/client';

export const PRINT_HEADING_FRAGMENT = gql`
  fragment PrintHeadingFragment on PrintHeading {
    id
    title
    order
    hasAutomaticSubject
    autoPrototype
    printTemplateCount
    assignmentCount
    printTemplates(first: 50) {
      id
      active
      createdAt
      updatedAt
      printTemplate {
        id
      }
    }
  }
`;
