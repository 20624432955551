import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  draftPreviewContainer: {
    overflow: 'auto',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  draftImgContainer: {
    margin: '15px 60px',
    position: 'relative',
  },
  draftPreviewImg: {
    boxShadow: '0px 0px 7px -1px rgba(0,0,0,0.54);',
    width: '100%',
    height: 'auto',
    minWidth: '30vw',
    maxWidth: '35vw',
    maxHeight: '100%',
  },
  draftPreviewImgLoading: {
    filter: 'blur(1.2px)',
  },
  draftPreviewBadge: {
    position: 'absolute',
    transform: 'scale(1) translate(-140%, 0%)',
    transformOrigin: '0% 0%',
    color: '#fff',
    backgroundColor: '#bdbdbd',
    width: '35px',
    height: '35px',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    flexShrink: 0,
    userSelect: 'none',
    borderRadius: '50%',
    justifyContent: 'center',
    '& > span': {
      transform: 'unset',
      position: 'relative',
      fontSize: '1rem',
    },
  },
  fullWidth: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
}));
