import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  inputElement: {
    display: 'none',
  },
  formControl: {
    marginTop: theme.spacing(4),
    width: '-webkit-fill-available',
  },
  formSubtitle: {
    marginBottom: theme.spacing(2),
  },
  formField: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  fields: {
    width: '100%',
  },
}));
