import { Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  MediaSearchStatus,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';
import { CircularLoader } from '../../../components/Loader/Circular';
import { PrintPortal } from '../../../components/PrintPortal';
import { useGetFilteredSubjectListByIssue } from './getFilteredSubjectListByIssue.topic.graphql';
import { IssuesList } from './IssuesList';

interface SubjectListByIssueProps {
  issueId: string;
  showEmpty: boolean;
  selectedTexts: RawArticleStatus[];
  selectedMediaSearchStatuses: MediaSearchStatus[];
}

export const SubjectListByIssue: FC<SubjectListByIssueProps> = ({
  issueId,
  showEmpty,
  selectedMediaSearchStatuses,
  selectedTexts,
}) => {
  const {
    data: issueData,
    loading: issueLoading,
  } = useGetFilteredSubjectListByIssue({ id: issueId });

  if (issueLoading) {
    return <CircularLoader />;
  }
  if (issueId && !issueData) {
    return <Typography>ERROR SubjectListByIssue</Typography>;
  }
  return (
    <>
      <IssuesList
        showEmpty={showEmpty}
        issueData={issueData}
        selectedMediaSearchStatuses={selectedMediaSearchStatuses}
        selectedTexts={selectedTexts}
        forPrint={false}
      />
      <PrintPortal>
        <IssuesList
          showEmpty={showEmpty}
          issueData={issueData}
          selectedMediaSearchStatuses={selectedMediaSearchStatuses}
          selectedTexts={selectedTexts}
          forPrint={true}
        />
      </PrintPortal>
    </>
  );
};
