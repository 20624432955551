import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#08877D',
    },
    secondary: {
      main: '#FFB90C',
    },
    background: { default: '#fafafa' },
  },
});
