import { gql } from '@apollo/client';

export const VERSION_FRAGMENT = gql`
  fragment ArticleForm_RawArticleVersionFragment on RawArticleVersion {
    allowedStatuses
    id
    title
    lead
    body
    signature
    leftOver
    status
    createdAt
    editor {
      id
      name
      avatarUrl
    }
    textIsReadOnly
  }
`;
