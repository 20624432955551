import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 'auto',
    padding: '0 5px',
  },
  fullScreen: {
    height: '100vh',
    width: '100vw',
  },
});
