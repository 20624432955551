import FilterIcon from '@mui/icons-material/Filter';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  NotificationTypeEnum,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { FC, useState } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import {
  GetSubjectForMediaStatusDialogQuery_query_subject_Subject_assignments_Assignment,
  MediaSearchStatus,
} from '../../__generated__/queries-topic';
import { CircularLoader, LoaderSizes } from '../../components/Loader/Circular';
import {
  getColorByStatus,
  getLabelByStatus,
} from '../../utils/mediaSearchStatus';
import { Select } from '../FinalFormMaterial/Select';
import { AssignmentItem } from '../ListItem/AssignmentItem';
import { useGetSubjectForMediaStatusDialog } from './getSubject.topic.graphql';
import { useStyles } from './styles';
import { useAssignmentMediaSearchStatusUpdater } from './updateAssignmentMediaSearchStatus.topic.graphql';

interface MediaStatusDialogProps {
  subjectId?: string;
}

export const MediaStatusDialog: FC<MediaStatusDialogProps> = ({
  subjectId,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const { closeDialog } = useDialog();

  const [isUpdatingMediaStatus, setIsUpdatingMediaStatus] = useState(false);
  const {
    data: subjectData = null,
    loading = false,
    error = null,
  } = useGetSubjectForMediaStatusDialog({ id: subjectId || '' });
  const [
    updateAssignmentMediaSearchStatus,
  ] = useAssignmentMediaSearchStatusUpdater();

  return (
    <>
      <DialogTitle>Changer le statut du choix photo</DialogTitle>
      {subjectId &&
        (loading ? (
          <DialogContent>
            <Typography variant="body1">Chargement en cours.</Typography>
          </DialogContent>
        ) : !subjectData || !subjectData.subject || error ? (
          <DialogContent>
            <Typography variant="body1">Une erreur est survenue.</Typography>
          </DialogContent>
        ) : !subjectData.subject.assignments ||
          subjectData.subject.assignments.length === 0 ? (
          <DialogContent>
            <Typography variant="body1">
              Ce sujet est en attente d'affectation.
            </Typography>
          </DialogContent>
        ) : (
          <Form
            initialValues={{
              assignments: subjectData.subject?.assignments,
            }}
            onSubmit={
              (async ({
                assignments,
              }: {
                assignments: GetSubjectForMediaStatusDialogQuery_query_subject_Subject_assignments_Assignment[];
              }) => {
                setIsUpdatingMediaStatus(true);
                try {
                  await Promise.all(
                    assignments
                      .filter(
                        ({ mediaSearchStatus }, index) =>
                          mediaSearchStatus !==
                          subjectData.subject?.assignments[index]
                            .mediaSearchStatus,
                      )
                      .map(({ mediaSearchStatus, id }) =>
                        updateAssignmentMediaSearchStatus({
                          variables: {
                            status: mediaSearchStatus,
                            assignmentId: id,
                          },
                        }),
                      ),
                  );

                  pushNotification({
                    type: NotificationTypeEnum.success,
                    message: 'Changement de statut effectué',
                  });
                } catch (e) {
                  pushNotification({
                    type: NotificationTypeEnum.error,
                    message: 'Erreur lors du changement de statut',
                  });
                }
                setIsUpdatingMediaStatus(false);
                closeDialog();
              }) as any
            }
          >
            {({ form, pristine }: any) => {
              return (
                <>
                  <DialogContent aria-disabled={isUpdatingMediaStatus}>
                    <List disablePadding>
                      {subjectData.subject?.assignments.map(
                        (assignment, index) => (
                          <div
                            className={classes.assignmentItemWrapper}
                            key={assignment.id}
                          >
                            <AssignmentItem
                              key={assignment.id}
                              assignment={assignment}
                            />
                            <Field
                              component={Select as FC<FieldRenderProps>}
                              name={`assignments[${index}].mediaSearchStatus`}
                              classes={{
                                root: classes.select,
                              }}
                            >
                              {Object.values(MediaSearchStatus).map((item) => (
                                <MenuItem key={item} value={item}>
                                  <div className={classes.selectOption}>
                                    <ListItemIcon>
                                      <FilterIcon
                                        style={{
                                          color: getColorByStatus(
                                            MediaSearchStatus[item],
                                          ),
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      inset
                                      primary={getLabelByStatus(
                                        MediaSearchStatus[item],
                                      )}
                                    />
                                  </div>
                                </MenuItem>
                              ))}
                            </Field>
                          </div>
                        ),
                      )}
                    </List>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => closeDialog()} color="primary">
                      Annuler
                    </Button>
                    <Button
                      onClick={form.submit}
                      color="primary"
                      variant="contained"
                      disabled={pristine || isUpdatingMediaStatus}
                    >
                      {isUpdatingMediaStatus ? (
                        <>
                          <CircularLoader
                            size={LoaderSizes.xsmall}
                            color="secondary"
                          />{' '}
                          Mise à jour en cours
                        </>
                      ) : (
                        'Enregistrer'
                      )}
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Form>
        ))}
    </>
  );
};
