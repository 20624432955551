import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(6),
  },
  paperWrapper: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  // @todo: add extend jss plugin
  selectedPaper: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    backgroundColor: theme.palette.primary.light,
  },
  buttonPaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.light,
    color: 'white !important',
  },
  auto: {
    padding: 12,
  },
}));
