import { AutocompleteItem } from '@prismamedia/one-components';
import {
  MetadataNameEnumType,
  SearchPhotosQuery_searchPhotos_Photo,
} from '__generated__/queries-photo';
import { ReactElement } from 'react';
import { FieldType } from './template';

export interface EditInfoProps {
  onChipClick?: (item: AutocompleteItem) => void;
  loading?: boolean;
  className?: string;
  value?: string | string[] | null;
  onChange?: (value: string | string[] | null) => void;
  onValidate?: (value: string | string[] | null) => void;
  title?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  inputRef?: any;
  labelClass?: string;
}

export interface InfosConfigType {
  metadata?: MetadataNameEnumType;
  title: string;
  fieldType: FieldType;
  mapToText?: (
    photo: SearchPhotosQuery_searchPhotos_Photo,
  ) => Promise<string | undefined | null>;
  editRenderer?: (props: EditInfoProps) => ReactElement;
}

export const getSize = (bytes?: number) => {
  if (!bytes) return '-';
  const k = 1024;
  const sizes = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};
