import { gql, useQuery } from '@apollo/client';
import {
  LocationPage_GetPrintHeadingDeleteInformationQuery,
  LocationPage_GetPrintHeadingDeleteInformationQueryVariables,
} from '../../../../__generated__/queries-topic';

const GET_PRINT_HEADING_DELETE_INFORMATION = gql`
  query LocationPage_GetPrintHeadingDeleteInformation($id: ID!) {
    printHeading(where: { id: $id }) {
      title
      printIssue {
        title
        printPublication {
          title
        }
      }
      assignments(first: 100) {
        subject {
          title
        }
      }
    }
  }
`;

export const useGetPrintHeadingDeleteInformation = (
  variables: LocationPage_GetPrintHeadingDeleteInformationQueryVariables,
) =>
  useQuery<
    LocationPage_GetPrintHeadingDeleteInformationQuery,
    LocationPage_GetPrintHeadingDeleteInformationQueryVariables
  >(GET_PRINT_HEADING_DELETE_INFORMATION, { variables, skip: !variables.id });
