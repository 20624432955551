import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  articleAssignments: {
    marginLeft: theme.spacing(5),
    borderLeft: `2px ${theme.palette.grey[300]} solid`,
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(7),
  },
  alignMediaLink: {
    float: 'right',
  },
  articleTitle: {
    fontSize: '1.3em',
  },
  articleLink: {
    marginRight: 10,
  },
  articleTitleAndCounter: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  articleCategory: {
    marginLeft: 'auto',
  },
  noAssignmentListItem: {
    padding: 0,
    height: theme.spacing(5),
  },
  noAssignmentText: {
    color: theme.palette.grey[500],
    fontSize: '1em',
    fontStyle: 'italic',
  },
  moreIcon: {
    padding: theme.spacing(0.5),
  },
  menuItemLink: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
}));
