import { ApolloClient, gql } from '@apollo/client';
import { CREATE_FRAGMENT } from './attachmentFragment.topic.graphql';

const CREATE_ATTACHMENT = gql`
  mutation CreateAttachment($data: AttachmentCreationInput!) {
    createAttachment(data: $data) {
      ...CreateAttachmentFragment
    }
  }
  ${CREATE_FRAGMENT}
`;

export const createAttachment = (
  client: ApolloClient<any>,
  file: File,
  subjectId: string,
) =>
  client
    .mutate({
      mutation: CREATE_ATTACHMENT,
      variables: {
        data: {
          isUploaded: false,
          filename: file.name,
          contentType: file.type,
          subject: { connect: { id: subjectId } },
        },
      },
    })
    .then(({ data, errors }) => {
      if (!data || errors) {
        throw new Error('Echec du téléchargement. Merci de réessayer.');
      }
      return data.createAttachment;
    });
