import {
  AppBar,
  Button,
  IconButton,
  StandardProps,
  Toolbar,
  Typography,
} from '@mui/material';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import classNames from 'classnames';
import React, { FC } from 'react';
import { format } from '../../utils/dateFnsFormat';
import { TopicLink } from '../Link/TopicLink';
import { useStyles } from './styles';

interface AppToolbarProps {
  previousRoute?: { pathname: string };
  title: string;
  assignmentTitle?: string | JSX.Element | undefined;
  childrenJustifyContent?: any;
  subtitle?: string;
  actionButton?: React.ReactElement<typeof Button>;
  color?: string;
  editedAt?: Date;
  childrenClass?: string;
}

export const AppToolbar: FC<
  AppToolbarProps & StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>
> = ({
  previousRoute,
  title,
  children,
  childrenJustifyContent = 'center',
  childrenClass,
  actionButton,
  subtitle,
  className,
  color,
  editedAt,
  assignmentTitle,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: color }}
        color="primary"
        elevation={0}
      >
        <Toolbar className={classes.appBar}>
          {previousRoute && (
            <TopicLink to={previousRoute}>
              <IconButton
                color="inherit"
                aria-label="Menu"
                data-cy="app-toolbar-go-back-arrow"
                size="large"
              >
                <KeyboardBackspace />
              </IconButton>
            </TopicLink>
          )}
          <div className={classes.container}>
            <div className={classes.subjectInfo}>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                {title}
              </Typography>
              {editedAt && (
                <Typography color="inherit" variant="caption">
                  {format(editedAt, 'EEEE d MMMM [à] HH:mm')}
                </Typography>
              )}
              {subtitle && subtitle.length > 0 && (
                <Typography color="inherit" variant="caption">
                  <i>{subtitle}</i>
                </Typography>
              )}
            </div>
            <div className={classes.divider}>
              <div className={classes.assignment}>
                <Typography
                  variant="subtitle1"
                  className={classes.assignmentTitle}
                >
                  {assignmentTitle && assignmentTitle}
                </Typography>
              </div>
            </div>
          </div>
          <div
            className={classNames(classes.children, childrenClass)}
            style={{ justifyContent: childrenJustifyContent }}
          >
            {children}
          </div>
          {actionButton}
        </Toolbar>
      </AppBar>
    </div>
  );
};
