import { gql, useApolloClient, useMutation } from '@apollo/client';
import {
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
} from '__generated__/queries-photo';
import { CATEGORY_FRAGMENT } from 'apollo/fragments/category.photo.graphql';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: CategoryCreateInput!) {
    createCategory(data: $data) {
      ...Category
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useCreateCategory = () => {
  const [createCategory] = useMutation<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >(CREATE_CATEGORY);

  return (variables: CreateCategoryMutationVariables) =>
    createCategory({ variables });
};

export const useUpdateCategoryCache = () => {
  const client = useApolloClient();

  return () =>
    client.refetchQueries({
      updateCache: (cache) =>
        cache.modify({
          fields: {
            categories: (prev, { DELETE }) => DELETE,
          },
        }),
    });
};
