import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ColoredCheckboxButton } from '../../../components/Button/ColoredCheckboxButton';
import {
  getColorByStatus,
  getLabelByStatus,
  getOrderedMediaSearchStatus,
} from '../../../utils/mediaSearchStatus';
import { useStyles } from './styles';

interface MediaSearchStatusFilterProps {
  selectedMediaSearchStatuses: string[];
  onChange: (statuses: string[]) => void;
}

export const MediaSearchStatusFilter: FC<MediaSearchStatusFilterProps> = ({
  selectedMediaSearchStatuses,
  onChange,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setSelected(
      getOrderedMediaSearchStatus().reduce((acc, status) => {
        // eslint-disable-next-line immutable/no-mutation
        acc[status] = selectedMediaSearchStatuses.includes(status);
        return acc;
      }, {} as Record<string, boolean>),
    );
  }, [selectedMediaSearchStatuses, setSelected]);

  const onCheckboxChange = (checked: boolean, value?: string) => {
    if (!value) {
      return;
    }

    // the setState callback doesn't get new state
    const nextSelectedState = {
      ...selected,
      ...{ [value]: !checked },
    };
    setSelected(nextSelectedState);
    onChange(
      Object.entries(nextSelectedState)
        .filter(([, isChecked]) => isChecked)
        .map(([statusName]) => statusName),
    );
  };

  return (
    <div className={classes.wrapper}>
      {getOrderedMediaSearchStatus().map((status) => (
        <ColoredCheckboxButton
          key={status}
          value={status}
          checked={selected[status]}
          onChange={onCheckboxChange}
          backgroundColor={getColorByStatus(status)}
          color="white"
          buttonProps={{ style: { marginTop: 8 } }}
        >
          <Typography color="inherit" variant="body2">
            {getLabelByStatus(status).toUpperCase()}
          </Typography>
        </ColoredCheckboxButton>
      ))}
    </div>
  );
};
