import {
  AppBar as MuiAppBar,
  Avatar,
  Button,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import SubjectIcon from '@mui/icons-material/Subject';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { auth } from '../../utils/auth';
import { SearchInput } from '../Field/SearchInput';
import { useStyles } from './styles';
import { UnitSwitch } from './UnitSwitch';
import { useMenu } from '@prismamedia/one-components';

interface AppBarProps {
  title: JSX.Element | string;
  withSearchBar?: boolean;
  withUnitSelector?: boolean;
  icon?: ReactElement<typeof IconButton>;
  iconTitle?: string;
  onIconClick?: () => void;
  onSearchBarChange?: (value: string) => void;
  onClearSearch?: () => void;
  searchValue?: string;
  actionButtons?: ReactElement<typeof Button>[];
  avatarUrl?: string | null;
  className?: string;
}

export const AppBar: FC<AppBarProps> = ({
  title,
  children,
  iconTitle,
  onIconClick,
  onSearchBarChange = () => undefined,
  onClearSearch = () => undefined,
  searchValue,
  actionButtons,
  avatarUrl,
  withSearchBar = true,
  icon = <SubjectIcon />,
  withUnitSelector = true,
  className,
}) => {
  const classes = useStyles();
  const { openMenu, closeMenu } = useMenu();
  const Icon = <Avatar src={avatarUrl || undefined} />;

  return (
    <MuiAppBar
      position="fixed"
      elevation={0}
      className={classNames(classes.appBar, className)}
    >
      <Toolbar>
        {icon && (
          <IconButton
            onClick={onIconClick}
            title={iconTitle || ''}
            size="large"
          >
            <Avatar className={classes.icon}>{icon}</Avatar>
          </IconButton>
        )}
        {withUnitSelector && <UnitSwitch />}
        <Typography className={classes.title} variant="h6" color="inherit">
          {title}
        </Typography>
        <div className={classes.searchActionBar}>
          {withSearchBar && (
            <SearchInput
              initValue={searchValue || ''}
              onChange={onSearchBarChange}
              onClearSearch={onClearSearch}
            />
          )}
          {actionButtons}
        </div>
        <IconButton
          onClick={(e) =>
            openMenu(
              e.currentTarget,
              <MenuItem
                onClick={() => {
                  auth.logout();
                  closeMenu();
                }}
              >
                Déconnexion
              </MenuItem>,
            )
          }
        >
          {Icon}
        </IconButton>
      </Toolbar>
      {children}
    </MuiAppBar>
  );
};
