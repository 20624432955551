import Button from '@mui/material/Button';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useStyles } from './styles';

interface SaveButtonProps {
  onClick: () => void;
  className?: string;
}

export const SaveButton: FC<SaveButtonProps> = ({ className, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      color="inherit"
      data-cy="save-button"
      onClick={onClick}
      className={classNames(classes.saveButton, className)}
    >
      Sauvegarder
    </Button>
  );
};
