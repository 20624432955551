import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { parse, stringify } from 'query-string';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetUnits } from '../../../apollo/queries/units.topic.graphql';
import { useStyles } from './styles';

export const UnitSwitch: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { data, loading } = useGetUnits();
  const parsedSearch = parse(location.search);
  const currentUnit = parsedSearch.unit || '';

  const switchUnit = (unitId: string) => {
    const newLocation = {
      ...location,
      search: stringify({ ...parsedSearch, unit: unitId }),
    };
    history.push(newLocation);
  };
  if (loading) {
    return <div>LOADING</div>;
  }
  if (!data) {
    return <div>ERROR</div>;
  }

  return (
    <FormControl
      data-cy="unit-switch-select"
      className={classes.form}
      variant="standard"
    >
      <InputLabel className={classes.label} htmlFor="unit">
        Pôle
      </InputLabel>
      <Select
        className={classes.label}
        value={data.units.length === 1 ? data.units[0].id : currentUnit}
        disabled={data.units.length <= 1}
        onChange={(event) => switchUnit(event.target.value as string)}
        inputProps={{
          name: 'unit',
          id: 'unit',
        }}
        variant="standard"
      >
        {data.units.map((unit) => (
          <MenuItem
            data-cy="unit-switch-select-choice"
            key={unit.id}
            value={unit.id}
          >
            {unit.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
