import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  centered: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    height: '100%',
  },
  emptyListWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    width: 400,
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    color: theme.palette.grey[300],
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
  emptyListIcon: {
    fontSize: 120,
  },
  emptyListText: {
    color: theme.palette.grey[500],
  },
  leftDrawer: {
    padding: 0,
  },
}));
