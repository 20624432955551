import { flattenObject } from '../flattenObject/index';

export const getUrlParamByNamespace = (input: {
  [key: string]: string | Record<string, unknown> | boolean;
}) => {
  const customValues = Object.entries(input).reduce(
    (acc, [key, value]) =>
      typeof value === 'object'
        ? {
            ...acc,
            objects: {
              ...acc.objects,
              ...flattenObject(null, { [key]: value }),
            },
          }
        : { ...acc, primitives: { ...acc.primitives, [key]: value } },
    { objects: {}, primitives: {} },
  );

  const mergedParams = {
    ...customValues.primitives,
    ...customValues.objects,
  };
  return Object.keys(mergedParams).filter(
    (key) => mergedParams[key as keyof typeof mergedParams],
  );
};

export const preserveSubjectListSearch = (search: string) => {
  const urlParams = new URLSearchParams(search);
  const unit = urlParams.get('unit');
  const subjectList_type = urlParams.get('SubjectList_type');
  const subjectList_printPublication = urlParams.get(
    'SubjectList_printPublication',
  );
  const subjectList_printIssue = urlParams.get('SubjectList_printIssue');

  const targetUrlParams = new URLSearchParams();
  if (unit) targetUrlParams.set('unit', unit);
  if (subjectList_type)
    targetUrlParams.set('SubjectList_type', subjectList_type);
  if (subjectList_printPublication)
    targetUrlParams.set(
      'SubjectList_printPublication',
      subjectList_printPublication,
    );
  if (subjectList_printIssue)
    targetUrlParams.set('SubjectList_printIssue', subjectList_printIssue);
  return targetUrlParams.toString();
};
