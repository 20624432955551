import { gql } from '@apollo/client';

export const ARCHIVE_FRAGMENT = gql`
  fragment Archive on Archive {
    id
    archiveId
    type
    title
    key
    accesses
    hasCategory
  }
`;
