import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  subjectsList: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
  subjectCategory: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10,
  },
  dialog: {
    transition: 'all 0.25s ease-out',
  },
}));
