import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { FC } from 'react';

export interface ConfirmDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  open: boolean;
  title: string;
  content: string;
  confirmLabel: string;
  cancelLabel: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  content,
  open,
  onConfirm,
  onCancel,
  onClose,
  confirmLabel,
  cancelLabel,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
