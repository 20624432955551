import React, { FC } from 'react';
import { paths } from '../../../routing';
import { TopicLink } from '../TopicLink';
import { useStyles } from './styles';

interface PrintIssueLinkProps {
  printIssueId: string;
  printIssueTitle: string;
  printPublicationId: string;
}

export const PrintIssueLink: FC<PrintIssueLinkProps> = ({
  printIssueId,
  printIssueTitle,
  printPublicationId,
}) => {
  const classes = useStyles();

  return (
    <TopicLink
      to={{
        pathname: paths.SUBJECT_LIST,
        search: `SubjectList_type=issue&SubjectList_printIssue=${printIssueId}&SubjectList_printPublication=${printPublicationId}`,
      }}
      linkClass={classes.printIssueLinkClass}
    >
      {printIssueTitle}
    </TopicLink>
  );
};
