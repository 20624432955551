import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    flexBasis: 'calc(33.33% - 5px)',
  },
  noIssueText: {
    marginRight: 'auto',
    paddingLeft: theme.spacing(1),
  },
}));
