import { brown, cyan, deepPurple, orange } from '@mui/material/colors';
import { MediaSearchStatus } from '../__generated__/queries-topic';

export const getColorByStatus = (status: MediaSearchStatus | null): string => {
  switch (status) {
    case MediaSearchStatus.Started:
      return brown[500];
    case MediaSearchStatus.Ready:
      return deepPurple[500];
    case MediaSearchStatus.Sent:
      return orange[500];
    case MediaSearchStatus.Waiting:
    default:
      return cyan[500];
  }
};

export const getOrderedMediaSearchStatus = () => [
  MediaSearchStatus.Waiting,
  MediaSearchStatus.Started,
  MediaSearchStatus.Ready,
  MediaSearchStatus.Sent,
];

export const getLabelByStatus = (status: MediaSearchStatus | null): string => {
  switch (status) {
    case MediaSearchStatus.Started:
      return 'En cours';
    case MediaSearchStatus.Ready:
      return 'Prête';
    case MediaSearchStatus.Sent:
      return 'Envoyée';
    case MediaSearchStatus.Waiting:
    default:
      return 'En attente';
  }
};
