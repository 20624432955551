import { useApolloClient } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  LoadingButton,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { path } from 'ramda';
import { FC, useCallback, useState } from 'react';
import { LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading } from '../../../../__generated__/queries-topic';
import { useGetSubjectCategory } from '../../../SubjectForm/SubjectCategory/getSubjectCategoryQuery.topic.graphql';
import { useStyles } from './styles';
import { createAutoSubject, mutateHeadingAutomaticSubject } from './utils';

interface DialogSubjectCategoryProps {
  unitId: string;
  title: string;
  subjectId?: string;
  handleClose: () => void;
  printHeading: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading;
  printIssueId: string;
}

interface DialogSubjectCategoryState {
  subjectCategoryId: string;
  subSubjectCategoryId: string;
}

export const DialogSubjectCategory: FC<DialogSubjectCategoryProps> = ({
  unitId,
  handleClose,
  title,
  subjectId,
  printHeading,
  printIssueId,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useNotification();
  const [
    { subjectCategoryId, subSubjectCategoryId },
    setSubjectCategories,
  ] = useState<DialogSubjectCategoryState>({
    subjectCategoryId: '',
    subSubjectCategoryId: '',
  });
  const {
    data: subjectCategoryData,
    loading: subjectCategoryLoading,
    error,
  } = useGetSubjectCategory({ unitId });

  const handleCreateAutoSubjects = useCallback(async () => {
    setIsLoading(true);
    try {
      const [resultSubject, resultHeading] = await Promise.all([
        createAutoSubject(
          client,
          title,
          subjectId,
          unitId,
          printHeading.id,
          subjectCategoryId,
          subSubjectCategoryId,
        ),
        mutateHeadingAutomaticSubject(
          client,
          {
            id: printHeading.id,
            title: printHeading.title,
            order: printHeading.order,
            autoPrototype: printHeading.autoPrototype,
            hasAutomaticSubject: true,
          },
          printIssueId,
        ),
      ]);

      if (
        path(['data', 'upsertSubject', 'title'], resultSubject) &&
        path(['data', 'upsertPrintHeading', 'id'], resultHeading)
      ) {
        pushNotification({
          message: 'Le sujet et la fonction ont été correctement créés',
          type: NotificationTypeEnum.success,
        });
      } else {
        pushNotification({
          message: "Le sujet Auto n'a pas été correctement créé",
          type: NotificationTypeEnum.error,
        });
      }
    } catch (_) {
      pushNotification({
        message: "Une erreur s'est produite",
        type: NotificationTypeEnum.error,
      });
    }
    setIsLoading(false);
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    subjectId,
    printIssueId,
    unitId,
    printHeading,
    subjectCategoryId,
    subSubjectCategoryId,
  ]);

  const mainCategory = subjectCategoryData?.subjectCategories.find(
    (cat) => cat.id === subjectCategoryId,
  );

  return (
    <>
      <DialogTitle>Quelle est la thématique de votre sujet auto :</DialogTitle>
      <DialogContent>
        {subjectCategoryLoading ? (
          <Typography variant="subtitle2">LOADING</Typography>
        ) : !subjectCategoryData || error ? (
          <Typography variant="subtitle2">ERROR</Typography>
        ) : (
          <div className={classes.subjectCategory}>
            <Select
              fullWidth
              value={subjectCategoryId}
              onChange={(e) => {
                setSubjectCategories((prevState) => ({
                  ...prevState,
                  subjectCategoryId: e.target.value as string,
                }));
              }}
              variant="standard"
            >
              {subjectCategoryData.subjectCategories.map((sc) => (
                <MenuItem key={sc.id} value={sc.id}>
                  {sc.title}
                </MenuItem>
              ))}
            </Select>
            <Select
              fullWidth
              disabled={subjectCategoryId === ''}
              value={subSubjectCategoryId}
              onChange={(e) => {
                setSubjectCategories((prevState) => ({
                  ...prevState,
                  subSubjectCategoryId: e.target.value as string,
                }));
              }}
              variant="standard"
            >
              {mainCategory &&
                mainCategory.children.map((child) => (
                  <MenuItem key={child.id} value={child.id}>
                    {child.title}
                  </MenuItem>
                ))}
            </Select>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <LoadingButton
          data-cy="create-unit-save-button"
          color="primary"
          variant="contained"
          loading={isLoading}
          onClick={handleCreateAutoSubjects}
        >
          Confirmer
        </LoadingButton>
      </DialogActions>
    </>
  );
};
