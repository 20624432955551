import classNames from 'classnames';
import { parse, stringify } from 'query-string';
import { FC } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { replaceParams } from '../../../routing';
import { useStyles } from './styles';

export interface TopicLinkProps extends LinkProps {
  params?: { [key: string]: string };
  whiteList?: string[];
  linkClass?: string;
}

const computeQueryString = (queryParams: string, whiteList: string[]) => {
  if (whiteList.length === 0) {
    return queryParams;
  }
  const objectQuery = Object.entries(parse(queryParams)).reduce(
    (acc, [k, v]) => {
      if (whiteList.includes(k)) {
        // eslint-disable-next-line no-param-reassign
        acc = { ...acc, [k]: v };
      }
      return acc;
    },
    {},
  );

  return stringify(objectQuery);
};

export const TopicLink: FC<TopicLinkProps> = ({
  params,
  to,
  whiteList = [],
  linkClass,
  ...rest
}) => {
  const classes = useStyles();
  const { search } = useLocation();
  const queryParams = computeQueryString(search, whiteList);
  const locationSearch = { search: queryParams };
  let locationDescriptor: any;
  // handle to not in paths
  if (typeof to === 'string') {
    if (params && Object.keys(params).length > 0) {
      locationDescriptor = {
        pathname: replaceParams(to, params),
        ...locationSearch,
      };
    } else {
      locationDescriptor = { pathname: to, ...locationSearch };
    }
  } else if ((to as any).pathname) {
    if (params && Object.keys(params).length > 0) {
      locationDescriptor = {
        ...locationSearch,
        ...to,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        pathname: replaceParams((to as any).pathname!, params),
      };
    } else {
      locationDescriptor = { ...locationSearch, ...to };
    }
  }
  if (!locationDescriptor) {
    throw new Error('Bad link construction'); // should break "softly" ?
  }
  return (
    <Link
      className={classNames(classes.link, linkClass)}
      to={locationDescriptor}
      {...rest}
    />
  );
};
