import { gql } from '@apollo/client';

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on Attachment {
    id
    filename
    filesize
    contentType
    createdAt
    createdBy {
      name
    }
  }
`;

export const CREATE_FRAGMENT = gql`
  fragment CreateAttachmentFragment on Attachment {
    id
    uploadUrl
  }
`;

export const DOWNLOAD_FRAGMENT = gql`
  fragment DownloadAttachmentFragment on Attachment {
    downloadUrl
  }
`;
