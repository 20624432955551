import React from 'react';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import { useCommonStyles } from './common.styles';

export const ErrorView: React.FC = ({ children }) => {
  const classes = useCommonStyles();
  return (
    <div className={classes.viewContainer}>
      <div>
        <ErrorIcon className={classes.viewIcon} />
        <Typography
          variant="h6"
          color="textSecondary"
          className={classes.viewMessage}
        >
          {children}
        </Typography>
      </div>
    </div>
  );
};
