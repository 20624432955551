import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { LocationPage_GetSubjectsFromPrintHeadingQuery } from '../../../../__generated__/queries-topic';
import { useStyles } from './styles';

interface DialogSubjectExistProps {
  subjects: LocationPage_GetSubjectsFromPrintHeadingQuery['subjects'];
  handleClose: () => void;
  changeStep: (index: number) => void;
}

export const DialogSubjectExist: FC<DialogSubjectExistProps> = ({
  subjects,
  handleClose,
  changeStep,
}) => {
  const classes = useStyles();
  return (
    <>
      <DialogTitle>
        Attention, cette têtière contient déjà des sujets.
      </DialogTitle>
      <DialogContent className={classes.subjectsList}>
        {subjects.map((subject) => (
          <Typography variant="subtitle2" key={subject.id}>
            - {subject.title}
          </Typography>
        ))}
      </DialogContent>
      <DialogContent>
        <DialogContentText>
          Êtes-vous sûr de vouloir activer la fonctionnalité de création
          automatique de sujet sur cette têtière ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => changeStep(1)}
        >
          Valider
        </Button>
      </DialogActions>
    </>
  );
};
