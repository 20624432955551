import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import { GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate } from '../../../__generated__/queries-topic';
import { useGetPreviewUrls } from '../../../apollo/queries/printTemplate.topic.graphql';
import NotFoundImg from '../../../assets/images/404.png';
import { ErrorView } from '../ErrorView';
import { useStyles } from './styles';

interface PreviewPagesDialogProps {
  open?: boolean;
  printTemplate: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate;
  onClose: () => void;
}

export const PreviewPagesDialog: React.FC<PreviewPagesDialogProps> = ({
  open = false,
  printTemplate,
  onClose,
}) => {
  const classes = useStyles();
  const { data, error } = useGetPreviewUrls(printTemplate.id);

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-preview-printTemplate"
      maxWidth="md"
      className={classes.dialog}
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        {!data || error ? (
          <ErrorView>
            Une erreur s'est produite lors du chargement d'aperçu
          </ErrorView>
        ) : (
          <ImageGallery
            items={data.printTemplatePreviewUrls.map((url) => ({
              original: url,
              originalClass: classes.img,
            }))}
            defaultImage={NotFoundImg}
            lazyLoad={false}
            showBullets={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showIndex={true}
            showThumbnails={false}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
