import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  UpsertRawArticleMutation,
  UpsertRawArticleMutationVariables,
} from '../../../__generated__/queries-topic';
import { VERSION_FRAGMENT } from '../version.fragment.topic.graphql';

const UPSERT_RAW_ARTICLE = gql`
  mutation UpsertRawArticle(
    $create: RawArticleCreationInput!
    $update: RawArticleUpdateInput!
    $where: RawArticleUniqueFilterInput!
  ) {
    upsertRawArticle(create: $create, update: $update, where: $where) {
      id
      versions(first: 1, where: { nextVersion_is_null: true }) {
        ...ArticleForm_RawArticleVersionFragment
      }
      versionList: versions(first: 100, orderBy: _id_DESC) {
        id
        status
        editorId
        editor {
          id
          name
          avatarUrl
        }
      }
    }
  }
  ${VERSION_FRAGMENT}
`;

export const useRawArticleUpsert = (
  options?: MutationHookOptions<
    UpsertRawArticleMutation,
    UpsertRawArticleMutationVariables
  >,
) =>
  useMutation<UpsertRawArticleMutation, UpsertRawArticleMutationVariables>(
    UPSERT_RAW_ARTICLE,
    options,
  );
