import { Button, Typography } from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Lock,
} from '@mui/icons-material';
import React, { FC } from 'react';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import { Avatar } from '../../../components/Avatar';
import {
  getNextStatus,
  getPrevStatus,
  getStatusName,
  isValidStatus,
} from '../../../utils/rawArticleStatus';
import { useStyles } from './styles';

interface WorkflowStepperProps {
  status: RawArticleStatus;
  onStatusClick: () => void;
  avatarUrl?: string | null;
  userName?: string | null;
  updateStatus: (
    nextStatus: RawArticleStatus,
    currentStatus: RawArticleStatus,
  ) => void;
  allowedStatuses: RawArticleStatus[];
}

export const WorkflowStepper: FC<WorkflowStepperProps> = ({
  status,
  onStatusClick,
  updateStatus,
  avatarUrl,
  userName,
  allowedStatuses,
}) => {
  const classes = useStyles();
  const nextStatus = getNextStatus(status);
  const prevStatus = getPrevStatus(status);
  const tooltip = userName ? userName : undefined;
  const avatar = avatarUrl ? avatarUrl : undefined;
  const validNextSatus = isValidStatus(nextStatus, allowedStatuses);
  const validPrevSatus = isValidStatus(prevStatus, allowedStatuses);

  return (
    <div className={classes.wrapper}>
      {validPrevSatus && status !== RawArticleStatus.Mockup && (
        <div className={classes.buttonWrapper}>
          <Button
            size="small"
            variant="text"
            color="inherit"
            className={classes.button}
            aria-label="revenir au status précédent"
            onClick={() => {
              updateStatus(prevStatus as RawArticleStatus, status);
            }}
          >
            <KeyboardArrowLeft />
          </Button>
        </div>
      )}
      <Button
        color="inherit"
        onClick={onStatusClick}
        className={classes.button}
      >
        <Typography className={classes.status} variant="body2" color="inherit">
          {getStatusName(status)}
        </Typography>
        {[
          RawArticleStatus.Editing,
          RawArticleStatus.Validating,
          RawArticleStatus.Correcting,
        ].includes(status) && (
          <Avatar tooltip={tooltip} avatarUrl={avatar} icon={Lock} />
        )}
      </Button>
      {validNextSatus && (
        <div className={classes.buttonWrapper}>
          <Button
            data-cy="assignment-next-status"
            size="small"
            variant="text"
            color="inherit"
            className={classes.button}
            aria-label="aller au status suivant"
            onClick={() => {
              updateStatus(nextStatus as RawArticleStatus, status);
            }}
          >
            <KeyboardArrowRight />
          </Button>
        </div>
      )}
    </div>
  );
};
