import Typography from '@mui/material/Typography';
import React from 'react';
import EmptyImg from '../../assets/svg/undraw_lost_bqr2.svg';
import { useCommonStyles } from './common.styles';

export const EmptyView: React.FC = ({ children }) => {
  const classes = useCommonStyles();
  return (
    <div className={classes.viewContainer}>
      <div>
        <Typography
          variant="h6"
          color="textSecondary"
          className={classes.viewMessage}
        >
          {children}
        </Typography>
        <img src={EmptyImg} className={classes.viewImg} alt="empty" />
      </div>
    </div>
  );
};
