import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { MetadataNameEnumType } from '__generated__/queries-photo';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { FC, useEffect, useMemo, useState } from 'react';
import { extractMetadata } from 'utils/getMetadata';
import { PhotoInfo } from './PhotoInfo';
import { useStyles } from './styles';
import { infosConfig } from './template';

export type FieldsValues = {
  [K in MetadataNameEnumType]?: string | string[];
};

interface PhotoInfosProps {
  photo?: SearchPhotosPhoto;
  editable?: boolean;
}

export const PhotoInfos: FC<PhotoInfosProps> = ({ photo, editable }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const editableFields = useMemo(
    () =>
      editable
        ? infosConfig
            .filter(
              ({ editRenderer, metadata }) => !!editRenderer && !!metadata,
            )
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(({ metadata }) => metadata!)
        : [],
    [editable],
  );
  const [value, setValue] = useState<FieldsValues>({});

  useEffect(() => {
    editableFields &&
      photo &&
      setValue(
        editableFields.reduce(
          (prev, key) => ({
            ...prev,
            [key]: extractMetadata(key, photo),
          }),
          {},
        ),
      );
  }, [photo, editableFields]);

  if (!photo) {
    return <Typography variant="caption">Aucune photo sélectionnée</Typography>;
  }

  return (
    <List subheader={<li />}>
      {infosConfig.map((config, index) => (
        <ListItem classes={{ root: classes.listItem }} key={String(index)}>
          <PhotoInfo
            photo={photo}
            loading={loading}
            setLoading={setLoading}
            value={value}
            setValue={setValue}
            editableFields={editableFields}
            config={config}
          />
        </ListItem>
      ))}
    </List>
  );
};
