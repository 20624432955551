import { IconButton, MenuItem, Paper, Typography } from '@mui/material';
import { Done, ZoomIn } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import React, { FC, useState } from 'react';
import { getCountFromText } from '../../../components/CharCounter/utils';
import { pluralize } from '../../../utils/addPlural';
import { GlobalCharCounter } from '../GlobalCharCounter';
import { useStyles } from './styles';
import { useMenu } from '@prismamedia/one-components';

interface SubjectHeaderProps {
  zoomArticle: (z: number) => void;
  zoomValue: number;
}

const zoomPossibilities: number[] = [50, 75, 90, 100, 125, 150, 200];

const selectedTextCounter = (text: string) => {
  const { wordCount, charCount } = getCountFromText(text);
  return (
    <Typography variant="body1">
      {`Total de la sélection: ${charCount} ${pluralize(
        'Caractère',
        'Caractères',
        charCount,
      )} - ${wordCount} ${pluralize('Mot', 'Mots', wordCount)}`}
    </Typography>
  );
};

export const SubjectHeader: FC<SubjectHeaderProps> = ({
  zoomArticle,
  zoomValue,
}) => {
  const classes = useStyles();
  const { openMenu, closeMenu } = useMenu();
  const [selectedText, setSelectedText] = useState<string | null>('');

  const onSelectText = () => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      setSelectedText(null);
      return;
    }
    setSelectedText(selection.toString());
  };

  React.useEffect(() => {
    const ref = debounce(onSelectText, 100);
    document.addEventListener('selectionchange', ref);
    return () => {
      document.removeEventListener('selectionchange', ref);
    };
  }, []);

  return (
    <Paper square elevation={0} className={classes.paperWrapper}>
      <div
        data-cy="article-form-subject-header"
        className={classes.subjectHeader}
      >
        <div className={classes.assignment}>
          {selectedText ? (
            selectedTextCounter(selectedText)
          ) : (
            <GlobalCharCounter />
          )}
        </div>
      </div>
      <div>
        <IconButton
          onClick={(e) =>
            openMenu(
              e.currentTarget,
              zoomPossibilities.map((possibility) => (
                <MenuItem
                  key={possibility}
                  onClick={() => {
                    zoomArticle(possibility);
                    closeMenu();
                  }}
                >
                  <div className={classes.zoomItem}>
                    <span>
                      {zoomValue * 100 === possibility ? <Done /> : null}
                    </span>
                    <span>{` ${possibility} %`}</span>
                  </div>
                </MenuItem>
              )),
            )
          }
        >
          <ZoomIn />
        </IconButton>
      </div>
    </Paper>
  );
};
