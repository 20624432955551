import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import classNames from 'classnames';
import React, { FC, Fragment, useState } from 'react';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import { Avatar } from '../../../components/Avatar';
import { CircularLoader } from '../../../components/Loader/Circular';
import { format } from '../../../utils/dateFnsFormat';
import {
  getColorByStatus,
  getStatusName,
} from '../../../utils/rawArticleStatus';
import { useGetRawArticleMajorVersions } from '../getRawArticleMajorVersions.topic.graphql';
import { useStyles } from './styles';

interface MajorVersionsDrawerProps {
  idRawArticle: string;
  onChange: (id: string) => void;
}

export const MajorVersionDrawer: FC<MajorVersionsDrawerProps> = ({
  idRawArticle,
  onChange,
}) => {
  const classes = useStyles();
  const [majorVersionOpened, setMajorVersionOpened] = useState(0);
  const [
    minorVersionIdSelected,
    setMinorVersionIdSelected,
  ] = useState<string>();
  const {
    data: versionsData,
    loading: versionsLoading,
  } = useGetRawArticleMajorVersions(
    { id: idRawArticle },
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.rawArticle) {
          onChange(data.rawArticle.majorVersions[0].id);
        }
      },
    },
  );

  const getColorIfIsOpen = (status: RawArticleStatus, index: number) =>
    index === majorVersionOpened ? getColorByStatus(status) : '';
  const openMajorVersion = (index: number, versionId: string): void => {
    setMajorVersionOpened(index);
    setMinorVersionIdSelected(undefined);
    onChange(versionId);
  };

  const onClickMinorVersion = (id: string) => {
    setMinorVersionIdSelected(id);
    onChange(id);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      classes={{ paper: classes.drawerPaper }}
      className={classes.drawer}
    >
      <List
        data-cy="article-history-list"
        component="nav"
        subheader={
          <ListSubheader classes={{ root: classes.toolbarDrawer }}>
            Historique des versions
          </ListSubheader>
        }
      >
        <Divider />
        <div className={classes.majorVersionsPaperWrapper}>
          {versionsLoading ? (
            <CircularLoader />
          ) : !versionsData || !versionsData.rawArticle ? (
            'ERROR'
          ) : (
            versionsData.rawArticle.majorVersions.map(
              (majorVersion, majorVersionIndex) => {
                const isOpen = majorVersionOpened === majorVersionIndex;
                const versionDate = new Date(majorVersion.createdAt);
                return (
                  <Fragment key={majorVersion.id}>
                    <ListItem
                      data-cy={`article-history-list-item-${majorVersionIndex}`}
                      onClick={() =>
                        openMajorVersion(majorVersionIndex, majorVersion.id)
                      }
                      classes={{
                        root: classNames(isOpen && classes.titleVersion),
                      }}
                      button
                      className={classes.majorVersionPaper}
                      style={{
                        backgroundColor: getColorIfIsOpen(
                          majorVersion.status,
                          majorVersionIndex,
                        ),
                      }}
                    >
                      <div
                        className={classes.leftBorderMajorVersionPaper}
                        style={{
                          backgroundColor: getColorByStatus(
                            majorVersion.status,
                          ),
                        }}
                      />
                      <Avatar
                        className={classes.avatarWrapper}
                        tooltip={
                          majorVersion.editor
                            ? majorVersion.editor.name
                            : undefined
                        }
                        avatarUrl={
                          majorVersion.editor && majorVersion.editor.avatarUrl
                            ? majorVersion.editor.avatarUrl
                            : undefined
                        }
                      />
                      <ListItemText
                        data-cy={`article-history-list-item-${majorVersionIndex}-text`}
                        classes={{
                          primary: classNames(isOpen && classes.titleVersion),
                          secondary: classNames(isOpen && classes.titleVersion),
                        }}
                        primary={getStatusName(majorVersion.status)}
                        secondary={`${format(
                          versionDate,
                          'EEE d MMM yyyy',
                        )} à ${format(versionDate, 'H')}h${format(
                          versionDate,
                          'mm',
                        )}`}
                        className={classes.titleWrapper}
                      />
                      {majorVersion.previousMinorVersions.length > 0 ? (
                        isOpen ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowRight />
                        )
                      ) : null}
                    </ListItem>
                    <Collapse
                      in={majorVersionOpened === majorVersionIndex}
                      timeout="auto"
                      unmountOnExit
                    >
                      {majorVersion.previousMinorVersions.map(
                        (minorVersion) => {
                          const minorVersionDate = new Date(
                            minorVersion.createdAt,
                          );
                          return (
                            <Fragment key={minorVersion.id}>
                              <ListItem
                                button
                                disabled={
                                  minorVersionIdSelected === minorVersion.id &&
                                  majorVersionOpened === majorVersionIndex
                                }
                                onClick={() => {
                                  onClickMinorVersion(minorVersion.id);
                                }}
                                className={classes.majorVersionPaper}
                              >
                                <Avatar
                                  className={classes.avatarWrapper}
                                  tooltip={
                                    minorVersion.editor
                                      ? minorVersion.editor.name
                                      : undefined
                                  }
                                  avatarUrl={
                                    minorVersion.editor &&
                                    minorVersion.editor.avatarUrl
                                      ? minorVersion.editor.avatarUrl
                                      : undefined
                                  }
                                  avatarClass={classes.avatarWrapperMinor}
                                />
                                <ListItemText
                                  classes={{
                                    primary: classes.textMinorVersion,
                                  }}
                                  className={classes.textMinorVersionMargin}
                                  primary={`${format(
                                    minorVersionDate,
                                    'EEE d MMM',
                                  )} à ${format(versionDate, 'H')}h${format(
                                    minorVersionDate,
                                    'mm',
                                  )}`}
                                />
                              </ListItem>
                              <Divider />
                            </Fragment>
                          );
                        },
                      )}
                    </Collapse>
                    <Divider />
                  </Fragment>
                );
              },
            )
          )}
        </div>
      </List>
    </Drawer>
  );
};
