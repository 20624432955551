import { GetPrintHeadingQuery } from '../../__generated__/queries-topic';
import { paths } from '../../routing';

export type ViewVariant = 'list' | 'grid';

export const ViewVariantStorage = {
  viewStorageKey: 'printTemplate-view',
  get(defaultValue: ViewVariant = 'grid') {
    // eslint-disable-next-line fp/no-this
    return (localStorage.getItem(this.viewStorageKey) ||
      defaultValue) as ViewVariant;
  },
  set(value: ViewVariant) {
    // eslint-disable-next-line fp/no-this
    return localStorage.setItem(this.viewStorageKey, value);
  },
};

export const createHrefFromPrintHeading = (
  printHeading: NonNullable<GetPrintHeadingQuery['printHeading']>,
) => {
  const unitHref = `${paths.LOCATION}?unit=${printHeading.printIssue.printPublication.brand.unit.id}`;
  const printPublicationHref = `${unitHref}&printPublication=${printHeading.printIssue.printPublication.id}`;
  const printIssueHref = `${printPublicationHref}&printIssue=${printHeading.printIssue.id}`;

  return {
    printPublicationHref,
    printIssueHref,
    unitHref,
  };
};
