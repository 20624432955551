import { gql, useQuery } from '@apollo/client';
import {
  GetPrintHeadingByPrintIssueQuery,
  GetPrintHeadingByPrintIssueQueryVariables,
} from '../../__generated__/queries-topic';

const GET_PRINT_HEADING_BY_PRINT_ISSUE = gql`
  query GetPrintHeadingByPrintIssue($id: ID!) {
    printIssue(where: { id: $id }) {
      id
      title
      printHeadings(first: 1000, orderBy: order_ASC) {
        id
        title
        autoPrototype
        printTemplates(first: 1, where: { active: true }) {
          printTemplate {
            id
            type
          }
        }
      }
    }
  }
`;

export const useGetPrintHeadingByPrintIssue = (
  variables: GetPrintHeadingByPrintIssueQueryVariables,
) =>
  useQuery<
    GetPrintHeadingByPrintIssueQuery,
    GetPrintHeadingByPrintIssueQueryVariables
  >(GET_PRINT_HEADING_BY_PRINT_ISSUE, { variables, skip: !variables.id });
