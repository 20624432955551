import { debounce } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useStyles } from './styles';

interface SearchInputProps {
  onChange: (value: string) => void;
  onClearSearch?: () => void;
  defaultValue?: string;
  placeholder?: string;
  initValue: string;
  shadowed?: boolean;
}

export const SearchInput: FC<SearchInputProps> = ({
  onChange,
  shadowed,
  placeholder,
  initValue,
  onClearSearch,
}) => {
  const classes = useStyles();
  const [stateValue, setStateValue] = useState(initValue);
  const deferedOnChange = useCallback(debounce(onChange, 1000), [onChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setStateValue(target.value);
    deferedOnChange(target.value);
  };

  const handleClear = () => {
    setStateValue('');
    onClearSearch?.();
  };

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        disableUnderline: true,
        className: classNames(classes.input, shadowed && classes.shadowedInput),
        classes: {
          input: classes.textFieldInput,
        },
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        ...(onClearSearch && stateValue
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Effacer la recherche"
                    onClick={handleClear}
                    size="large"
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : null),
      }}
      fullWidth
      onChange={handleChange}
      value={stateValue}
      placeholder={placeholder}
      variant="standard"
    />
  );
};
