import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '24px 24px 24px 24px',
    display: 'flex',
    // cyan 50
    backgroundColor: '#F3F6F7',
    flexDirection: 'column',
    position: 'relative',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  deleteButton: {
    backgroundColor: 'white',
    cursor: 'pointer',
    position: 'absolute',
    top: `-${theme.spacing(2)}`,
    right: `-${theme.spacing(2)}`,
  },
  avatarIcon: {
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: 'black',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  charCount: {
    textAlign: 'right',
  },
}));
