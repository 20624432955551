import { useApolloClient } from '@apollo/client';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  NotificationTypeEnum,
  PushNotificationFn,
  RemoveNotificationFn,
} from '@prismamedia/one-components';
import { FC, useState } from 'react';
import Dropzone, { FileWithPreview } from 'react-dropzone';
import { GetSubjectQuery_query_subject_Subject_attachments_Attachment } from '../../../__generated__/queries-topic';
import { AttachmentItem } from '../../../components/ListItem/AttachmentItem';
import {
  ATTACHMENT_EXTENSIONS,
  ATTACHMENT_SIZE_MAX,
  isValidFileTypeAndSize,
  uploadAttachmentFromS3,
} from '../../../utils/attachment';
import { createAttachment } from './createAttachment.topic.graphql';
import { DeleteAttachmentDialog } from './DeleteAttachmentDialog';
import { useStyles } from './styles';
import { updateAttachment } from './updateAttachment.topic.graphql';

interface CustomFile extends File {
  folder: string;
}

interface AttachmentsProps {
  subjectId: string;
  attachmentsList?: GetSubjectQuery_query_subject_Subject_attachments_Attachment[];
  pushNotification: PushNotificationFn;
  removeNotification: RemoveNotificationFn;
}

interface AttachmentsState {
  attachmentsList: GetSubjectQuery_query_subject_Subject_attachments_Attachment[];
  openDialog: boolean;
  deleteAttachmentData?: GetSubjectQuery_query_subject_Subject_attachments_Attachment;
}

export const Attachments: FC<AttachmentsProps> = ({
  subjectId,
  pushNotification,
  removeNotification,
  attachmentsList,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [state, setState] = useState<AttachmentsState>({
    attachmentsList: attachmentsList || [],
    openDialog: false,
    deleteAttachmentData: undefined,
  });

  return (
    <Paper className={classes.box} elevation={0}>
      <Typography variant="h6" color="textSecondary" className={classes.title}>
        <strong>Pièces jointes</strong>
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.subTitle}
      >
        {`* ${ATTACHMENT_EXTENSIONS.join(' ')}  — max ${ATTACHMENT_SIZE_MAX}M`}
      </Typography>

      {subjectId && state.attachmentsList.length > 0 && (
        <>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Propriétaire</TableCell>
                <TableCell>Date d'ajout</TableCell>
                <TableCell>Taille du fichier</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {state.attachmentsList.map((attachment, index) => (
                <AttachmentItem
                  key={index}
                  attachment={attachment}
                  setDeleteAttachmentData={() => {
                    setState((prev) => ({
                      ...prev,
                      deleteAttachmentData: attachment,
                      openDialog: true,
                    }));
                  }}
                  pushNotification={pushNotification}
                  removeNotification={removeNotification}
                />
              ))}
            </TableBody>
          </Table>
          <DeleteAttachmentDialog
            open={state.openDialog}
            attachment={state.deleteAttachmentData}
            handleCloseDialog={() => {
              setState((prev) => ({ ...prev, openDialog: false }));
            }}
            removeAttachment={(
              attachment: GetSubjectQuery_query_subject_Subject_attachments_Attachment,
            ) => {
              setState((prev) => ({
                ...prev,
                attachmentsList: prev.attachmentsList.filter(
                  (
                    attachmentItem: GetSubjectQuery_query_subject_Subject_attachments_Attachment,
                  ) => attachmentItem.id !== attachment.id,
                ),
              }));
            }}
          />
        </>
      )}

      <Dropzone
        className={classes.dropzone}
        style={{ position: 'initial' }}
        activeClassName={classes.dropzoneActive}
        onDrop={
          (async (files: (FileWithPreview & { folder: string })[]) => {
            const notificationKey = pushNotification({
              message: 'Téléchargement en cours, veuillez patienter...',
              type: NotificationTypeEnum.info,
              autoHideDuration: false,
            });

            const uploadAttachments: GetSubjectQuery_query_subject_Subject_attachments_Attachment[] = await Promise.all(
              Array.from(files).map(async (file: CustomFile) => {
                try {
                  if (isValidFileTypeAndSize(file)) {
                    const { uploadUrl } = await createAttachment(
                      client,
                      file,
                      subjectId,
                    );
                    const attachmentId = await uploadAttachmentFromS3(
                      uploadUrl,
                      file,
                    );
                    return await updateAttachment(client, attachmentId);
                  }
                  throw new Error(`Echec de téléchargement. Merci de verifier le type
                    (${ATTACHMENT_EXTENSIONS.join(
                      '',
                    )}) ou la taille (max ${ATTACHMENT_SIZE_MAX}m) du fichier ${
                    file.name
                  }`);
                } catch (error) {
                  const errorMsg = (error as { message: string }).message;
                  console.error('------ logs: -------', { errorMsg });
                  removeNotification(notificationKey);
                  pushNotification({
                    message: errorMsg,
                    type: NotificationTypeEnum.error,
                  });
                }
              }),
            );

            const uploadAttachmentsFilter: GetSubjectQuery_query_subject_Subject_attachments_Attachment[] = uploadAttachments.filter(
              (
                attachment:
                  | GetSubjectQuery_query_subject_Subject_attachments_Attachment
                  | undefined,
              ) => attachment,
            );

            if (uploadAttachmentsFilter.length > 0) {
              setState((prev) => ({
                ...prev,
                attachmentsList: prev.attachmentsList.concat(
                  uploadAttachmentsFilter,
                ),
              }));
              removeNotification(notificationKey);
              pushNotification({
                message: `Téléchargement terminé.`,
                type: NotificationTypeEnum.success,
              });
            }
          }) as any
        }
      >
        <div className={classes.dropzoneNotificationContainer}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.typo}
          >
            Glisser ici ou <span>Ajouter une nouvelle pièce jointe</span>
          </Typography>
        </div>
      </Dropzone>
    </Paper>
  );
};
