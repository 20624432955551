import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  LocationPage_DeletePrintHeadingMutation,
  LocationPage_DeletePrintHeadingMutationVariables,
} from '../../../../__generated__/queries-topic';

const DELETE_PRINT_HEADING = gql`
  mutation LocationPage_DeletePrintHeading($id: ID!) {
    deletePrintHeading(where: { id: $id }) {
      id
    }
  }
`;

export const usePrintHeadingDeleter = (
  options?: MutationHookOptions<
    LocationPage_DeletePrintHeadingMutation,
    LocationPage_DeletePrintHeadingMutationVariables
  >,
) =>
  useMutation<
    LocationPage_DeletePrintHeadingMutation,
    LocationPage_DeletePrintHeadingMutationVariables
  >(DELETE_PRINT_HEADING, options);
