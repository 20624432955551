import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    width: '100%',
  },
  formControl: {
    minWidth: '200px',
    marginRight: theme.spacing(2),
  },
  subheading: {
    marginRight: 20,
  },
  selectRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderColor: theme.palette.grey[200],
    borderRadius: 3,
    boxShadow: `1px 1px 5px ${theme.palette.grey[400]}`,
  },
}));
