import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

interface TabPanelProps {
  active: boolean;
  children: React.ReactNode;
}

export const TabPanel: React.FC<TabPanelProps> = ({ active, children }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.tabPanel, !active && classes.hidden)}>
      {children}
    </div>
  );
};
