import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  LocationPage_UpsertPrintHeadingMutation,
  LocationPage_UpsertPrintHeadingMutationVariables,
} from '../../../../__generated__/queries-topic';
import { PRINT_HEADING_FRAGMENT } from '../printHeadingFragment.topic.graphql';

export const UPSERT_PRINT_HEADING = gql`
  mutation LocationPage_UpsertPrintHeading(
    $create: PrintHeadingCreationInput!
    $update: PrintHeadingUpdateInput!
    $where: PrintHeadingUniqueFilterInput!
  ) {
    upsertPrintHeading(create: $create, update: $update, where: $where) {
      ...PrintHeadingFragment
    }
  }
  ${PRINT_HEADING_FRAGMENT}
`;

export const usePrintHeadingUpsert = (
  options?: MutationHookOptions<
    LocationPage_UpsertPrintHeadingMutation,
    LocationPage_UpsertPrintHeadingMutationVariables
  >,
) =>
  useMutation<
    LocationPage_UpsertPrintHeadingMutation,
    LocationPage_UpsertPrintHeadingMutationVariables
  >(UPSERT_PRINT_HEADING, options);
