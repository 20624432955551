import { List, ListItem, ListItemText, Typography } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion } from '../../../../__generated__/queries-topic';
import { Avatar } from '../../../../components/Avatar';
import { format } from '../../../../utils/dateFnsFormat';
import { getStatusName } from '../../../../utils/rawArticleStatus';
import { useGetRawArticleMajorVersions } from '../../../ArticleHistory/getRawArticleMajorVersions.topic.graphql';
import { useStyles } from './styles';

interface MajorVersionsListProps {
  rawArticleId: string;
  versionSelected: GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion | null;
  onVersionClick: (
    version: GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion,
    index: number,
  ) => void;
}

export const MajorVersionsList: FC<MajorVersionsListProps> = ({
  rawArticleId,
  onVersionClick,
  versionSelected,
}) => {
  const classes = useStyles();
  const {
    data: versionsData,
    loading: versionsLoading,
  } = useGetRawArticleMajorVersions(
    { id: rawArticleId },
    { fetchPolicy: 'network-only' },
  );

  return (
    <div className={classes.container}>
      {versionsLoading ? (
        <Typography variant="subtitle1">CHARGEMENT</Typography>
      ) : !versionsData || !versionsData.rawArticle ? (
        <Typography variant="subtitle1">ERREUR</Typography>
      ) : (
        <List
          disablePadding
          classes={{
            root: classes.rootList,
          }}
        >
          {versionsData.rawArticle.majorVersions.map((majorVersion, index) => {
            const versionDate = new Date(majorVersion.createdAt);
            const versionIdSelected = versionSelected ? versionSelected.id : '';
            return (
              <ListItem
                key={index}
                disableGutters
                className={classNames(classes.listItem, majorVersion.status, {
                  [`${majorVersion.status}Selected`]:
                    majorVersion.id === versionIdSelected,
                })}
                onClick={() => {
                  onVersionClick(majorVersion, index);
                }}
              >
                <Avatar
                  avatarUrl={
                    majorVersion.editor && majorVersion.editor.avatarUrl
                      ? majorVersion.editor.avatarUrl
                      : undefined
                  }
                  avatarClass={classes.avatar}
                />
                <ListItemText
                  primary={getStatusName(majorVersion.status)}
                  secondary={`${format(versionDate, 'EEE d MMM')} à ${format(
                    versionDate,
                    'H',
                  )}h${format(versionDate, 'mm')}`}
                  classes={{
                    root: classes.rootListItem,
                    secondary: classes.secondary,
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </div>
  );
};
