import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  saveButton: {
    backgroundColor: 'rgba(0,0,0,0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
});
