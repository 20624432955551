import { ArrowDownward, ArrowUpward, Check, Upload } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  ListItemIcon,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  ImageGallery,
  NotificationTypeEnum,
  PageWithDrawer,
  SearchParams,
  isDefined,
  notificationQueue,
  useDialog,
  useMenu,
  useNotification,
  useOnMount,
  FileDropZone,
  GalleryItem,
} from '@prismamedia/one-components';
import {
  useCreatePhoto,
  useUpdatePhotoCache,
} from 'apollo/mutations/photos.photo.graphql';
import {
  useGetPhotos,
  useGetPhotosCount,
} from 'apollo/queries/photos.photo.graphql';
import { Head } from 'components/Head';
import { useGetSubject } from 'pages/SubjectForm/getSubjectQuery.topic.graphql';
import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { FileWithPath } from 'file-selector';
import { theme } from 'theme';
import { JsonParam, StringParam, useQueryParam } from 'use-query-params';
import { Metadata } from 'utils/getMetadata';
import { AppBar } from './AppBar';
import { FullscreenGallery } from './FullscreenGallery';
import { GalleryItemInfos } from './GalleryItemInfos';
import { ImportPhotosDialog } from './ImportPhotosDialog';
import { InfoSidebar } from './InfoSidebar';
import { PhotoFilters } from './PhotoFilters';
import { SelectionToolbar } from './SelectionToolbar';
import { useStyles } from './styles';
import { handleDragItem, handleDropEvents } from './dropHandler';
import {
  Filters,
  Sort,
  convertPhotosToGallery,
  getOrderBy,
  getWhere,
  sortMethods,
} from './utils';
import { PhotoOrderByInput } from '__generated__/queries-photo';

export const PAGINATION = 75;

export const SubjectPhotos: FC = () => {
  const classes = useStyles();
  const notification = useNotification();
  const { id: subjectId } = useParams<{ id: string }>();
  const [sort, setSort] = useState<Sort>({
    direction: 'desc',
    method: sortMethods[1],
  });
  const { openMenu, closeMenu } = useMenu();
  const {
    data: subjectData,
    loading: subjectLoading,
    error: subjectError,
  } = useGetSubject({ id: subjectId });
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [filters, setFilters] = useState<Filters>({});
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<string[]>([]);
  const [init, setInit] = useState(true);
  const { openDialog } = useDialog();
  const [searchUrl, setSearchUrl] = useQueryParam('search', StringParam);
  const [filtersUrl, setFiltersUrl] = useQueryParam('filters', JsonParam);
  const filtersDefined = Object.values(filters).filter((val) =>
    Array.isArray(val) ? val?.length : !!val,
  ).length;
  const createPhoto = useCreatePhoto();
  const { pushNotification, removeNotification } = useNotification();
  const updatePhotoCache = useUpdatePhotoCache();
  const subject = subjectData?.subject;
  const [fullScreenGalleryOpen, setFullScreenGalleryOpen] = useState(false);
  const [
    fullScreenGalleryStartIndex,
    setFullScreenGalleryStartIndex,
  ] = useState(0);

  useOnMount(() => {
    searchUrl && setSearchParams({ search: searchUrl });
    filtersUrl && setFilters(filtersUrl || {});
  });

  useEffect(() => {
    setSearchUrl(searchParams.search || undefined, 'replaceIn');
  }, [searchParams, setSearchUrl]);

  useEffect(() => {
    if (filtersDefined) {
      setFiltersUrl(filters, 'replaceIn');
    } else {
      setFiltersUrl(undefined, 'replaceIn');
    }
  }, [filters, setFiltersUrl, filtersDefined]);

  const where = useMemo(() => {
    return getWhere(
      subjectId,
      searchParams,
      filters,
      subject?.unit.photoArchiveId || undefined,
    );
  }, [subjectId, searchParams, filters, subject?.unit.photoArchiveId]);

  const photoVariables = {
    first: PAGINATION,
    where,
    orderBy: [getOrderBy(sort), PhotoOrderByInput._id_DESC],
  };

  const {
    data: photosCountData,
    loading: getCountIsLoading,
  } = useGetPhotosCount({ where }, !subject);

  const { data, loading, error, fetchMore } = useGetPhotos(
    photoVariables,
    !subject,
  );

  useEffect(() => {
    data?.searchPhotos.length && setInit(false);
  }, [data?.searchPhotos.length]);

  useEffect(() => {
    setSelectedPhotoIds([]);
  }, [searchParams, filters]);

  const displayImportTooltip =
    init &&
    !data?.searchPhotos.length &&
    !filtersDefined &&
    !searchParams.search;

  const photoSelection = useMemo(() => {
    return selectedPhotoIds
      .map((selectedId) =>
        data?.searchPhotos.find(({ id }) => id === selectedId),
      )
      .filter(isDefined);
  }, [selectedPhotoIds, data?.searchPhotos]);

  const handleDragItemCallback = useCallback(
    (item?: GalleryItem, e?: React.DragEvent<HTMLDivElement>) => {
      handleDragItem(photoSelection, e);
    },
    [photoSelection],
  );

  const handleDropEventsCallback = useCallback(
    async (event: any) => {
      try {
        return await handleDropEvents(event);
      } catch (err) {
        notification.pushNotification({
          type: NotificationTypeEnum.error,
          message: `erreur survenue lors de l'importation des données, ${err}`,
        });
      }
      return [];
    },
    [notification],
  );

  const importFiles = async (files: FileWithPath[]) => {
    if (!subject) return;
    const queue = Array.from(files).map((file) => {
      let underSubject: string | undefined;
      if (typeof file.path !== 'undefined') {
        underSubject =
          file.path !== file.name ? file.path.split('/')[1] : undefined;
      }

      const result = createPhoto({
        file,
        archiveConnect: { archiveId: subject.unit.photoArchiveId },
        metadatas: [
          {
            metadata: Metadata.Undersubject,
            value: underSubject,
          },
          { metadata: Metadata.ID_subject, value: subject.id },
        ],
      });

      return () => result;
    });

    try {
      await notificationQueue(
        queue,
        pushNotification,
        removeNotification,
        'Importation',
      );
      await updatePhotoCache();
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }
  };

  const onSortClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    openMenu(
      e.currentTarget,
      sortMethods.map((method) => (
        <MenuItem
          key={method.id}
          onClick={() => {
            setSort((prev) => ({ ...prev, method }));
            closeMenu();
          }}
        >
          <ListItemIcon>
            {sort.method.id === method.id ? <Check /> : null}
          </ListItemIcon>
          <Typography variant="inherit">{method.title}</Typography>
        </MenuItem>
      )),
    );
  };

  return (
    <>
      <Head title={subject?.title} />
      <AppBar
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        subject={subject}
      />
      <SelectionToolbar
        subject={subject}
        photos={data?.searchPhotos}
        photoSelection={photoSelection}
        setSelectedPhotoIds={setSelectedPhotoIds}
        openFullscreen={(index) => {
          setFullScreenGalleryOpen(true);
          setFullScreenGalleryStartIndex(index);
        }}
      />
      <PageWithDrawer
        fullWidth
        loading={subjectLoading}
        error={subjectError}
        paddingTop={selectedPhotoIds.length ? 48 : 0}
        leftDrawer={
          !displayImportTooltip && (
            <PhotoFilters
              subject={subject}
              filters={filters}
              setFilters={setFilters}
            />
          )
        }
        rightDrawer={
          !displayImportTooltip && <InfoSidebar photos={photoSelection} />
        }
        className={classes.wrapper}
        classes={{
          leftDrawer: classes.leftDrawer,
        }}
      >
        {subject ? (
          <FileDropZone
            className={classes.wrapper}
            title={`Déposez des fichiers pour les importer directement dans ${subject.title}`}
            onDrop={importFiles}
            dropzoneOptions={{
              getFilesFromEvent: handleDropEventsCallback,
            }}
          >
            {displayImportTooltip && !loading && !error ? (
              <div className={classes.centered}>
                <div
                  className={classes.emptyListWrapper}
                  onClick={() => {
                    openDialog(<ImportPhotosDialog subject={subject} />);
                  }}
                >
                  <Upload className={classes.emptyListIcon} />
                  <Typography className={classes.emptyListText} variant="h6">
                    Déposez des images ici
                  </Typography>
                  <Typography className={classes.emptyListText} variant="body2">
                    Ou utilisez le bouton "Importer"
                  </Typography>
                </div>
              </div>
            ) : (
              <ImageGallery
                list={convertPhotosToGallery(data?.searchPhotos)}
                infoRenderer={(item) => {
                  const photo = data?.searchPhotos.find(
                    ({ id }) => id === item.id,
                  );
                  return <GalleryItemInfos photo={photo} />;
                }}
                itemsPerRow={{ default: 3, max: 6, min: 2 }}
                loading={loading || getCountIsLoading}
                error={error}
                count={photosCountData?.photoCount}
                fetchMore={({ startIndex }) => {
                  return fetchMore({ variables: { skip: startIndex } });
                }}
                selectedItems={selectedPhotoIds}
                setSelectedItems={setSelectedPhotoIds}
                onItemDoubleClick={(item, index) => {
                  setFullScreenGalleryOpen(true);
                  setFullScreenGalleryStartIndex(index);
                }}
                setItemDragging={handleDragItemCallback}
                additionalElement={
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                      mr: 2,
                    }}
                  >
                    <Button
                      size="small"
                      sx={{
                        textTransform: 'none',
                        color: theme.palette.grey[600],
                      }}
                      onClick={onSortClick}
                    >
                      {sort.method.title}
                    </Button>
                    <IconButton
                      size="small"
                      sx={{ color: theme.palette.grey[600] }}
                      onClick={() => {
                        setSort((prev) => ({
                          ...prev,
                          direction: prev.direction === 'desc' ? 'asc' : 'desc',
                        }));
                      }}
                    >
                      {sort.direction === 'desc' ? (
                        <ArrowDownward />
                      ) : (
                        <ArrowUpward />
                      )}
                    </IconButton>
                  </Box>
                }
              />
            )}
          </FileDropZone>
        ) : (
          <div className={classes.centered}>
            <Typography variant="h4" color="primary">
              Ce sujet n'existe pas
            </Typography>
          </div>
        )}
      </PageWithDrawer>
      <Dialog open={fullScreenGalleryOpen} fullScreen>
        <FullscreenGallery
          initFocusIndex={fullScreenGalleryStartIndex}
          selectedPhotoIds={selectedPhotoIds}
          photoVariables={photoVariables}
          setSelectedPhotoIds={setSelectedPhotoIds}
          onClose={() => setFullScreenGalleryOpen(false)}
        />
      </Dialog>
    </>
  );
};
