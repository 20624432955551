import { OneMedia } from '@prismamedia/one-components';
import { ContentState, EditorState } from 'draft-js';
import debounce from 'lodash.debounce';
import { editorStateFromRaw } from 'megadraft';
import { useContext, useEffect, useState } from 'react';
import {
  ArticleAutoContext,
  DraftFormField,
  FormField,
  ImageFormField,
} from '..';
import { PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField } from '../../../__generated__/queries-topic';
import { serializeFormValues } from '../dataTransformer';
import { embedImage } from '../utils/importImage';

export const useAutoFormFieldsGenerator = () => {
  const [result, setResult] = useState<FormField[]>([]);
  const [processAwaiting, setProcessAwaiting] = useState(0);
  const {
    printTemplates,
    articleTemplate,
    selectedPrintTemplateId,
    rawArticleBody,
    setFormValues,
    setFormStructure,
    setLastSavedFormFieldValues,
  } = useContext(ArticleAutoContext);

  const updateFormValues = debounce(setFormValues, 1000);
  const updateFormStructure = debounce(setFormStructure, 1000);

  // awaiting all process to finish to set formValues
  useEffect(() => {
    if (processAwaiting === 0) {
      updateFormValues(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processAwaiting]);

  const matchExistingRawArticleField = (id: number) => {
    return (
      rawArticleBody &&
      rawArticleBody.idPrintTemplate === selectedPrintTemplateId &&
      rawArticleBody?.fields.find((field) => field.id === id)
    );
  };

  const initDraftFormField = (id: number): EditorState => {
    const existingField = matchExistingRawArticleField(id);
    if (existingField) {
      try {
        return editorStateFromRaw(JSON.parse(existingField.value));
      } catch (err) {
        // treat old TEXT values as raw text for EditorState
        return EditorState.createWithContent(
          ContentState.createFromText(existingField.value),
        );
      }
    }
    return EditorState.createWithContent(ContentState.createFromText(''));
  };

  const initImageFormField = async (id: number): Promise<OneMedia[]> => {
    const imageField = matchExistingRawArticleField(id);
    const oneMedias: OneMedia[] = [];

    if (imageField && imageField.value) {
      setProcessAwaiting((process) => process + 1);
      try {
        const imageValue = JSON.parse(imageField.value);
        if (imageValue.media) {
          // eslint-disable-next-line fp/no-mutating-methods
          oneMedias.push(imageValue.media as OneMedia);
        } else if (imageValue.path) {
          //TODO : Extractexisting images url from `path`, should be remove in the futur
          const iframely = await embedImage(imageValue.path);

          if (iframely) {
            // eslint-disable-next-line fp/no-mutating-methods
            oneMedias.push({
              iframely,
            });
          }
        }
      } catch (error) {
        console.warn("Erreur lors du parsing de l'objet image", error);
      } finally {
        setProcessAwaiting((process) => process - 1);
      }
    }
    return oneMedias;
  };

  useEffect(
    () => {
      const printTemplate = printTemplates?.find(
        (item) => item?.id === selectedPrintTemplateId,
      );
      if (printTemplate) {
        const fields = (articleTemplate
          ? articleTemplate.fields
          : printTemplate.fields
        ).map(
          (field) =>
            ({
              ...field,
              data: field?.data ? JSON.parse(field.data) : {},
            } as PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField & {
              data: { ratio: string; maxChar: number };
            }),
        );

        if (fields && Array.isArray(fields)) {
          const formStructure: FormField[] = [];
          fields.forEach(async (field) => {
            if (field.type === 'GRAPHIC_TYPE') {
              // eslint-disable-next-line fp/no-mutating-methods
              formStructure.push({
                id: field.id,
                name: field.name,
                page: field.page.offset,
                type: 'IMAGE',
                value: await initImageFormField(field.id),
                ratio: field.data.ratio,
              } as ImageFormField);
            } else if (field.type === 'TEXT_TYPE') {
              // eslint-disable-next-line fp/no-mutating-methods
              formStructure.push({
                id: field.id,
                name: field.name,
                page: field.page.offset,
                type: 'DRAFT',
                value: initDraftFormField(field.id),
                maxLength: field.data.maxChar,
              } as DraftFormField);
            }
          });
          updateFormStructure(formStructure);
          updateFormValues(formStructure);
          setLastSavedFormFieldValues(
            serializeFormValues(printTemplate.id, formStructure),
          );
          // this value will be used when all processes (api calls/images download) are finished
          setResult(formStructure);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPrintTemplateId],
  );
};
