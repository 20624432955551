import { ApolloQueryResult } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { FC } from 'react';
import {
  GetSubjectsQuery,
  GetSubjectsQuery_query_subjects_Subject,
  GetSubjectsQueryVariables,
} from '../../__generated__/queries-topic';
import { useSubjectDeleter } from './deleteSubject.topic.graphql';

interface SubjectDeleteDialogProps {
  subject: GetSubjectsQuery_query_subjects_Subject;
  closeDialog: () => void;
  refetchSubject?: (
    variables?: GetSubjectsQueryVariables,
  ) => Promise<ApolloQueryResult<GetSubjectsQuery>>;
}

export const SubjectDeleteDialog: FC<SubjectDeleteDialogProps> = ({
  subject,
  closeDialog,
  refetchSubject,
}) => {
  const [deleteSubject] = useSubjectDeleter();
  const { pushNotification } = useNotification();

  const handleSubjectDelete = async (subjectId: string, close: () => void) => {
    const resSubject = await deleteSubject({ variables: { id: subjectId } });

    if (resSubject.data?.deleteSubject.id) {
      pushNotification({
        message: 'Le sujet a correctement été supprimé',
        type: NotificationTypeEnum.success,
      });
      refetchSubject && refetchSubject();
      close();
    } else {
      pushNotification({
        message: 'Un problème est survenu',
        type: NotificationTypeEnum.error,
      });
    }
  };

  return (
    <>
      <DialogTitle>Supprimer le sujet</DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          Etes-vous sûr de vouloir supprimer le sujet{' '}
          <strong>{`${subject.title}`}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Annuler
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleSubjectDelete(subject.id, closeDialog);
          }}
        >
          Valider
        </Button>
      </DialogActions>
    </>
  );
};
