import Assignment from '@mui/icons-material/Assignment';
import { Badge, Grid, IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ArticleAutoContext } from '..';
import { pluralize } from '../../../utils/addPlural';
import { FormInputDraft } from './components/FormInputDraft';

export const useAutoFormComponentsGenerator = () => {
  const {
    selectedPrintTemplateId,
    formStructure,
    rawArticleData,
    setIsLeftOverOpen,
    setSelectedField,
    printTemplates,
  } = useContext(ArticleAutoContext);
  const [components, setComponents] = useState<any>([]);

  useEffect(
    () => {
      const { textIsReadOnly, leftOver } = rawArticleData;
      if (formStructure?.length) {
        const formComponents = formStructure.map((field, index) => {
          switch (field.type) {
            case 'TEXT':
            case 'DRAFT':
              return (
                <Grid container spacing={3} key={index}>
                  <Grid item xs={11}>
                    <FormInputDraft field={field} disabled={textIsReadOnly} />
                  </Grid>
                  <Grid item xs={1}>
                    {leftOver && leftOver.length > 0 && !textIsReadOnly && (
                      <IconButton
                        onClick={() => {
                          setSelectedField(field.id);
                          setIsLeftOverOpen(true);
                        }}
                        disabled={leftOver.length < 1}
                        size="large"
                      >
                        <Tooltip
                          title={`${leftOver.length} ${pluralize(
                            'texte disponible',
                            'textes disponibles',
                            leftOver.length,
                          )}`}
                        >
                          <Badge
                            badgeContent={leftOver.length}
                            color="secondary"
                          >
                            <Assignment
                              color={
                                leftOver.length < 1 ? 'disabled' : 'primary'
                              }
                            />
                          </Badge>
                        </Tooltip>
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            default:
              return <></>;
          }
        });
        setComponents(formComponents);
      }
      if (!formStructure && selectedPrintTemplateId) {
        setComponents('Chargement du formulaire en cours...');
      }

      if (formStructure && formStructure.length === 0) {
        setComponents('Le gabarit sélectionné ne contient aucun champ.');
      }

      if (
        !printTemplates.find(
          (printTemplate) => printTemplate?.id === selectedPrintTemplateId,
        )
      ) {
        setComponents(
          "Le gabarit avec le quel cet article a été édité, n'est plus disponible.",
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formStructure, rawArticleData],
  );
  return components;
};
