import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { EditorState } from 'draft-js';
import { editorStateFromRaw } from 'megadraft';
import React, { FC } from 'react';
import { useStyles } from './styles';

interface LeftOverDrawerProps {
  open: boolean;
  onClose: () => void;
  leftOver: string[];
  handleSelectLeftOver: (value: EditorState) => void;
}

export const LeftOverDrawer: FC<LeftOverDrawerProps> = ({
  open,
  onClose,
  leftOver,
  handleSelectLeftOver,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h3" className={classes.titleWrapper}>
          Textes Disponibles
        </Typography>
        <DialogContent>
          {leftOver.map((item, index) => {
            const editorState = editorStateFromRaw(JSON.parse(item));
            const text = editorState.getCurrentContent().getPlainText();
            return (
              <div key={index}>
                <Divider />
                <ListItem button>
                  <ListItemText
                    onClick={() => handleSelectLeftOver(editorState)}
                    secondary={
                      text.length > 500 ? `${text.substr(0, 500)}...` : text
                    }
                  />
                </ListItem>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};
