import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  counter: {
    display: 'block',
    textAlign: 'right',
    fontSize: '0.9rem',
  },
  charsLeftCounter: {
    display: 'block',
    textAlign: 'right',
    fontSize: '0.7rem',
  },
});
