import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  toolbarContainer: {
    backgroundColor: 'transparent',
    padding: `${theme.spacing(3)} 0 ${theme.spacing(5)} ${theme.spacing(0.5)}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  toolbarActions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
}));
