import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  printPortalWrapper: {
    display: 'none',
    '&:not(:empty)': {
      '& ~ $appContainer': {
        '@media print': {
          display: 'none',
        },
      },
      '@media print': {
        display: 'block',
        breakInside: 'avoid',
      },
    },
  },
  appContainer: {
    '@media print': {
      display: 'none',
    },
  },
});
