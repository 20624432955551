import { IconButton, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import { TooltipProps } from '@mui/material/Tooltip';
import Filter from '@mui/icons-material/Filter';
import React, { FC } from 'react';
import { MediaSearchStatus } from '../../../__generated__/queries-topic';
import { paths } from '../../../routing';
import {
  getColorByStatus,
  getLabelByStatus,
} from '../../../utils/mediaSearchStatus';
import { TopicLink } from '../TopicLink';

interface MediaLinkProps {
  subjectId: string;
  photosCount?: number;
  className?: string;
  mediaSearchStatus?: MediaSearchStatus;
  tooltipProps?: Omit<TooltipProps, 'open'>;
  onClick?: () => void;
}

export const MediaLink: FC<MediaLinkProps> = ({
  subjectId,
  photosCount,
  className,
  mediaSearchStatus,
  tooltipProps,
  onClick,
}) => {
  let tooltipTitle = 'Aller aux médias';

  if (mediaSearchStatus) {
    tooltipTitle += ` (statut : ${getLabelByStatus(mediaSearchStatus)})`;
  }

  const getFilter = (searchStatusText?: MediaSearchStatus) => (
    <Filter
      style={{
        color: searchStatusText ? getColorByStatus(searchStatusText) : 'black',
      }}
    />
  );

  return (
    <TopicLink
      to={paths.SUBJECT_ASSETS}
      params={{ id: subjectId }}
      className={className}
      title=""
    >
      <Tooltip
        {...tooltipProps}
        title={tooltipTitle}
        onClick={() => onClick && onClick()}
      >
        <IconButton aria-label={tooltipTitle} size="large">
          {photosCount !== undefined ? (
            <Badge color="primary" badgeContent={photosCount}>
              {getFilter(mediaSearchStatus)}
            </Badge>
          ) : (
            getFilter(mediaSearchStatus)
          )}
        </IconButton>
      </Tooltip>
    </TopicLink>
  );
};
