import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    minHeight: 250,
  },
  hidden: {
    display: 'none',
  },
  callapseContainer: {
    margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
    width: '-webkit-fill-available',
  },
}));
