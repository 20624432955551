import { alpha } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexShrink: 1,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  secondary: {
    color: alpha(theme.palette.common.black, 0.5),
    fontSize: 10,
    lineHeight: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  badges: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 16,
  },
  badge: {
    fontSize: 22,
    marginLeft: theme.spacing(1),
  },
  resolution: {
    fontSize: 12,
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
    borderRadius: 3,
    padding: theme.spacing(0, 0.5),
    lineHeight: '16px',
    fontWeight: 'bold',
  },
  color: {
    marginRight: 'auto',
    height: 14,
    width: 14,
    borderRadius: 50,
    border: `1px solid ${theme.palette.grey[600]}`,
  },
}));
