import { Box } from '@mui/material';
import { MaterialDraft } from '@prismamedia/one-components';
import { AppToolbar } from 'components/AppToolbar';
import { CircularLoader, LoaderSizes } from 'components/Loader/Circular';
import { useGetRawArticleVersionArticleHistory } from 'pages/ArticleHistory/ArticleWrapper/getRawArticleVersionHistory.topic.graphql';
import { FC, useMemo } from 'react';
import { paths, replaceParams } from 'routing';
import { getColorByStatus } from 'utils/rawArticleStatus';
import { format } from '../../utils/dateFnsFormat';
import { useStyles } from './../ArticleHistory/ArticleWrapper/styles';

interface ArticleWrapperProps {
  idRawArticleVersion: string;
  idRawArticle: string;
}

export const ArticleWrapper: FC<ArticleWrapperProps> = ({
  idRawArticleVersion,
  idRawArticle,
}) => {
  const { data, loading } = useGetRawArticleVersionArticleHistory({
    id: idRawArticleVersion,
  });
  const classes = useStyles();

  let versionDate = null;
  let colorStatus = '';
  if (data?.rawArticleVersion) {
    versionDate = new Date(data.rawArticleVersion.createdAt);
    colorStatus = getColorByStatus(data.rawArticleVersion.status);
  }

  const fields = useMemo(() => {
    let fieldsContent: { id: number; value: any }[] = [];

    try {
      const parsedBody = JSON.parse(data?.rawArticleVersion?.body || '');
      if (Array.isArray(parsedBody['fields'])) {
        fieldsContent = parsedBody['fields'];
      }
    } catch (e) {}

    return data?.rawArticleVersion?.rawArticle.assignment?.printTemplate?.fields.map(
      (field) => ({
        ...field,
        value: fieldsContent.find((content) => content.id === field?.id)?.value,
      }),
    );
  }, [data?.rawArticleVersion]);

  return (
    <>
      <AppToolbar
        className={classes.appToolbar}
        previousRoute={{
          pathname: replaceParams(paths.ARTICLE_AUTO_EDIT, {
            id: idRawArticle,
          }),
        }}
        childrenClass={classes.children}
        color={colorStatus}
        title={
          versionDate !== null
            ? `${format(versionDate, 'EEEE d MMMM')} à ${format(
                versionDate,
                'H',
              )}h${format(versionDate, 'mm')}`
            : ''
        }
      ></AppToolbar>
      <Box
        sx={{
          mt: '64px',
          mr: '339px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Box
            sx={{
              height: 'calc(100vh - 64px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularLoader size={LoaderSizes.big} />
          </Box>
        ) : (
          <Box sx={{ p: 2, maxWidth: 800 }} className={classes.fullWidth}>
            {fields?.map((field) => {
              if (field.type !== 'TEXT_TYPE') {
                return null;
              }

              return (
                <Box key={field.id} sx={{ mb: 4 }}>
                  <MaterialDraft
                    className={classes.fullWidth}
                    label={field.name}
                    disabled
                    value={field.value}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};
