import { Grid, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication } from '../../../__generated__/queries-topic';
import { LocationItem } from '../../../components/ListItem/LocationItem';
import { CircularLoader } from '../../../components/Loader/Circular';
import { useStyles } from '../styles';
import { useGetPrintPublicationsByUnit } from './getPrintPublicationsByUnit.topic.graphql';
import { PrintPublicationDialogForm } from './PrintPublicationDialogForm';

interface PrintPublicationListState {
  printPublicationFormOpen: boolean;
  editedPrintPublication?: LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication;
}

interface PrintPublicationListProps {
  unitId: string;
  printPublicationId: string;
  addFilters: (filters: { [key: string]: string | string[] | null }) => void;
}

export const PrintPublicationList: FC<PrintPublicationListProps> = ({
  unitId,
  printPublicationId,
  addFilters,
}) => {
  const [
    { printPublicationFormOpen, editedPrintPublication },
    setPrintPublicationState,
  ] = useState<PrintPublicationListState>({
    printPublicationFormOpen: false,
    editedPrintPublication: undefined,
  });
  const classes = useStyles();
  const {
    data: printPublicationData,
    loading: printPublicationLoading,
  } = useGetPrintPublicationsByUnit({ unitId });

  const selectPublication = (publicationId: string) => {
    addFilters({ printPublication: publicationId, printIssue: null });
  };

  return (
    <Grid item xs className={classes.column} data-cy="location-grid-unit">
      {printPublicationLoading ? (
        <CircularLoader />
      ) : !printPublicationData || !printPublicationData.unit ? (
        <div className={classes.placeholder}>
          <Typography variant="h5">Choisissez un pôle.</Typography>
        </div>
      ) : (
        <>
          <PrintPublicationDialogForm
            open={printPublicationFormOpen}
            brands={printPublicationData.unit.brands}
            handleClose={() =>
              setPrintPublicationState({
                printPublicationFormOpen: false,
                editedPrintPublication: undefined,
              })
            }
            unitId={unitId}
            printPublication={editedPrintPublication}
          />
          <LocationItem
            sticky
            text="Créer un nouveau support"
            data-cy="location-create-item-publication"
            onClick={() =>
              setPrintPublicationState({
                printPublicationFormOpen: true,
                editedPrintPublication: undefined,
              })
            }
            header
          />
          {printPublicationData.unit.brands.reduce((acc, brand) => {
            return acc.concat(
              brand.printPublications.map((printPublication) => (
                <LocationItem
                  data-cy="location-item-publication"
                  text={printPublication.title}
                  selected={printPublication.id === printPublicationId}
                  key={printPublication.id}
                  onClick={() => selectPublication(printPublication.id)}
                  menuActions={[
                    {
                      label: 'modifier',
                      onClick: () => {
                        setPrintPublicationState({
                          printPublicationFormOpen: true,
                          editedPrintPublication: printPublication,
                        });
                      },
                    },
                  ]}
                />
              )),
            );
          }, [] as JSX.Element[])}
        </>
      )}
    </Grid>
  );
};
