import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormSpy } from 'react-final-form';
import { pluralize } from '../../../utils/addPlural';
import { getCountFromText } from '../utils';

interface TextCharCounterProps {
  name: string;
}

export const TextCharCounter: FC<TextCharCounterProps> = ({ name }) => (
  <FormSpy subscription={{ values: true }}>
    {({ values }: any) => {
      const text = values[name] || '';
      const { wordCount, charCount } = getCountFromText(text);

      return (
        <Typography variant="caption">
          {`${charCount} ${pluralize(
            'Caractère',
            'Caractères',
            charCount,
          )} - ${wordCount} ${pluralize('Mot', 'Mots', wordCount)}`}
        </Typography>
      );
    }}
  </FormSpy>
);
