import { MajorVersionDrawer } from 'pages/ArticleHistory/MajorVersionsDrawer';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArticleWrapper } from './ArticleWrapper';

export const ArticleAutoHistory: FC = () => {
  const { idRawArticle } = useParams<{ idRawArticle: string }>();
  const [currentVersionId, setCurrentVersionId] = useState('');

  return (
    <>
      <MajorVersionDrawer
        onChange={setCurrentVersionId}
        idRawArticle={idRawArticle}
      />
      <ArticleWrapper
        idRawArticleVersion={currentVersionId || ''}
        idRawArticle={idRawArticle}
      />
    </>
  );
};
