import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  templateList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  addBtn: {
    position: 'fixed',
    bottom: '3%',
    right: '3%',
  },
});
