import { IframelyMedia } from '@prismamedia/one-components';
import { getAxiosClient } from './getAxiosClient';

export const embedImage = async (url: string): Promise<IframelyMedia | any> => {
  const axios = await getAxiosClient();
  if (!axios) {
    return false;
  }
  const result = await axios.get(
    `${config.API_ONE_IFRAMELY}/iframely?uri=${encodeURIComponent(url)}`,
  );

  return result.data;
};
