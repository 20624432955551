import { ContentState, convertToRaw } from 'draft-js';
import { editorStateFromRaw } from 'megadraft';

export const generateTextLeftOver = (
  title: string | null | undefined,
  lead: string | null | undefined,
  body: string | null | undefined,
  signature: string | null | undefined,
  previousLeftOver: string | null | undefined,
) => {
  const resolvedLeftOver = previousLeftOver ? JSON.parse(previousLeftOver) : [];
  let newLines: string[] = [];
  if (title) {
    newLines = [
      JSON.stringify(convertToRaw(ContentState.createFromText(title))),
    ];
  }
  if (lead) {
    try {
      const leadText = editorStateFromRaw(JSON.parse(lead))
        .getCurrentContent()
        .getPlainText();
      if (leadText)
        newLines = [
          ...newLines,
          JSON.stringify(convertToRaw(ContentState.createFromText(leadText))),
        ];
    } catch (error) {}
  }
  if (body) {
    const parsedBody = JSON.parse(body);
    const { fields } = parsedBody;
    if (!fields) {
      try {
        const bodyText = editorStateFromRaw(parsedBody)
          .getCurrentContent()
          .getPlainText();
        if (bodyText)
          newLines = [
            ...newLines,
            JSON.stringify(convertToRaw(ContentState.createFromText(bodyText))),
          ];
      } catch (error) {}
    } else {
      newLines = [
        ...newLines,
        ...fields
          .filter((field: any) => {
            // remove empty drafts
            try {
              const editorState = editorStateFromRaw(JSON.parse(field.value));
              return editorState.getCurrentContent().getPlainText();
            } catch (error) {
              return false;
            }
          })
          .map((field: any) => field?.value),
      ];
    }
  }
  if (signature) {
    newLines = [
      ...newLines,
      JSON.stringify(convertToRaw(ContentState.createFromText(signature))),
    ];
  }
  if (resolvedLeftOver.length === 0) return JSON.stringify(newLines);
  return JSON.stringify(
    Array.from(new Set([...resolvedLeftOver, ...newLines]).values()),
  );
};
