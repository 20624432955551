import { Drawer, Typography } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './styles';

interface WithDrawersProps {
  drawerLeftComponent?: JSX.Element;
  drawerRightComponent?: JSX.Element;
  drawerLeftTitle?: string;
  drawerRightTitle?: string;
  drawerLeftBackgroundColor?: string;
  drawerRightBackgroundColor?: string;
  drawerLeftSize?: '200' | '300' | '400';
  drawerRightSize?: '200' | '300' | '400';
  withGutters?: boolean;
  drawerLeftOpen?: boolean;
  drawerRightOpen?: boolean;
  withArrows?: boolean;
  withClose?: boolean;
  closable?: boolean;
  offsetTop?: string;
  onChange?: (state: WithDrawersState) => void;
  drawerWithGutters?: boolean;
  contentWithGutters?: boolean;
}

interface WithDrawersState {
  drawerLeftOpen: boolean;
  drawerRightOpen: boolean;
}

export const WithDrawers: FC<WithDrawersProps> = ({
  drawerLeftComponent,
  drawerRightComponent,
  drawerLeftTitle,
  drawerRightTitle,
  drawerLeftBackgroundColor = '#FFFFFF',
  drawerRightBackgroundColor = '#FFFFFF',
  drawerLeftSize = '300',
  drawerRightSize = '300',
  withArrows = true,
  closable = true,
  withGutters = true,
  drawerWithGutters = false,
  contentWithGutters = false,
  withClose = true,
  children,
  offsetTop = '64px',
  drawerLeftOpen = true,
  drawerRightOpen = true,
  onChange,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<WithDrawersState>({
    drawerLeftOpen,
    drawerRightOpen,
  });

  useEffect(() => {
    if (drawerLeftOpen !== state.drawerLeftOpen) {
      setState((prev) => ({ ...prev, drawerLeftOpen }));
    }
    if (drawerRightOpen !== state.drawerRightOpen) {
      setState((prev) => ({ ...prev, drawerRightOpen }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerLeftOpen, drawerRightOpen, setState]);

  const toggleDrawerLeft = () => {
    const newState = {
      ...state,
      drawerLeftOpen: !state.drawerLeftOpen,
    };
    setState(newState);
    onChange && onChange(newState);
  };

  const toggleDrawerRight = () => {
    const newState = {
      ...state,
      drawerRightOpen: !state.drawerRightOpen,
    };
    setState(newState);
    onChange && onChange(newState);
  };

  return (
    <div className={classes.root}>
      {drawerLeftComponent && (
        <Drawer
          variant="permanent"
          open={state.drawerLeftOpen}
          className={classes.drawerWrapper}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              classes[`drawerPaper${drawerLeftSize}` as keyof typeof classes],
              !state.drawerLeftOpen && classes.drawerPaperCloseLeft,
            ),
          }}
          style={{
            backgroundColor: drawerLeftBackgroundColor,
            paddingTop: offsetTop,
          }}
        >
          <div
            className={classNames(
              classes.drawerContainer,
              (withGutters || drawerWithGutters) &&
                classes.drawerContainerWithGutters,
            )}
          >
            <Typography
              variant="h5"
              gutterBottom
              classes={{ root: classes.drawerTitle }}
            >
              <span>{drawerLeftTitle}</span>
              {closable && withClose && <Close onClick={toggleDrawerLeft} />}
            </Typography>
            {drawerLeftComponent}
            {closable && withArrows && (
              <ChevronLeft
                className={classes.drawerHandleLeft}
                onClick={toggleDrawerLeft}
              />
            )}
          </div>
        </Drawer>
      )}
      <main
        className={classNames(
          classes.content,
          (withGutters || contentWithGutters) && classes.contentWithGutters,
        )}
        style={{
          marginTop: offsetTop,
          height: `calc(100vh - ${offsetTop})`,
        }}
      >
        {children}
      </main>
      {drawerRightComponent && (
        <Drawer
          variant="permanent"
          open={state.drawerRightOpen}
          className={classes.drawerWrapper}
          anchor={'right'}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              classes[`drawerPaper${drawerRightSize}` as keyof typeof classes],
              !state.drawerRightOpen && classes.drawerPaperCloseRight,
            ),
          }}
          style={{
            backgroundColor: drawerRightBackgroundColor,
            paddingTop: offsetTop,
          }}
        >
          <div
            className={classNames(
              classes.drawerContainer,
              (withGutters || drawerWithGutters) &&
                classes.drawerContainerWithGutters,
            )}
          >
            <Typography
              variant="h5"
              gutterBottom
              classes={{ root: classes.drawerTitle }}
            >
              <span>{drawerRightTitle} </span>
              {closable && withClose && <Close onClick={toggleDrawerRight} />}
            </Typography>
            {drawerRightComponent}
            {closable && withArrows && (
              <ChevronRight
                className={classes.drawerHandleRight}
                onClick={toggleDrawerRight}
              />
            )}
          </div>
        </Drawer>
      )}
    </div>
  );
};
