import { Location } from 'history';
import React, { FC } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { ConfirmDialog, ConfirmDialogProps } from '../Dialog/Confirm';

interface RenderProps {
  isActive: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

interface TopicNavigationPromptProps
  extends Pick<
    ConfirmDialogProps,
    'title' | 'content' | 'confirmLabel' | 'cancelLabel'
  > {
  when:
    | ((currentLocation: Location, nextLocation: Location) => boolean)
    | boolean;
}

export const TopicNavigationPrompt: FC<TopicNavigationPromptProps> = ({
  title,
  content,
  confirmLabel,
  cancelLabel,
  when,
}) => (
  <NavigationPrompt when={when}>
    {({ isActive, onCancel, onConfirm, onClose }: RenderProps) => {
      if (isActive) {
        return (
          <ConfirmDialog
            open={true}
            onClose={onClose}
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={title}
            content={content}
            confirmLabel={confirmLabel}
            cancelLabel={cancelLabel}
          />
        );
      }
      return null;
    }}
  </NavigationPrompt>
);
