import React, { FC } from 'react';
import {
  Button,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useStyles } from './styles';
import { LoadingButton, useDialog } from '@prismamedia/one-components';
import { PhotoItem } from '../PhotoItem';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';

interface PhotoListDialogProps {
  title: string;
  submitText: string;
  photos: Pick<
    SearchPhotosPhoto,
    'metadataByName' | 'updatedAt' | 'previews' | 'downloadUrl'
  >[];
  onSubmit?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const PhotoListDialog: FC<PhotoListDialogProps> = ({
  title,
  submitText,
  photos,
  onSubmit,
  disabled,
  loading,
  children,
}) => {
  const classes = useStyles();
  const { closeDialog } = useDialog();

  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent className={classes.content}>
        {!!photos.length && (
          <Paper className={classes.selectionWrapper} square>
            <div className={classes.gridList}>
              {photos.map((photo, index) => (
                <PhotoItem
                  key={index}
                  photo={photo}
                  className={classes.photoItem}
                />
              ))}
            </div>
          </Paper>
        )}
        {children}
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Annuler
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={disabled}
          loading={loading}
          onClick={onSubmit}
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </>
  );
};
