import { Divider, Paper } from '@mui/material';
import { FC } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import { DraftCharCounter } from '../../../components/CharCounter/Draft';
import { TextCharCounter } from '../../../components/CharCounter/Text';
import { FinalFormMaterialDraft } from '../../../components/FinalFormMaterial/Draft';
import { TextField } from '../../../components/FinalFormMaterial/TextField';
import { paths, replaceParams } from '../../../routing';
import { asideBlockPlugin } from '../../ArticleForm/AsideBlockPlugin';
import {
  bodyDraftConfig,
  leadDraftConfig,
} from '../../ArticleForm/Form/megadraftActionsConfig';
import { useRawArticleUpsert } from '../../ArticleForm/Form/upsertArticleMutation.topic.graphql';
import { GlobalCharCounter } from '../../ArticleForm/GlobalCharCounter';
import { WorkflowConfirmStatusChangeModal } from '../../ArticleForm/WorkflowConfirmStatusChangeModal';
import { formToApi, initialValuesVersionHistory } from './dataTransfomer';
import { useStyles } from './styles';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';

interface ArticleHistoryFormProps {
  idRawArticle: string;
  formValues: initialValuesVersionHistory;
  isConfirmModalOpen: boolean;
  toggleConfirmModal: () => void;
}

export const ArticleHistoryForm: FC<ArticleHistoryFormProps> = ({
  formValues,
  isConfirmModalOpen,
  toggleConfirmModal,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { pushNotification } = useNotification();

  const [upsertRawArticle] = useRawArticleUpsert({
    onError: () => {
      pushNotification({
        message: "Erreur lors de l'enregistrement de l'article",
        type: NotificationTypeEnum.error,
      });
    },
  });

  return (
    <div className={classes.main}>
      <Paper className={classes.paper} elevation={7}>
        <Form
          initialValues={formValues}
          subscription={{}}
          onSubmit={async () => {
            await upsertRawArticle({
              variables: formToApi(formValues),
            });
            history.push({
              pathname: replaceParams(paths.ARTICLE_EDIT, {
                id: formValues.rawArticleId,
              }),
              search: location.search,
            });
          }}
          render={({ form }) => {
            const createNewVersion = () => {
              form.submit();
            };

            return (
              <>
                <form
                  id="article-version-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    form.submit();
                  }}
                >
                  <div className={classes.input}>
                    <Field
                      name="title"
                      label="Titre de l'article"
                      component={TextField}
                      disabled
                      fullWidth
                      multiline
                      InputProps={{
                        style: { fontSize: 24 },
                      }}
                    />
                  </div>
                  <div className={classes.charCounter}>
                    <TextCharCounter name="title" />
                  </div>
                  <div
                    className={`${classes.input} ${classes.megadraftDisabled}`}
                  >
                    <Field
                      component={FinalFormMaterialDraft as FC<FieldRenderProps>}
                      data-cy="article-form-chapo-draft-input"
                      sidebarRendererFn={() => <span />}
                      readOnly
                      placeholder="Contenu du chapô"
                      spellCheck={true}
                      actions={leadDraftConfig}
                      name="lead"
                      label="Contenu du chapô"
                    />
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.charCounter}>
                    <DraftCharCounter name="lead" />
                  </div>
                  <div
                    className={`${classes.input} ${classes.megadraftDisabled}`}
                  >
                    <Field
                      component={FinalFormMaterialDraft as FC<FieldRenderProps>}
                      name="body"
                      readOnly
                      placeholder="Contenu de l'article"
                      label="Contenu de l'article"
                      plugins={[asideBlockPlugin]}
                      actions={bodyDraftConfig}
                      spellCheck={true}
                    />
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.charCounter}>
                    <DraftCharCounter name="body" />
                  </div>
                  <div className={classes.inputSignature}>
                    <Field
                      name="signature"
                      label="Signature"
                      disabled
                      component={TextField}
                      fullWidth
                      multiline
                    />
                  </div>
                  <div className={classes.charCounter}>
                    <GlobalCharCounter />
                  </div>
                </form>
                <WorkflowConfirmStatusChangeModal
                  open={isConfirmModalOpen}
                  dialogContentText="Crée une nouvelle version ?"
                  currentStatus={RawArticleStatus.Editing}
                  toggleConfirmModal={toggleConfirmModal}
                  nextStatus={RawArticleStatus.Editing}
                  createNewVersion={createNewVersion}
                />
              </>
            );
          }}
        />
      </Paper>
    </div>
  );
};
