import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  label: {
    width: '50%',
  },
  inputLabel: {
    textTransform: 'uppercase',
  },
});
