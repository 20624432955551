import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormSpy } from 'react-final-form';
import {
  getCountFromEditorState,
  getCountFromText,
} from '../../../components/CharCounter/utils';
import { pluralize } from '../../../utils/addPlural';

export const GlobalCharCounter: FC = () => (
  <FormSpy subscription={{ values: true }}>
    {({ values }: any) => {
      const leadCount = getCountFromEditorState(values.lead);
      const bodyCount = getCountFromEditorState(values.body);
      const titleCount = getCountFromText(values.title);

      const charCount =
        leadCount.charCount + bodyCount.charCount + titleCount.charCount;
      const wordCount =
        leadCount.wordCount + bodyCount.wordCount + titleCount.wordCount;

      return (
        <Typography variant="body1">
          Total de l'article :{' '}
          {`${charCount} ${pluralize(
            'Caractère',
            'Caractères',
            charCount,
          )} - ${wordCount} ${pluralize('Mot', 'Mots', wordCount)}`}
        </Typography>
      );
    }}
  </FormSpy>
);
