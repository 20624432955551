import { gql, useQuery } from '@apollo/client';
import {
  GetPhotosCountQuery,
  GetPhotosCountQueryVariables,
  MetadataNameEnumType,
  SearchPhotosQuery_searchPhotos_Photo,
  SearchPhotosQueryVariables,
} from '__generated__/queries-photo';
import { PHOTO_FRAGMENT } from '../fragments/photo.photo.graphql';

interface MetadataValueType {
  id: number;
  value: string | null;
}

export const SEARCH_PHOTOS = gql`
  query SearchPhotos(
    $where: PhotoWhereInput
    $first: Int!
    $orderBy: [PhotoOrderByInput!]
    $skip: Int
  ) {
    searchPhotos(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
      ...Photo
    }
  }
  ${PHOTO_FRAGMENT}
`;

export type SearchPhotosPhoto = Omit<
  SearchPhotosQuery_searchPhotos_Photo,
  'metadataByName'
> & { metadataByName: Record<MetadataNameEnumType, MetadataValueType[]> };
interface SearchPhotosResult {
  searchPhotos: SearchPhotosPhoto[];
}

export const useGetPhotos = (
  variables: SearchPhotosQueryVariables,
  skip?: boolean,
) => {
  return useQuery<SearchPhotosResult, SearchPhotosQueryVariables>(
    SEARCH_PHOTOS,
    {
      variables,
      skip,
    },
  );
};

export const GET_PHOTOS_COUNT = gql`
  query GetPhotosCount($where: PhotoWhereInput) {
    photoCount: searchPhotosCount(where: $where)
  }
`;

export const useGetPhotosCount = (
  variables: GetPhotosCountQueryVariables,
  skip?: boolean,
) => {
  return useQuery<GetPhotosCountQuery, GetPhotosCountQueryVariables>(
    GET_PHOTOS_COUNT,
    {
      variables,
      skip,
    },
  );
};
