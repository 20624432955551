import { Avatar, Chip } from '@mui/material';
import { FC } from 'react';
import { useStyles } from './styles';

interface ChipItem {
  id: string;
  label: string;
  avatarUrl?: string | null;
  selected?: boolean;
}

interface ChipListProps {
  items: ChipItem[];
  onClick?: (_: ChipItem) => void;
  onDelete?: (_: ChipItem) => void;
  clickable?: boolean;
  defaultAvatar?: JSX.Element;
}

export const ChipList: FC<ChipListProps> = ({
  items,
  onClick,
  onDelete,
  clickable,
  defaultAvatar,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.chipList}>
      {items.map((item) => {
        const avatar = item.avatarUrl ? (
          <Avatar
            alt={item.label}
            src={item.avatarUrl}
            imgProps={{
              onError: (e) => (e.target as Element).remove(),
            }}
          />
        ) : (
          defaultAvatar
        );
        return (
          <Chip
            color={item.selected ? 'primary' : 'default'}
            key={item.id}
            label={item.label}
            classes={{
              root: classes.chipRoot,
              label: classes.chipLabel,
            }}
            onDelete={onDelete ? () => onDelete(item) : undefined}
            onClick={() => (onClick && onClick(item)) || undefined}
            clickable={clickable}
            avatar={avatar}
          />
        );
      })}
    </div>
  );
};
