import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { FC } from 'react';
import { TopicLink, TopicLinkProps } from '../../Link/TopicLink';
import { useStyles } from './styles';

interface FAButtonProps {
  linkProps: TopicLinkProps;
}

export const FAButton: FC<FAButtonProps> = ({ linkProps }) => {
  const classes = useStyles();

  return (
    <TopicLink {...linkProps}>
      <Fab color="primary" aria-label="add" className={classes.FAButton}>
        <AddIcon />
      </Fab>
    </TopicLink>
  );
};
