import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { UpdateAssignmentByArticleMutation } from '../../../__generated__/queries-topic';

const UPDATE_ASSIGNMENT_BY_RAW_ARTICLE_ID = gql`
  mutation UpdateAssignmentByArticle($id: ID!, $data: AssignmentUpdateInput!) {
    updateAssignment(where: { rawArticle: { id: $id } }, data: $data) {
      id
    }
  }
`;

export const useAssignmentByRawArticleIdUpdater = (
  options?: MutationHookOptions,
) =>
  useMutation<UpdateAssignmentByArticleMutation>(
    UPDATE_ASSIGNMENT_BY_RAW_ARTICLE_ID,
    options,
  );
