import { gql, useQuery } from '@apollo/client';
import {
  GetUserBySearchQuery,
  GetUserBySearchQueryVariables,
} from '../../../__generated__/queries-topic';

const GET_USER_BY_SEARCH = gql`
  query GetUserBySearch($id: ID!, $search: String!) {
    rawArticleVersion(where: { id: $id }) {
      id
      allowedTransfers(name: $search) {
        id
        name
      }
    }
  }
`;

export const useGetUserBySearch = (variables: GetUserBySearchQueryVariables) =>
  useQuery<GetUserBySearchQuery, GetUserBySearchQueryVariables>(
    GET_USER_BY_SEARCH,
    {
      variables,
    },
  );
