import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  SubjectForm_DeleteAssignmentMutation,
  SubjectForm_DeleteAssignmentMutationVariables,
} from '../../../__generated__/queries-topic';

const DELETE_ASSIGNMENT = gql`
  mutation SubjectForm_DeleteAssignment($id: ID!) {
    deleteAssignment(where: { id: $id }) {
      id
    }
  }
`;

export const useAssignmentDeleter = (
  options: MutationHookOptions<
    SubjectForm_DeleteAssignmentMutation,
    SubjectForm_DeleteAssignmentMutationVariables
  >,
) =>
  useMutation<
    SubjectForm_DeleteAssignmentMutation,
    SubjectForm_DeleteAssignmentMutationVariables
  >(DELETE_ASSIGNMENT, options);
