import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormSpy } from 'react-final-form';
import { pluralize } from '../../../utils/addPlural';
import { getCountFromEditorState } from '../utils';

interface DraftCharCounterProps {
  name: string;
}

export const DraftCharCounter: FC<DraftCharCounterProps> = ({ name }) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }: any) => {
        const editorState = values[name];
        const { wordCount, charCount } = getCountFromEditorState(editorState);
        return (
          <Typography variant="caption">
            {`${charCount} ${pluralize(
              'Caractère',
              'Caractères',
              charCount,
            )} - ${wordCount} ${pluralize('Mot', 'Mots', wordCount)}`}
          </Typography>
        );
      }}
    </FormSpy>
  );
};
