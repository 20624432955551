import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  ContentBlock,
  ContentState,
  convertToRaw,
  EditorState,
  genKey,
} from 'draft-js';
import { AnyObject, FormApi } from 'final-form';
import { editorStateFromRaw } from 'megadraft';
import uuid from 'uuid/v4';
import {
  ArticleHistory_MajorVersionFragment,
  CreateRawArticleMutation,
  CreateRawArticleMutationVariables,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';
import { auth } from '../../../utils/auth';
import { generateTextLeftOver } from '../../../utils/rawArticle';
import { AssignmentFormModel } from '../Form/dataTransformer';

export function duplicateArticle(
  subjectId: string,
  // we actually need a single version object who's not defined in queries, so we declare it manually
  version: ArticleHistory_MajorVersionFragment,
  assignment: AssignmentFormModel,
  assignmentOrigin: AssignmentFormModel,
  isUpdate: boolean,
): CreateRawArticleMutationVariables {
  const { title, lead, body, signature } = version;
  let assignmentConf;

  if (!isUpdate) {
    assignmentConf = {
      create: {
        subject: {
          connect: {
            id: subjectId,
          },
        },
      },
    };
    if (
      assignment.supportId &&
      !assignment.printIssueId &&
      !assignment.printHeadingId
    ) {
      const [type, supportId] = assignment.supportId.split(':');
      if (type === 'print') {
        assignmentConf = {
          create: {
            ...assignmentConf.create,
            printPublication: { connect: { id: supportId } },
            printIssue: null,
            printHeading: null,
          },
        };
      }
    }

    if (assignment.printIssueId && !assignment.printHeadingId) {
      assignmentConf = {
        create: {
          ...assignmentConf.create,
          printPublication: null,
          printIssue: { connect: { id: assignment.printIssueId } },
          printHeading: null,
        },
      };
    }

    if (assignment.printTemplateId) {
      assignmentConf = {
        create: {
          ...assignmentConf.create,
          printTemplate: {
            connect: { id: assignment.printTemplateId },
          },
        },
      };
    }

    if (assignment.printHeadingId) {
      assignmentConf = {
        create: {
          ...assignmentConf.create,
          printPublication: null,
          printIssue: null,
          printHeading: { connect: { id: assignment.printHeadingId } },
        },
      };
    }
  }

  const formFields = {
    title,
    lead,
    body,
    signature,
  };

  const newLeftOver = generateTextLeftOver(title, lead, body, signature, null);

  if (assignment.autoPrototype) {
    // eslint-disable-next-line immutable/no-mutation
    formFields.title = '';
    // eslint-disable-next-line immutable/no-mutation
    formFields.lead = JSON.stringify(
      convertToRaw(ContentState.createFromText('')),
    );
    // eslint-disable-next-line immutable/no-mutation
    formFields.body = JSON.stringify({});
    // eslint-disable-next-line immutable/no-mutation
    formFields.signature = '';
  }

  if (assignmentOrigin.autoPrototype && !assignment.autoPrototype) {
    let editorState = EditorState.createEmpty();
    try {
      const leftOverList: string[] = JSON.parse(newLeftOver);
      leftOverList.forEach((item, index) => {
        const text = editorStateFromRaw(JSON.parse(item))
          .getCurrentContent()
          .getPlainText();
        const contentState = editorState.getCurrentContent();
        const blockKey = index
          ? genKey()
          : contentState.getFirstBlock().getKey();
        const newBlockMap = contentState.getBlockMap().set(
          blockKey,
          new ContentBlock({
            key: blockKey,
            type: 'unstyled',
            text,
          }),
        );
        editorState = EditorState.push(
          editorState,
          ContentState.createFromBlockArray(newBlockMap.toArray()),
          'insert-fragment',
        );
      });
    } catch (error) {
      console.error(
        `Failed recovering texts from the automated Article`,
        error,
      );
    }

    // eslint-disable-next-line immutable/no-mutation
    formFields.body = JSON.stringify(
      convertToRaw(editorState.getCurrentContent()),
    );
  }

  const payload = {
    versions: {
      create: [
        {
          ...formFields,
          leftOver: newLeftOver,
          editorId: auth.user?.id,
          status: RawArticleStatus.Editing,
        },
      ],
    },
  };

  return {
    data: { ...payload, ...(assignment ? { assignment: assignmentConf } : {}) },
  };
}

export function createWebDuplicate(
  form: FormApi,
  values: AnyObject,
  versionSelected: ArticleHistory_MajorVersionFragment | null,
  rawArticleId: string,
) {
  const oldAssignments = values.assignments;
  const index = oldAssignments.length;
  // clean assignment
  const { needPortal, ...restAssignment } = oldAssignments[index - 1];

  const newAssignmentId = uuid();
  form.change(`assignments[${index - 1}]`, {
    ...restAssignment,
    isCreate: true,
    id: newAssignmentId,
    versionSelected,
  });
  const value = restAssignment.supportId.split(':');
  if (value[2]) {
    form.submit();
    const [, , brandkey] = restAssignment.supportId.split(':');
    const url = `${config.FRONT_ONE_WEB}#/${brandkey}/article/${rawArticleId}/version/${versionSelected?.id}`;
    window.open(url);
  }
}

const CREATE_RAW_ARTICLE = gql`
  mutation CreateRawArticle($data: RawArticleCreationInput!) {
    createRawArticle(data: $data) {
      id
    }
  }
`;

export const useRawArticleCreator = (
  options: MutationHookOptions<
    CreateRawArticleMutation,
    CreateRawArticleMutationVariables
  >,
) =>
  useMutation<CreateRawArticleMutation, CreateRawArticleMutationVariables>(
    CREATE_RAW_ARTICLE,
    options,
  );
