import { Edit, MoreVert } from '@mui/icons-material';
import { Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import {
  NotificationTypeEnum,
  useDialog,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import React from 'react';
import { TemplateType } from '../../../__generated__/queries-topic';
import { useAssignmentDeleter } from '../../../pages/SubjectForm/Assignments/deleteAssignment.topic.graphql';
import { DuplicateIcon } from '../../../pages/SubjectForm/DuplicateDialog/DuplicateIcon';
import { AssignmentFormModel } from '../../../pages/SubjectForm/Form/dataTransformer';
import { isUserAdmin } from '../../../utils/auth';
import { clipboard } from '../../../utils/clipboard';
import { EmailArticleDialog } from '../../EmailDialog/EmailArticleDialog';
import { ArticleLink } from '../../Link/ArticleLink';
import { useStyles } from './styles';

interface AssignmentItemProps {
  assignment: AssignmentFormModel;
  subjectId: string;
  refetchSubject?: () => void;
  handleDialogAssignment: (e: React.SyntheticEvent) => void;
  handleDuplicateDialog: (
    rawArticleId: string,
  ) => (e: React.SyntheticEvent) => void;
}

export const AssignmentItem: React.FC<AssignmentItemProps> = ({
  assignment,
  refetchSubject,
  subjectId,
  handleDialogAssignment,
  handleDuplicateDialog,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const { openMenu, closeMenu } = useMenu();
  const assignmentId = assignment.id ? assignment.id : '';
  const rawArticleId =
    assignment.rawArticle && assignment.rawArticle.id
      ? assignment.rawArticle.id
      : '';
  const { openDialog, closeDialog } = useDialog();
  const [deleteAssignment] = useAssignmentDeleter({
    onCompleted: () => {
      pushNotification({
        message: 'Assignation supprimée',
        type: NotificationTypeEnum.success,
      });
      if (refetchSubject) {
        refetchSubject();
      }
    },
    onError: () => {
      pushNotification({
        message: 'Erreur lors de la suppression',
        type: NotificationTypeEnum.error,
      });
    },
  });
  const isMultiSubject =
    Boolean(assignment?.printHeading?.autoPrototype) &&
    assignment.printHeading?.printTemplates[0]?.printTemplate.type ===
      TemplateType.MULTIPLE_ARTICLE_TEMPLATE;
  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={4} className={classes.title}>
        {assignment.rawArticle && assignment.rawArticle.id ? (
          <Typography variant="body1">
            {assignment.rawArticle.versions?.[0]?.title}
          </Typography>
        ) : (
          '--------------'
        )}
      </Grid>
      <Grid item xs={4} className={classes.affectation}>
        <Typography variant="body1">
          {assignment.supportId && assignment.supportKey
            ? `WEB - ${assignment.websiteTitle}`
            : `${assignment.supportTitle} •
            ${assignment.printIssueTitle} •
            ${assignment.printHeadingTitle}`}
        </Typography>
        <Tooltip title="Modifier l'affectation">
          <IconButton onClick={handleDialogAssignment} size="large">
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={4} className={classes.buttonWrapper}>
        <IconButton
          data-cy="tooltip-subject-report-problem-icon"
          className={classes.button}
          disabled={!!assignment.articleTemplate && !isMultiSubject}
          size="large"
        >
          <ArticleLink
            subjectId={subjectId}
            assignment={{
              isMultiSubject: !!assignment.articleTemplate || isMultiSubject,
              printTemplateId: assignment.printTemplateId,
              printHeadingId: assignment.printHeadingId,
              autoPrototype: assignment.autoPrototype,
              id: assignment.id,
              rawArticle:
                rawArticleId !== ''
                  ? {
                      id: rawArticleId,
                      versions:
                        (assignment.rawArticle &&
                          assignment.rawArticle.versions) ||
                        [],
                    }
                  : null,
              website:
                assignment.supportId && assignment.supportKey
                  ? {
                      id: assignment.supportId,
                      key: assignment.supportKey,
                    }
                  : null,
            }}
          />
        </IconButton>
        <DuplicateIcon
          refetchSubject={refetchSubject}
          subjectId={subjectId}
          rawArticleId={rawArticleId}
          handleDuplicateDialog={handleDuplicateDialog}
        />
        {isUserAdmin() && (
          <IconButton
            onClick={(e) =>
              openMenu(
                e.currentTarget,
                <div>
                  <MenuItem
                    onClick={() => {
                      clipboard(assignmentId);
                      pushNotification({
                        message: `L'affectation ${assignmentId} à bien été copiée dans le presse-papier`,
                        type: NotificationTypeEnum.success,
                      });
                      closeMenu();
                    }}
                  >
                    {assignmentId}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      deleteAssignment({ variables: { id: assignmentId } });
                      closeMenu();
                    }}
                  >
                    Supprimer l'assignation
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      openDialog(
                        <EmailArticleDialog
                          rawArticleId={rawArticleId}
                          closeDialog={closeDialog}
                        />,
                      );
                      closeMenu();
                    }}
                  >
                    Envoyer le lien par email
                  </MenuItem>
                </div>,
              )
            }
          >
            <MoreVert />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
