import makeStyles from '@mui/styles/makeStyles';

export const useCommonStyles = makeStyles((theme) => ({
  tableInfo: {
    '& td': {
      fontSize: '0.8rem',
      padding: '6px 6px 6px 0',
    },
    '& td:last-child': {
      fontWeight: 300,
      paddingRight: 0,
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
    },
  },
  buttonWithLeftIcon: {
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  viewContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: 'calc(100vh - 235px)',
    '& > *': {
      margin: 'auto',
    },
  },
  viewImg: {
    width: '50%',
    height: 'auto',
  },
  viewMessage: {
    width: '50%',
    margin: 'auto',
    paddingBottom: theme.spacing(2),
  },
  viewIcon: {
    fontSize: '7rem',
  },
}));
