import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'fixed',
    zIndex: theme.zIndex.appBar - 1,
  },
  subjectHeader: {
    width: '50%',
  },
  assignment: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  zoomItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
