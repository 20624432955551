import { MenuItem } from '@mui/material';
import { useMenu } from '@prismamedia/one-components';
import { FC } from 'react';
import { priceLevels } from '../utils';

interface PriceMenuProps {
  onSelect: (value: string | undefined) => void;
}

export const PriceMenu: FC<PriceMenuProps> = ({ onSelect }) => {
  const { closeMenu } = useMenu();

  return (
    <>
      <MenuItem
        onClick={() => {
          onSelect(undefined);
          closeMenu();
        }}
      >
        -
      </MenuItem>
      {[...Object.entries(priceLevels)].map(([key, { label }]) => (
        <MenuItem
          key={key}
          onClick={() => {
            onSelect(key);
            closeMenu();
          }}
        >
          {label}
        </MenuItem>
      ))}
    </>
  );
};
