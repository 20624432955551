import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  EmailSubjectMutation,
  EmailSubjectMutationVariables,
} from '../../__generated__/queries-topic';

const EMAIL_SUBJECT_MUTATION = gql`
  mutation EmailSubject(
    $subject: ID!
    $recipients: [String!]!
    $comment: String
  ) {
    emailSubject(
      subject: { id: $subject }
      recipients: $recipients
      comment: $comment
    ) {
      success
    }
  }
`;

export const useEmailSubjectSender = (
  options?: MutationHookOptions<
    EmailSubjectMutation,
    EmailSubjectMutationVariables
  >,
) =>
  useMutation<EmailSubjectMutation, EmailSubjectMutationVariables>(
    EMAIL_SUBJECT_MUTATION,
    options,
  );
