import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  searchWrapper: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  textFilterWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
