import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    borderRadius: 4,
  },
  textFieldInput: {
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(1),
    lineHeight: theme.typography.body1.lineHeight,
  },
  table: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(1),
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableHeadCell: {
    color: theme.palette.common.black,
  },
  tableCellMain: {
    fontWeight: 'bold',
  },
  tableCellSmall: {
    width: '1%',
  },
  tableWrapper: {},
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      flex: '0 0 auto',
    },
    '& > $tableWrapper': {
      flex: '1',
      overflow: 'auto',
    },
  },
}));
