import { gql, useApolloClient } from '@apollo/client';
import {
  UpdatePrintHeadingMutation,
  UpdatePrintHeadingMutationVariables,
} from '../../../../__generated__/queries-topic';

import { PRINT_HEADING_FRAGMENT } from '../printHeadingFragment.topic.graphql';

const UPDATE_PRINTHEADING_QUERY = gql`
  mutation UpdatePrintHeading(
    $data: PrintHeadingUpdateInput!
    $where: PrintHeadingUniqueFilterInput!
  ) {
    updatePrintHeading(data: $data, where: $where) {
      ...PrintHeadingFragment
    }
  }
  ${PRINT_HEADING_FRAGMENT}
`;

export const useUpdatePrintHeadingAutoProto = (printHeadingId: string) => {
  const client = useApolloClient();
  return (autoPrototype: boolean) => {
    return client.mutate<
      UpdatePrintHeadingMutation,
      UpdatePrintHeadingMutationVariables
    >({
      mutation: UPDATE_PRINTHEADING_QUERY,
      variables: {
        where: {
          id: printHeadingId,
        },
        data: {
          autoPrototype,
        },
      },
    });
  };
};
