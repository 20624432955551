import { gql, useQuery } from '@apollo/client';
import {
  GetAssignmentQuery,
  GetAssignmentQueryVariables,
} from '../../__generated__/queries-topic';
import { useGetter } from '../useGetter';
import { PRINT_TEMPLATE_PRINT_TEMPLATE_FRAGMENT } from './printTemplate.topic.graphql';

const GET_ASSIGNMENT_QUERY = gql`
  query GetAssignment($assignmentId: ID!) {
    assignment(where: { id: $assignmentId }) {
      id
      printTemplate {
        id
      }
      printPublication {
        id
        title
      }
      subject {
        id
        title
        unit {
          id
          title
        }
      }
      printHeading {
        id
        autoPrototype
        printTemplates(first: 50) {
          id
          active
          ...PrintHeadingPrintTemplate
        }
        printIssue {
          id
          title
          printPublication {
            id
            title
          }
        }
      }
    }
  }
  ${PRINT_TEMPLATE_PRINT_TEMPLATE_FRAGMENT}
`;

export const useGetAssignment = (variables: GetAssignmentQueryVariables) =>
  useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(
    GET_ASSIGNMENT_QUERY,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );

export const useAssigmentGetter = () =>
  useGetter<GetAssignmentQuery, GetAssignmentQueryVariables>(
    GET_ASSIGNMENT_QUERY,
  );
