import { gql, useQuery } from '@apollo/client';
import { SubjectListPage_GetPrintPublicationsByUnitQuery } from '../../../../__generated__/queries-topic';

const GET_PRINT_PUBLICATIONS_BY_UNIT = gql`
  query SubjectListPage_GetPrintPublicationsByUnit {
    units(first: 20) {
      id
      title
      brands(first: 20) {
        title
        printPublications(first: 20) {
          id
          title
        }
      }
    }
  }
`;

export const useGetPrintPublicationsByUnits = () =>
  useQuery<SubjectListPage_GetPrintPublicationsByUnitQuery>(
    GET_PRINT_PUBLICATIONS_BY_UNIT,
  );
