import CloudUploadIcon from '@mui/icons-material/CloudUploadTwoTone';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import clx from 'classnames';
import React, { useCallback, useState } from 'react';
import DropZone, { DropFilesEventHandler } from 'react-dropzone';
import uuiv4 from 'uuid/v4';
import {
  useCreatePrintTemplate,
  useGetUploadUrl,
} from '../../../apollo/queries/printTemplate.topic.graphql';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../components/Loader/Circular';
import { CustomDialog } from '../Dialog';
import { InputLabel } from '../InputLabel';
import { FileInfo, SelectedFileInfo } from './SelectedFileInfo';
import { useStyles } from './styles';

interface AddPrintTemplateDialogProps {
  printHeadingId: string;
  onClose: () => void;
  isFirstTemplate: boolean;
}

export const AddPrintTemplateDialog: React.FC<AddPrintTemplateDialogProps> = ({
  printHeadingId,
  onClose,
  isFirstTemplate,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const getUploadUrl = useGetUploadUrl();
  const createPrintTemplate = useCreatePrintTemplate(printHeadingId);
  const [selectedFile, setSelectedFile] = useState<FileInfo | null>(null);
  const [label, setLabel] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onDrop = useCallback<DropFilesEventHandler>((files) => {
    const reader = new FileReader();

    // eslint-disable-next-line immutable/no-mutation
    reader.onerror = () =>
      pushNotification({
        message: 'Erreur est servenue lors de la lecture de fichier.',
        type: NotificationTypeEnum.error,
      });

    // eslint-disable-next-line immutable/no-mutation
    reader.onload = () => {
      setSelectedFile({
        filename: files[0].name,
        filesize: files[0].size,
        content: reader.result,
      });
    };
    reader.readAsArrayBuffer(files[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!selectedFile) {
      return;
    }
    setIsSubmiting(true);

    try {
      const { data, errors } = await getUploadUrl(
        uuiv4(),
        selectedFile.filename,
      );

      if (errors || !data) {
        throw Error();
      }

      const resp = await fetch(data.printTemplateUpload.url, {
        method: 'PUT',
        body: selectedFile.content,
      });

      if (!resp.ok) {
        throw Error();
      }

      const result = await createPrintTemplate(
        {
          id: uuiv4(),
          S3ObjectId: data.printTemplateUpload.id,
          label,
          filename: selectedFile.filename,
          filesize: selectedFile.filesize,
        },
        isFirstTemplate,
      );

      if (result.errors?.length) {
        throw Error();
      }

      pushNotification({
        message: `Le gabarit ${label.toUpperCase()} a été créé avec succès.`,
        type: NotificationTypeEnum.success,
      });

      setLabel('');
      setSelectedFile(null);
      onClose();
    } catch (err) {
      pushNotification({
        message: 'Un problème est survenu lors de la création du gabarit',
        type: NotificationTypeEnum.error,
      });
    }
    setIsSubmiting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, label, isFirstTemplate]);

  return (
    <CustomDialog
      open
      aria-labelledby="dialog-create-printTemplate"
      maxWidth="md"
    >
      <DialogTitle id="dialog-create-printTemplate">
        Nouveau Gabarit
      </DialogTitle>
      <DialogContent>
        <InputLabel value={label} onChange={setLabel} disabled={isSubmiting} />
        {selectedFile ? (
          <SelectedFileInfo
            fileInfo={selectedFile}
            onDelete={() => setSelectedFile(null)}
            disabled={isSubmiting}
          />
        ) : (
          <DropZone
            onDrop={onDrop}
            accept=".indd"
            multiple={false}
            className={clx(classes.dropzoneContainer, classes.dropzone)}
            disabled={isSubmiting}
          >
            <CloudUploadIcon className={classes.bigIcon} />
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              disabled={isSubmiting}
            >
              Sélectionner un fichier
            </Button>
            ou déposer-glisser ici
          </DropZone>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmiting}>
          Annuler
        </Button>
        <Button
          color="primary"
          disabled={!(label && selectedFile && !isSubmiting)}
          onClick={handleSubmit}
        >
          {isSubmiting && <CircularLoader size={LoaderSizes.xsmall} />}
          Valider
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
