import { ApolloClient, gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from './attachmentFragment.topic.graphql';

const UPDATE_ATTACHMENT = gql`
  mutation UpdateAttachment(
    $data: AttachmentUpdateInput!
    $where: AttachmentUniqueFilterInput!
  ) {
    updateAttachment(data: $data, where: $where) {
      ...AttachmentFragment
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const updateAttachment = (
  client: ApolloClient<any>,
  attachmentId: string,
) =>
  client
    .mutate({
      mutation: UPDATE_ATTACHMENT,
      variables: {
        data: { isUploaded: true },
        where: { id: attachmentId },
      },
    })
    .then(({ data }) => {
      if (!data) {
        throw new Error('Echec du téléchargement. Merci de réessayer.');
      }
      return data.updateAttachment;
    });
