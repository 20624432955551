import { Avatar } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useStyles } from './styles';

interface CountAvatarProps {
  count: number;
  withHoverStyle?: boolean;
  onMouseOver?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOut?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const CountAvatar: FC<CountAvatarProps> = ({
  count,
  withHoverStyle = false,
  onMouseOver,
  onMouseOut,
}) => {
  const classes = useStyles();

  return (
    <Avatar
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={classNames(
        classes.avatar,
        count === 0 && classes.disabled,
        withHoverStyle && classes.hoverable,
      )}
    >
      {count.toString()}
    </Avatar>
  );
};
