import { CircularProgress } from '@mui/material';
import { ChipsInput } from '@prismamedia/one-components';
import React, { FC } from 'react';
import { EditInfoProps } from '../utils';
import { useStyles } from './styles';
import clsx from 'clsx';

export const ChipsEdit: FC<EditInfoProps> = ({
  value,
  onChange,
  loading,
  className,
  title,
  onChipClick,
  onValidate,
  variant,
  inputRef,
  labelClass,
}) => {
  const classes = useStyles();

  if (value && !Array.isArray(value)) return null;

  return (
    <div className={clsx(classes.wrapper, className)}>
      <ChipsInput
        variant={variant}
        onChipClick={onChipClick}
        className={classes.input}
        label={title || ''}
        disabled={loading}
        inputRef={inputRef}
        value={
          (value as string[] | undefined | null)?.map((val, i) => ({
            id: i.toString(),
            label: val,
          })) || []
        }
        onChange={(val) => {
          const finalValue = val.map(({ label }) => label as string);
          onChange?.(finalValue);
          onValidate?.(finalValue);
        }}
        TextFieldProps={{ InputLabelProps: { className: labelClass } }}
      />
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
};
