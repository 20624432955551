import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  LoadingButton,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { append, update } from 'ramda';
import { FC, useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import uuid from 'uuid/v4';
import {
  LocationPage_GetPrintHeadingByPrintIssueQuery,
  LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading,
  PrintHeadingCreationInput,
  PrintHeadingUpdateInput,
} from '../../../../__generated__/queries-topic';
import { TextField } from '../../../../components/FinalFormMaterial/TextField';
import { GET_PRINT_HEADING_BY_PRINT_ISSUE } from '../getPrintHeadingsByPrintIssue.topic.graphql';
import { usePrintHeadingUpsert } from './upsertPrintHeading.topic.graphql';
import { mustbeValidName } from './validate';

interface PrintHeadingDialogFormProps {
  open: boolean;
  printHeading?: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading;
  handleClose: () => void;
  printIssueId: string;
  headingsLength: number;
}

interface PrintHeadingFormModel {
  title: string;
}

export const PrintHeadingDialogForm: FC<PrintHeadingDialogFormProps> = ({
  printIssueId,
  open,
  printHeading,
  handleClose,
  headingsLength,
}) => {
  const { pushNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [upsertPrintHeading] = usePrintHeadingUpsert({
    update: (cache, { data }) => {
      if (!data?.upsertPrintHeading.id) {
        return;
      }
      const result = cache.readQuery<LocationPage_GetPrintHeadingByPrintIssueQuery>(
        {
          query: GET_PRINT_HEADING_BY_PRINT_ISSUE,
          variables: { printIssueId },
        },
      );
      if (result && result.printIssue && result.printHeadings) {
        const newPrintHeading = data.upsertPrintHeading;

        let newPrintHeadings = result.printHeadings;
        if (printHeading) {
          const printHeadingIndex = newPrintHeadings.findIndex(
            (ph) => ph.id === printHeading.id,
          );
          newPrintHeadings = update(
            printHeadingIndex,
            newPrintHeading,
            newPrintHeadings,
          );
        } else {
          newPrintHeadings = append(newPrintHeading, newPrintHeadings);
        }
        cache.writeQuery({
          query: GET_PRINT_HEADING_BY_PRINT_ISSUE,
          variables: { printIssueId },
          data: {
            ...result,
            printHeadings: newPrintHeadings,
          },
        });
      }
    },
    onCompleted: (data) => {
      handleClose();
      const printHeadingTitle = data.upsertPrintHeading?.title;
      pushNotification({
        message: `La têtière ${printHeadingTitle} a bien été enregistrée.`,
        type: NotificationTypeEnum.success,
      });
    },
    onError: (error) => {
      console.error(error);
      pushNotification({
        message: "Problème lors de l'enregistrement de la têtière",
        type: NotificationTypeEnum.error,
      });
    },
  });

  const handleSubmitPrintHeading = useCallback(
    async (values: PrintHeadingFormModel) => {
      setIsLoading(true);
      const id = (printHeading && printHeading.id) || uuid();
      const updateData: PrintHeadingUpdateInput = {
        title: values.title,
        order: (printHeading && printHeading.order) || headingsLength,
        printIssue: {
          connect: { id: printIssueId },
        },
        hasAutomaticSubject: printHeading
          ? printHeading.hasAutomaticSubject
          : false,
      };
      const createData: PrintHeadingCreationInput = {
        id,
        ...(updateData as PrintHeadingCreationInput),
      };
      await upsertPrintHeading({
        variables: {
          create: createData,
          update: updateData,
          where: { id },
        },
      });
      setIsLoading(false);
    },
    [printIssueId, printHeading, headingsLength, upsertPrintHeading],
  );

  return (
    <Dialog
      open={open}
      onClose={() => handleClose}
      disableAutoFocus={true}
      aria-labelledby="form-dialog-title"
    >
      <Form
        initialValues={{
          title: printHeading ? printHeading.title : '',
        }}
        validate={
          ((values: PrintHeadingFormModel) => {
            const errors: { title?: string } = {};
            if (!values.title) {
              // eslint-disable-next-line immutable/no-mutation
              errors.title = 'Titre obligatoire';
            } else if (values.title.length > 27) {
              // eslint-disable-next-line immutable/no-mutation
              errors.title = 'Longueur maximale du titre: 27 caractères.';
            }
            return errors;
          }) as any
        }
        onSubmit={handleSubmitPrintHeading as any}
        render={({ form }) => {
          const title = printHeading
            ? 'Éditer la têtière'
            : 'Créer une têtière';
          return (
            <form
              onSubmit={(e) => {
                form.submit();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
              <DialogContent>
                <Field
                  component={TextField}
                  label="Titre"
                  name="title"
                  fullWidth
                  autoFocus
                  validate={mustbeValidName}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <LoadingButton
                  data-cy="create-heading-save-button"
                  loading={isLoading}
                  onClick={form.submit}
                  color="primary"
                  variant="contained"
                >
                  Sauvegarder
                </LoadingButton>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
