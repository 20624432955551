import {
  Avatar as MaterialAvatar,
  StandardProps,
  Tooltip,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useStyles } from './styles';

interface AvatarProps {
  tooltip?: string;
  icon?: typeof SvgIcon;
  avatarUrl?: string;
  avatarClass?: string;
}

export const Avatar: FC<
  AvatarProps & StandardProps<React.HTMLAttributes<HTMLDivElement>, ''>
> = ({ tooltip, avatarUrl, icon: Icon, className, avatarClass }) => {
  const classes = useStyles();

  return (
    <Tooltip
      disableHoverListener={!tooltip}
      className={className}
      title={tooltip || ''}
    >
      <div className={classes.avatarWrapper}>
        <MaterialAvatar
          classes={{ root: classNames(classes.avatar, avatarClass) }}
          src={avatarUrl}
        />
        {Icon && <Icon className={classes.icon} />}
      </div>
    </Tooltip>
  );
};
