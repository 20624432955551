import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 340;

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textAlign: 'start',
  },
  inputSignature: {
    width: 350,
    margin: theme.spacing(3),
    textAlign: 'start',
  },
  main: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .DraftEditor-root': {
      fontSize: 18,
    },
  },
  paper: {
    width: 'calc(100% - 80px)',
    maxWidth: '1200px',
    minHeight: '80vh',
    padding: theme.spacing(6),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  charCounter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
    fontSize: '12px',
  },
  megadraftDisabled: {
    '& .megadraft-editor .DraftEditor-root': {
      color: theme.palette.text.disabled,
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    backgroundColor: theme.palette.grey[500],
  },
  appToolbar: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  majorVersionPaper: {
    height: theme.spacing(8),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  majorVersionsPaperWrapper: {
    paddingTop: theme.spacing(6),
  },
}));
