import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  UpsertSubjectMutation,
  UpsertSubjectMutationVariables,
} from '../../../__generated__/queries-topic';

export const UPSERT_SUBJECT = gql`
  mutation UpsertSubject(
    $create: SubjectCreationInput!
    $update: SubjectUpdateInput!
    $where: SubjectUniqueFilterInput!
  ) {
    upsertSubject(create: $create, update: $update, where: $where) {
      id
      title
      comment
      synopsis
      mediaRequest
    }
  }
`;

export const useSubjectUpsert = (
  options?: MutationHookOptions<
    UpsertSubjectMutation,
    UpsertSubjectMutationVariables
  >,
) =>
  useMutation<UpsertSubjectMutation, UpsertSubjectMutationVariables>(
    UPSERT_SUBJECT,
    options,
  );
