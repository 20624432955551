import {
  AddToPhotos,
  Archive,
  ArrowDropDown,
  Compare,
  Delete,
  Edit,
  EuroSymbol,
  Fullscreen,
  GetApp,
  Hd,
  Palette,
  Print,
} from '@mui/icons-material';
import { Button, IconButton, MenuItem } from '@mui/material';
import {
  AppBarAction,
  ImageCompare,
  ImageSelectionBar,
  NotificationTypeEnum,
  useDialog,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import { Role } from '@prismamedia/one-user';
import { MetadataNameEnumType } from '__generated__/queries-photo';
import { GetSubjectQuery_query_subject_Subject } from '__generated__/queries-topic';
import {
  UpsertPhotoMetadataValue,
  useUpdatePhoto,
} from 'apollo/mutations/photos.photo.graphql';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { auth } from 'utils/auth';
import { Metadata, getMetadata } from 'utils/getMetadata';
import { PreviewSize } from 'utils/getPreview';
import { ArchiveDialog } from '../ArchiveDialog';
import { ColorMenu } from '../ColorMenu';
import { CopyPhotosToSubject } from '../CopyPhotosToSubject';
import { DeleteDialog } from '../DeleteDialog';
import { GalleryItemInfos } from '../GalleryItemInfos';
import { InfoEditDialog } from '../InfoEditDialog';
import { PhotosPrintView } from '../PhotosPrintView';
import { PriceMenu } from '../PriceMenu';
import { PublishPhotoMenu } from '../PublishPhotoMenu';
import {
  AccessName,
  EXPORT_TYPE,
  Resolution,
  convertPhotosToGallery,
  useDownloadPhotos,
} from '../utils';
import { useStyles } from './styles';

interface SelectionToolbarProps {
  subject: GetSubjectQuery_query_subject_Subject | null | undefined;
  photos?: SearchPhotosPhoto[];
  photoSelection: SearchPhotosPhoto[];
  setSelectedPhotoIds: Dispatch<SetStateAction<string[]>>;
  openFullscreen: (focusIndex: number) => void;
}

export const SelectionToolbar: FC<SelectionToolbarProps> = ({
  subject,
  photos,
  photoSelection,
  setSelectedPhotoIds,
  openFullscreen,
}) => {
  const classes = useStyles();
  const { openDialog, closeDialog } = useDialog();
  const { openMenu, closeMenu } = useMenu();
  const { pushNotification } = useNotification();
  const updatePhoto = useUpdatePhoto();
  const downloadPhotos = useDownloadPhotos();
  const [downloading, setDownloading] = useState(false);
  const canSendAndDownload = photoSelection.every(({ archive }) =>
    archive.accesses?.includes(AccessName.SEND_AND_DOWNLOAD),
  );
  const canDelete = photoSelection.every(({ archive }) =>
    archive.accesses?.includes(AccessName.DELETE),
  );
  const canArchive = photoSelection.every(({ archive }) =>
    archive.accesses?.includes(AccessName.ARCHIVE_FROM),
  );

  if (!subject) return null;

  const canDownload =
    canSendAndDownload &&
    (auth.user?.hasRole(Role.Superadmin) ||
      auth.user?.hasRole(Role.Admin) ||
      auth.user?.hasRole(Role.Icono) ||
      photoSelection.every(
        (photo) =>
          getMetadata(Metadata.ExportTypeWeb, photo) === EXPORT_TYPE.WEB,
      ));

  const download = async () => {
    setDownloading(true);
    try {
      await downloadPhotos(photoSelection);
    } catch (e) {
      if (e instanceof Error) {
        pushNotification({
          message: e.message,
          type: NotificationTypeEnum.error,
        });
      }
    }
    setDownloading(false);
  };

  const updateSelection = async ({
    metadatas,
  }: {
    metadatas?: UpsertPhotoMetadataValue[];
  }) => {
    try {
      await Promise.all(
        photoSelection.map((photo) => updatePhoto({ photo, metadatas })),
      );
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }
  };

  const updatePhotoMetadata = async (
    photoId: string,
    metadata: MetadataNameEnumType,
    value: string | undefined,
  ) => {
    const photo = photoSelection.find(({ id }) => id === photoId);
    if (!photo) return;

    try {
      updatePhoto({
        photo,
        metadatas: [{ metadata, value }],
      });
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }
  };

  const actions: (AppBarAction | 'divider')[] = [
    {
      id: 'full-screen',
      label: 'Plein écran',
      icon: <Fullscreen />,
      onClick: () =>
        openFullscreen(
          photos?.findIndex(
            ({ id }) => id === photoSelection[photoSelection.length - 1].id,
          ) || 0,
        ),
    },
    {
      id: 'compare',
      label: 'Comparer',
      icon: <Compare />,
      disabled: photoSelection.length < 2 || photoSelection.length > 6,
      onClick: () =>
        openDialog(
          <ImageCompare
            onClose={closeDialog}
            images={convertPhotosToGallery(photoSelection, PreviewSize.XL)}
            actionsRenderer={(image) => [
              <IconButton
                key="color-select"
                className={classes.actionButton}
                onClick={(e) =>
                  openMenu(
                    e.currentTarget,
                    <ColorMenu
                      onSelect={(value) =>
                        updatePhotoMetadata(image.id, Metadata.Urgency, value)
                      }
                    />,
                  )
                }
              >
                <Palette />
              </IconButton>,
            ]}
          />,
          {
            fullScreen: true,
          },
        ),
    },
    'divider',
    {
      id: 'quality',
      label: 'Qualité',
      icon: <Hd />,
      onClick: (e) =>
        openMenu(
          e.currentTarget,
          ['-', ...Object.values(Resolution)].map((quality, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                updateSelection({
                  metadatas: [
                    {
                      metadata: Metadata.ResolutionKind,
                      value: quality !== '-' ? quality : undefined,
                    },
                  ],
                });
                closeMenu();
              }}
            >
              {quality}
            </MenuItem>
          )),
        ),
    },
    {
      id: 'price',
      label: 'Prix',
      icon: <EuroSymbol />,
      onClick: (e) =>
        openMenu(
          e.currentTarget,
          <PriceMenu
            onSelect={(value) =>
              updateSelection({
                metadatas: [
                  {
                    metadata: Metadata.PriceLevel,
                    value,
                  },
                ],
              })
            }
          />,
        ),
    },
    {
      id: 'color',
      label: 'Couleur',
      icon: <Palette />,
      onClick: (e) =>
        openMenu(
          e.currentTarget,
          <ColorMenu
            onSelect={(value) =>
              updateSelection({
                metadatas: [
                  {
                    metadata: Metadata.Urgency,
                    value,
                  },
                ],
              })
            }
          />,
        ),
    },
    'divider',
    {
      id: 'edit',
      label: 'Modifier les informations',
      icon: <Edit />,
      onClick: () =>
        openDialog(<InfoEditDialog selection={photoSelection} />, {
          maxWidth: 'md',
        }),
    },
    ...(canDelete
      ? [
          {
            id: 'delete',
            label: 'Supprimer',
            icon: <Delete />,
            onClick: () =>
              openDialog(
                <DeleteDialog
                  photos={photoSelection}
                  setSelectedPhotoIds={setSelectedPhotoIds}
                />,
              ),
          },
        ]
      : []),
    'divider',
    {
      id: 'print',
      label: 'Imprimer',
      icon: <Print />,
      onClick: () => window.print(),
    },
    ...(canDownload
      ? [
          {
            id: 'download',
            label: 'Télécharger',
            icon: <GetApp />,
            onClick: download,
            disabled: downloading,
          },
        ]
      : []),
    'divider',
    ...(canArchive
      ? [
          {
            id: 'archive',
            label: 'Archiver',
            icon: <Archive />,
            onClick: () =>
              openDialog(<ArchiveDialog photos={photoSelection} />),
          },
        ]
      : []),
    {
      id: 'copy',
      label: 'Copier vers un autre sujet',
      icon: <AddToPhotos />,
      onClick: () =>
        openDialog(<CopyPhotosToSubject photos={photoSelection} />),
    },
  ];

  return (
    <>
      <ImageSelectionBar
        selection={convertPhotosToGallery(photoSelection)}
        onSelectionClear={() => setSelectedPhotoIds([])}
        onRemoveFromSelection={(index) =>
          setSelectedPhotoIds((prev) => prev.filter((_, i) => i !== index))
        }
        infoRenderer={(photo: SearchPhotosPhoto) => (
          <GalleryItemInfos photo={photo} />
        )}
        actions={actions}
        additionalElement={
          canSendAndDownload ? (
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowDropDown />}
              onClick={(e) =>
                openMenu(
                  e.currentTarget,
                  <PublishPhotoMenu
                    subject={subject}
                    photos={photoSelection}
                  />,
                )
              }
            >
              Envoyer
            </Button>
          ) : undefined
        }
      />
      <PhotosPrintView photos={photoSelection} />
    </>
  );
};
