import { FC } from 'react';
import excelSvg from '../../assets/svg/excel.svg';
import pdfSvg from '../../assets/svg/pdf.svg';
import wordSvg from '../../assets/svg/word.svg';
import { AttachmentContentType } from '../../utils/attachment';
import { useStyles } from './styles';

interface AttachmentIconProps {
  contentType?: string | null;
}

export const AttachmentIcon: FC<AttachmentIconProps> = ({ contentType }) => {
  const classes = useStyles();
  let imageSrc: string | undefined;

  if (AttachmentContentType.pdf === contentType) {
    imageSrc = pdfSvg;
  }
  if (
    AttachmentContentType.word === contentType ||
    AttachmentContentType.wordx === contentType
  ) {
    imageSrc = wordSvg;
  }
  if (
    AttachmentContentType.excel === contentType ||
    AttachmentContentType.excelx === contentType
  ) {
    imageSrc = excelSvg;
  }

  if (!imageSrc) {
    return null;
  }
  return <img src={imageSrc} className={classes.root} alt="document" />;
};
