import { gql, useQuery } from '@apollo/client';
import {
  GetSubjectCategoryQuery,
  GetSubjectCategoryQueryVariables,
} from '../../../__generated__/queries-topic';

const GET_SUBJECT_CATEGORY = gql`
  query GetSubjectCategory($unitId: ID!) {
    subjectCategories(
      where: { parent_is_null: true, unit: { id: $unitId } }
      first: 1000
    ) {
      id
      title
      parent {
        id
      }
      children(first: 1000) {
        id
        title
      }
    }
  }
`;

export const useGetSubjectCategory = (
  variables: GetSubjectCategoryQueryVariables,
) =>
  useQuery<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables>(
    GET_SUBJECT_CATEGORY,
    { variables },
  );
