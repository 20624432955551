import { gql } from '@apollo/client';

export const PRINT_ISSUE_FRAGMENT = gql`
  fragment PrintIssueFragment on PrintIssue {
    id
    title
    printHeadings(first: 1000, orderBy: order_ASC) {
      id
      title
      order
      hasAutomaticSubject
    }
  }
`;
