import { useCallback } from 'react';
import {
  DocumentNode,
  OperationVariables,
  useApolloClient,
} from '@apollo/client';
import { QueryOptions } from '@apollo/client/core/watchQueryOptions';

export const useGetter = <T, Var extends OperationVariables>(
  query: DocumentNode,
) => {
  const client = useApolloClient();

  return useCallback(
    async (
      variables: Var,
      options?: Omit<QueryOptions, 'query' | 'variables'>,
    ) => {
      const { data, errors } = await client.query<T, Var>({
        query,
        variables,
        ...options,
      });
      if (errors?.length) {
        throw errors[0];
      }
      return data;
    },
    [client, query],
  );
};
