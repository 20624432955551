import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    height: 200,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(6),
  },
  content: {
    marginLeft: theme.spacing(6),
    whiteSpace: 'pre-wrap',
  },
  subjectWrapper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'space-between',
  },
}));
