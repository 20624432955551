import { gql, useQuery } from '@apollo/client';
import { UnitSpeedDial_GetUnitsQuery } from '../../../__generated__/queries-topic';

const UNIT_FRAGMENT = gql`
  fragment UnitSpeedDial_Fragment on Unit {
    id
    title
  }
`;

const GET_UNITS = gql`
  query UnitSpeedDial_GetUnits {
    units(first: 10) {
      ...UnitSpeedDial_Fragment
    }
  }
  ${UNIT_FRAGMENT}
`;

export const useGetUnits = () =>
  useQuery<UnitSpeedDial_GetUnitsQuery>(GET_UNITS);
