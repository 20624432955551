import { Divider, Typography } from '@mui/material';
import { EditorState } from 'draft-js';
import React, { FC } from 'react';
import { pluralize } from '../../../utils/addPlural';
import { useStyles } from './styles';
import {
  CountType,
  getCountFromEditorState,
} from '../../../components/CharCounter/utils';

interface CharCounterProps {
  content: EditorState;
  type?: 'Draft';
  maxLength?: number;
}

export const CharCounter: FC<CharCounterProps> = ({
  content,
  type,
  maxLength,
}) => {
  const { charCount, wordCount }: CountType = getCountFromEditorState(content);
  const classes = useStyles();
  const charsLeft =
    maxLength && maxLength !== charCount ? maxLength - charCount : undefined;
  return (
    <>
      {type && <Divider variant="fullWidth" />}
      <Typography
        className={classes.counter}
        variant="caption"
        component="span"
      >
        {`${charCount} ${pluralize('caractère', 'caractères', charCount)} 
          ${wordCount} ${pluralize('mot', 'mots', wordCount)}`}
      </Typography>
      {charsLeft &&
        (charsLeft > 0 ? (
          <Typography
            className={classes.charsLeftCounter}
            variant="caption"
            component="span"
          >
            {`${charsLeft} ${pluralize(
              'caractère',
              'caractères',
              charsLeft,
            )} restant`}
          </Typography>
        ) : (
          <Typography
            className={classes.charsLeftCounter}
            variant="caption"
            component="span"
            color="error"
          >
            {`Dépassement de ${charsLeft * -1} ${pluralize(
              'caractère',
              'caractères',
              charsLeft * -1,
            )}`}
          </Typography>
        ))}
    </>
  );
};
