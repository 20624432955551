import makeStyles from '@mui/styles/makeStyles';

const drawerWidthGlobal = 380;
const drawerWidthIssue = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
  },
  wrapper: {
    paddingTop: '64px',
  },
  drawerWidthGlobal: {
    width: drawerWidthGlobal,
  },
  drawerWidthIssue: {
    width: drawerWidthIssue,
  },
  drawerPaper: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[50],
    boxShadow: `1px 1px 10px ${theme.palette.grey[400]}`,
  },
  tabs: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    minHeight: theme.spacing(5),
  },
  tab: {
    color: theme.palette.grey[600],
    minHeight: theme.spacing(5),
  },
  tabLabel: {
    fontSize: '.9rem',
  },
  toolbar: theme.mixins.toolbar,
  contentPaddingGlobal: {
    paddingLeft: `calc(${drawerWidthGlobal}px + ${theme.spacing(3)})`,
  },
  contentPaddingIssue: {
    paddingLeft: `calc(${drawerWidthIssue}px + ${theme.spacing(3)})`,
  },
  content: {
    paddingTop: `calc(56px * 2 + ${theme.spacing(3)})`,
    minHeight: `calc(100vh - 56px * 2 - ${theme.spacing(3)})`,
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingRight: theme.spacing(3),
    minWidth: 0, // So the Typography noWrap works
    flexDirection: 'column',
  },
  issueFilter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
