import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  DeleteSubjectUserMutation,
  DeleteSubjectUserMutationVariables,
} from '../../../__generated__/queries-topic';

const DELETE_SUBJECT_USER = gql`
  mutation DeleteSubjectUser($userId: String!, $subjectId: ID!) {
    deleteSubjectUser(where: { userId: $userId, subject: { id: $subjectId } }) {
      userId
    }
  }
`;

export const useSubjectUserDeleter = (
  options?: MutationHookOptions<
    DeleteSubjectUserMutation,
    DeleteSubjectUserMutationVariables
  >,
) =>
  useMutation<DeleteSubjectUserMutation, DeleteSubjectUserMutationVariables>(
    DELETE_SUBJECT_USER,
    options,
  );
