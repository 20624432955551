// https://github.com/unional/typescript-guidelines/blob/master/pages/advance-types/recursive-types.md
type stringOrStringArray = string | string[] | null;

const convertNumberParamsToStringValue = (
  param: stringOrStringArray,
): stringOrStringArray => {
  if (!param) {
    return '';
  }

  if (Array.isArray(param)) {
    return param.map((p) => convertNumberParamsToStringValue(p)) as string[];
  }
  return isNaN(param as any) ? param : `'${param}'`;
};

export const convertStringValueToNumberParams = (
  value: stringOrStringArray,
): stringOrStringArray => {
  if (Array.isArray(value)) {
    return value.map((p) => convertStringValueToNumberParams(p)) as string[];
  }
  return (value && value.replace(/'(.*)'/, '$1')) || null;
};

export const filterNamespacedUrlFilters = (
  parsedSearch: { [s: string]: string },
  namespace = '',
  mode: 'include' | 'exclude' = 'include',
): { [s: string]: string | string[] } => {
  const filteredSearch: { [s: string]: string } = Object.keys(parsedSearch)
    .filter(
      (name) =>
        (mode === 'include' && name.startsWith(namespace)) ||
        (mode === 'exclude' && !name.startsWith(namespace)),
    )
    .reduce(
      (params, name) => ({
        ...params,
        // https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object
        // en gros le probleme c'est que final-form ne permet pas d'utiliser des clefs numeriques dans les objets de ses formulaires
        // ce qu'on fait dans les filtres des assets pour le champs urgency par exemple (urgency[idx])
        // donc pour que ca marche on fait un remplacement string ('2') =>  number (2) quand on envoie
        // a l'url et inverse quand on recupere de l'url
        [name.replace(`${namespace}_`, '')]: convertNumberParamsToStringValue(
          parsedSearch[name],
        ),
      }),
      {},
    );
  return filteredSearch;
};

export const addNamespaceToUrlFilters = (
  params: { [s: string]: stringOrStringArray },
  namespace = '',
): { [s: string]: stringOrStringArray } => {
  if (namespace === '') {
    return params;
  }

  const formattedParams = Object.keys(params).reduce(
    (prev, paramKey) => ({
      ...prev,
      // https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object
      [`${namespace}_${paramKey}`]: convertStringValueToNumberParams(
        params[paramKey],
      ),
    }),
    {},
  );

  return formattedParams;
};
