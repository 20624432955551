import { Typography } from '@mui/material';
import { EditorState } from 'draft-js';
import {
  CountType,
  getCountFromEditorState,
  getCountFromText,
} from '../../../components/CharCounter/utils';
import { pluralize } from '../../../utils/addPlural';

interface GlobalCharCounterProps {
  values: (string | EditorState)[];
}

export const GlobalCharCounter = ({ values }: GlobalCharCounterProps) => {
  const globalCounter: CountType = { charCount: 0, wordCount: 0 };
  values.forEach((value) => {
    let counter: CountType;
    if (typeof value === 'string') {
      counter = getCountFromText(value);
    } else {
      counter = getCountFromEditorState(value);
    }
    // eslint-disable-next-line immutable/no-mutation
    globalCounter.charCount = globalCounter.charCount + counter.charCount;
    // eslint-disable-next-line immutable/no-mutation
    globalCounter.wordCount = globalCounter.wordCount + counter.wordCount;
  });

  return (
    <Typography variant="body1">
      <b>Total de l'article</b> :{' '}
      {`${globalCounter.charCount} ${pluralize(
        'Caractère',
        'Caractères',
        globalCounter.charCount,
      )} - ${globalCounter.wordCount} ${pluralize(
        'Mot',
        'Mots',
        globalCounter.wordCount,
      )}`}
    </Typography>
  );
};
