/*
  deduplicate a slice by key
*/
export function deduplicate<T = any>(_in: T[], byKey: (i: T) => any): T[] {
  const seen: ReturnType<typeof byKey>[] = [];
  return _in.reduce((result, item) => {
    const key = byKey(item);
    if (!seen.includes(key)) {
      // eslint-disable-next-line fp/no-mutating-methods
      seen.push(key);
      return [...result, item];
    }

    return result;
  }, [] as T[]);
}
