import { gql } from '@apollo/client';

export const UPDATE_ASSIGNMENT_PAGENUMBER = gql`
  mutation updateAssignmentPageNumber($assignmentId: ID!, $pageNumber: Int!) {
    updateAssignment(
      data: { pageNumber: $pageNumber }
      where: { id: $assignmentId }
    ) {
      id
    }
  }
`;
