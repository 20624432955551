import axios from 'axios';

export const ATTACHMENT_SIZE_MAX: number = 1024 * 1024 * 200; // max 200 MO
export const ATTACHMENT_EXTENSIONS: string[] = [
  '.doc',
  '.docx',
  '.xsl',
  '.xslx',
  '.pdf',
];

export enum AttachmentContentType {
  pdf = 'application/pdf',
  word = 'application/msword',
  wordx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  excel = 'application/vnd.ms-excel',
  excelx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const AttachmentsType: string[] = [
  AttachmentContentType.pdf,
  AttachmentContentType.word,
  AttachmentContentType.wordx,
  AttachmentContentType.excel,
  AttachmentContentType.excelx,
];

const isValidTypeAttachment = (type: string) => AttachmentsType.includes(type);

const getAttachmentIdByUrl = (url: string) =>
  new URL(url).pathname.split('/')[1];

export const uploadAttachmentFromS3 = async (
  presignedUrl: string,
  file: File,
) => {
  try {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return await axios
      .put(presignedUrl, file, {
        headers: {
          'content-type': file.type,
        },
      })
      .then(() => getAttachmentIdByUrl(presignedUrl));
  } catch (e) {
    throw new Error('Echec du téléchargement. Merci de réessayer.');
  }
};

export const isValidFileTypeAndSize = (file: File) =>
  isValidTypeAttachment(file.type) && file.size < ATTACHMENT_SIZE_MAX;
