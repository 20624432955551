import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  contentText: {
    marginBottom: theme.spacing(2),
  },
  wrapperAssignment: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));
