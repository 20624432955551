import { gql } from '@apollo/client';
import {
  LocationPage_GetSubjectsFromPrintHeadingQuery,
  LocationPage_GetSubjectsFromPrintHeadingQueryVariables,
} from '../../../../__generated__/queries-topic';
import { useGetter } from '../../../../apollo/useGetter';

export const GET_SUBJECTS_FROM_PRINT_HEADING = gql`
  query LocationPage_GetSubjectsFromPrintHeading($where: SubjectFilterInput) {
    subjects(first: 1000, where: $where) {
      id
      title
      isAuto
      subjectCategory {
        id
        title
        parent {
          id
          title
        }
      }
      flatAssignments(first: 10) {
        printHeading {
          id
          title
        }
      }
    }
  }
`;

export const useSubjectsFromPrintHeadingGetter = () =>
  useGetter<
    LocationPage_GetSubjectsFromPrintHeadingQuery,
    LocationPage_GetSubjectsFromPrintHeadingQueryVariables
  >(GET_SUBJECTS_FROM_PRINT_HEADING);
