import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    borderRadius: 4,
  },
  shadowedInput: {
    boxShadow: `1px 1px 10px ${theme.palette.grey[400]}`,
  },
  textFieldInput: {
    fontSize: 16,
    marginLeft: '8px',
    lineHeight: 1.7,
    padding: '10.5px 0px',
  },
}));
