import { gql, useApolloClient, useQuery } from '@apollo/client';
import uuid from 'uuid/v4';
import {
  CreatePrintTemplateMutation,
  CreatePrintTemplateMutationVariables,
  DetachPrintTemplateMutation,
  DetachPrintTemplateMutationVariables,
  GetPrintHeadingQuery,
  GetPrintHeadingQueryVariables,
  GetPrintTemplateDownloadUrlQuery,
  GetPrintTemplateDownloadUrlQueryVariables,
  GetPrintTemplatePreviewUrlsQuery,
  GetPrintTemplatePreviewUrlsQueryVariables,
  GetPrintTemplateUploadUrlQuery,
  UpdatePrintHeadingPrintTemplateMutation,
  UpdatePrintHeadingPrintTemplateMutationVariables,
  UpdatePrintTemplateMutation,
  UpdatePrintTemplateMutationVariables,
} from '../../__generated__/queries-topic';

export const PRINT_TEMPLATE_PRINT_TEMPLATE_FRAGMENT = gql`
  fragment PrintHeadingPrintTemplate on PrintHeadingPrintTemplate {
    printTemplate {
      id
      S3ObjectId
      label
      filename
      type
      filesize
      pageCount
      extVersion
      data
      fields {
        id
        name
        type
        enabled
        data
        typeVariation
        paragraphStyles {
          id
          name
        }
        characterStyles {
          id
          name
        }
        coordinates {
          x
          y
          width
          height
          rotation
        }
        page {
          offset
          number
          coordinates {
            x
            y
            width
            height
            rotation
          }
        }
      }
      createdAt
      updatedAt
      createdBy {
        name
      }
      updatedBy {
        name
      }
    }
  }
`;

const PRINT_TEMPLATE_FRAGMENT = gql`
  fragment PrintTemplate on PrintTemplate {
    id
    S3ObjectId
    label
    filename
    type
    filesize
    pageCount
    extVersion
    data
    fields {
      id
      name
      type
      enabled
      data
      typeVariation
      paragraphStyles {
        id
        name
      }
      characterStyles {
        id
        name
      }
      coordinates {
        x
        y
        width
        height
        rotation
      }
      page {
        offset
        number
        coordinates {
          x
          y
          width
          height
          rotation
        }
      }
    }
    createdAt
    updatedAt
    printHeadings(first: 1000) {
      id
      active
      printHeading {
        id
        title
        printIssue {
          id
          title
          printPublication {
            id
            title
            brand {
              id
              title
            }
          }
        }
      }
    }
    createdBy {
      name
    }
    updatedBy {
      name
    }
  }
`;

const CREATE_PRINTTEMPLATE_QUERY = gql`
  mutation CreatePrintTemplate($data: PrintTemplateCreationInput!) {
    createPrintTemplate(data: $data) {
      ...PrintTemplate
    }
  }
  ${PRINT_TEMPLATE_FRAGMENT}
`;

export const useCreatePrintTemplate = (printHeadingId: string) => {
  const client = useApolloClient();
  return (
    variables: Omit<
      CreatePrintTemplateMutationVariables['data'],
      'printHeadings'
    >,
    active = false,
  ) =>
    client.mutate<
      CreatePrintTemplateMutation,
      CreatePrintTemplateMutationVariables
    >({
      mutation: CREATE_PRINTTEMPLATE_QUERY,
      refetchQueries: ['GetPrintHeadingQuery'],
      variables: {
        data: {
          printHeadings: {
            create: [
              {
                id: uuid(),
                active,
                printHeading: { connect: { id: printHeadingId } },
              },
            ],
          },
          ...variables,
        },
      },
    });
};

const UPDATE_PRINTTEMPLATE_QUERY = gql`
  mutation UpdatePrintTemplate(
    $data: PrintTemplateUpdateInput!
    $where: PrintTemplateUniqueFilterInput!
  ) {
    updatePrintTemplate(data: $data, where: $where) {
      ...PrintTemplate
    }
  }
  ${PRINT_TEMPLATE_FRAGMENT}
`;

export const useUpdatePrintTemplateLabel = (printTemplateId: string) => {
  const client = useApolloClient();
  return (label: string) => {
    return client.mutate<
      UpdatePrintTemplateMutation,
      UpdatePrintTemplateMutationVariables
    >({
      mutation: UPDATE_PRINTTEMPLATE_QUERY,
      refetchQueries: ['GetPrintHeadingQuery'],
      variables: {
        data: {
          label,
        },
        where: {
          id: printTemplateId,
        },
      },
    });
  };
};

const DETACH_PRINT_TEMPLATE_QUERY = gql`
  mutation DetachPrintTemplate($id: ID!) {
    deletePrintHeadingPrintTemplate(where: { id: $id }) {
      id
    }
  }
`;

export const useDetachPrintTemplate = (printHeadingPrintTemplateId: string) => {
  const client = useApolloClient();
  return () => {
    return client.mutate<
      DetachPrintTemplateMutation,
      DetachPrintTemplateMutationVariables
    >({
      mutation: DETACH_PRINT_TEMPLATE_QUERY,
      refetchQueries: ['GetPrintHeadingQuery'],
      variables: {
        id: printHeadingPrintTemplateId,
      },
    });
  };
};

const UPDATE_PRINTHEADING_PTINTTEMPLATE_QUERY = gql`
  mutation UpdatePrintHeadingPrintTemplate(
    $data: PrintHeadingPrintTemplateUpdateInput!
    $where: PrintHeadingPrintTemplateUniqueFilterInput!
  ) {
    updatePrintHeadingPrintTemplate(data: $data, where: $where) {
      id
      active
    }
  }
`;

export const useActivatePrintTemplate = () => {
  const client = useApolloClient();
  return (printHeadingPrintTemplateId: string) => {
    return client.mutate<
      UpdatePrintHeadingPrintTemplateMutation,
      UpdatePrintHeadingPrintTemplateMutationVariables
    >({
      mutation: UPDATE_PRINTHEADING_PTINTTEMPLATE_QUERY,
      refetchQueries: ['GetPrintHeadingQuery'],
      variables: {
        data: {
          active: true,
        },
        where: {
          id: printHeadingPrintTemplateId,
        },
      },
    });
  };
};

const GET_PRINTHEADING_QUERY = gql`
  query GetPrintHeading($id: ID) {
    printHeading(where: { id: $id }) {
      id
      title
      order
      legacyId
      hasAutomaticSubject
      createdAt
      updatedAt
      printIssue {
        id
        title
        printPublication {
          id
          title
          brand {
            id
            title
            unit {
              id
              title
            }
          }
        }
      }
      printTemplateCount
      printTemplates(first: 50) {
        id
        active
        ...PrintHeadingPrintTemplate
      }
    }
  }
  ${PRINT_TEMPLATE_PRINT_TEMPLATE_FRAGMENT}
`;

export const useGetPrintHeading = (printHeadingId: string) => {
  return useQuery<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>(
    GET_PRINTHEADING_QUERY,
    {
      variables: {
        id: printHeadingId,
      },
    },
  );
};

const GET_DOWNLOAD_URL_QUERY = gql`
  query GetPrintTemplateDownloadUrl($printTemplateId: String!) {
    printTemplateDownload(printTemplateId: $printTemplateId) {
      id
      url
    }
  }
`;

export const useGetDownloadUrl = (printTemplateId: string) => {
  const client = useApolloClient();
  return () =>
    client.query<
      GetPrintTemplateDownloadUrlQuery,
      GetPrintTemplateDownloadUrlQueryVariables
    >({
      query: GET_DOWNLOAD_URL_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        printTemplateId,
      },
    });
};

const GET_UPLOAD_URL_QUERY = gql`
  query GetPrintTemplateUploadUrl($S3ObjectId: String!, $filename: String!) {
    printTemplateUpload(S3ObjectId: $S3ObjectId, filename: $filename) {
      id
      url
    }
  }
`;

export const useGetUploadUrl = () => {
  const client = useApolloClient();
  return (S3ObjectId: string, filename: string) =>
    client.query<GetPrintTemplateUploadUrlQuery>({
      query: GET_UPLOAD_URL_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        S3ObjectId,
        filename,
      },
    });
};

const GET_PRINT_TEMPLATE_PREVIEW_URLS_QUERY = gql`
  query GetPrintTemplatePreviewUrls($printTemplateId: String!) {
    printTemplatePreviewUrls(printTemplateId: $printTemplateId)
  }
`;

export const useGetPreviewUrls = (printTemplateId: string) => {
  return useQuery<
    GetPrintTemplatePreviewUrlsQuery,
    GetPrintTemplatePreviewUrlsQueryVariables
  >(GET_PRINT_TEMPLATE_PREVIEW_URLS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      printTemplateId,
    },
  });
};
