import { User } from '@prismamedia/one-user';
import { EditorState } from 'draft-js';
import { editorStateFromRaw } from 'megadraft';
import uuid from 'uuid/v4';
import {
  GetRawArticleVersionArticleHistoryQuery,
  RawArticleStatus,
  UpsertRawArticleMutationVariables,
} from '../../../__generated__/queries-topic';
import { auth } from '../../../utils/auth';

export interface initialValuesVersionHistory {
  id: string;
  title: string;
  lead: EditorState;
  body: EditorState;
  signature: string;
  status: RawArticleStatus;
  lastVersionId: string;
  rawArticleId: string;
}

export const apiToForm = (
  data: GetRawArticleVersionArticleHistoryQuery,
): initialValuesVersionHistory => {
  if (!data.rawArticleVersion) {
    return {
      id: '',
      title: '',
      lead: editorStateFromRaw(''),
      body: editorStateFromRaw(''),
      signature: '',
      status: RawArticleStatus.Editing,
      lastVersionId: '',
      rawArticleId: '',
    };
  }

  return {
    id: data.rawArticleVersion.id,
    title: data.rawArticleVersion.title || '',
    lead: editorStateFromRaw(
      data.rawArticleVersion.lead
        ? JSON.parse(data.rawArticleVersion.lead)
        : '',
    ),
    body: editorStateFromRaw(
      data.rawArticleVersion.body
        ? JSON.parse(data.rawArticleVersion.body)
        : '',
    ),
    signature: data.rawArticleVersion.signature || '',
    status: data.rawArticleVersion.status,
    lastVersionId: data.rawArticleVersion.rawArticle.lastVersion?.id || '',
    rawArticleId: data.rawArticleVersion.rawArticle.id,
  };
};

// TODO - TO TEST
export const formToApi = (
  data: initialValuesVersionHistory,
): UpsertRawArticleMutationVariables => {
  const versionData = {
    id: uuid(),
    editorId: (auth.user as User).id,
    status: RawArticleStatus.Editing,
    forkOf: { connect: { id: data.id } },
    previousVersion: {
      connect: { id: data.lastVersionId },
    },
  };

  const model = {
    versions: {
      create: [versionData],
    },
  };

  return {
    create: model,
    update: model,
    where: { id: data.rawArticleId },
  };
};
