import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  list: {
    width: '100%',
  },
  infinite: {
    width: '100%',
  },
});
