import FormLabel from '@mui/material/FormLabel';
import { ContentState, EditorState, Modifier } from 'draft-js';
import { MegadraftEditor } from 'megadraft';
import { useContext, useState } from 'react';
import { ArticleAutoContext, DraftFormField } from '../../';
import { CharCounter } from '../../CharCounter';
import { useStyles } from '../styles';
import { useConnectFieldToComponent } from '../useConnectFieldToComponent';
import { createDraftActions } from './megadraftActions';

interface FormInputDraftProps {
  field: DraftFormField;
  disabled: boolean;
}

export const applyParagraphStyle = (state: EditorState, style: string) => {
  const styledContent = Modifier.setBlockType(
    state.getCurrentContent(),
    state.getSelection(),
    `idsn:p:${style}`,
  );
  return EditorState.push(state, styledContent, 'change-block-type');
};

export const FormInputDraft = ({ field, disabled }: FormInputDraftProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<EditorState>(field.value);
  const { updateFormValues, selectedPrintTemplate } = useContext(
    ArticleAutoContext,
  );
  const targetTemplateField = selectedPrintTemplate?.fields.find(
    (f) => f?.id === field.id,
  );

  useConnectFieldToComponent(field, setValue);

  const handleChange = (state: EditorState) => {
    const defaultStyle = targetTemplateField?.paragraphStyles[0]?.id;
    if (
      state.getLastChangeType() === 'insert-characters' &&
      value.getCurrentContent().getBlocksAsArray().length === 1 &&
      defaultStyle
    ) {
      // eslint-disable-next-line no-param-reassign
      state = applyParagraphStyle(state, defaultStyle);
    }

    setValue(state);
    updateFormValues(field.id, state);
  };

  const handlePastedText = (
    text: string,
    _: string,
    editorState: EditorState,
  ) => {
    const pastedBlocks = ContentState.createFromText(
      text,
      // split pasted text using unicode seperation char (u2028) delimeter
      String.fromCharCode(8232),
    ).getBlockMap();

    const pastedContent = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      pastedBlocks,
    );

    // eslint-disable-next-line no-param-reassign
    editorState = EditorState.push(
      editorState,
      pastedContent,
      'insert-characters',
    );

    handleChange(editorState);
    return true;
  };

  return (
    <div className={classes.formTextItem}>
      <FormLabel>{field.name}</FormLabel>
      <MegadraftEditor
        readOnly={disabled}
        label={field.name}
        sidebarRendererFn={() => null}
        actions={createDraftActions(
          targetTemplateField?.paragraphStyles || [],
          targetTemplateField?.characterStyles || [],
        )}
        editorState={value}
        onChange={handleChange}
        handlePastedText={handlePastedText}
      />
      <CharCounter content={value} maxLength={field.maxLength} type="Draft" />
    </div>
  );
};
