/* eslint-disable fp/no-this */
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowRight,
  MoreHoriz,
} from '@mui/icons-material';
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import * as R from 'ramda';
import React, {
  Component,
  CSSProperties,
  FC,
  MouseEvent as ReactMouseEvent,
} from 'react';

export enum checkboxStatus {
  checked = 'CHECKED',
  unchecked = 'UNCHECKED',
  indeterminate = 'INDETERMINATE',
}

enum changeType {
  nodeClick,
  nodeCheck,
  moreClick,
}

enum arrowTypeEnum {
  left,
  right,
}

interface TreeNode {
  title: string;
  id: string;
  children?: TreeNode[];
  more?: boolean;
  parent?: string;
  open?: boolean;
  checked?: checkboxStatus;
}

export interface TreeNodeRef {
  title: string;
  id: string;
  children: string[];
  more?: boolean;
  parent?: string;
  open?: boolean;
  checked?: checkboxStatus;
}

export interface NodeMap {
  [key: string]: TreeNodeRef;
}

type Timeout = number | { enter: number; exit: number } | 'auto';

interface TreeListProps {
  nodeMap: NodeMap;
  onChange: (nodeMap: NodeMap, type: changeType) => void;
  onNodeClick?: (node: TreeNodeRef) => void;
  onNodeCheck?: (node: TreeNodeRef, nodeMap: NodeMap) => void;
  onMoreClick?: (node: TreeNodeRef) => Promise<TreeNode[]>;
  timeout?: Timeout;
  depthPadding?: number;
  arrowType?: arrowTypeEnum;
}

type PropsWithStyle = TreeListProps;

interface NodeListProps {
  parent?: TreeNodeRef;
  nodeMap: NodeMap;
  nodeList: TreeNodeRef[];
  onNodeClick: (
    e: ReactMouseEvent<HTMLLIElement, MouseEvent>,
    node: TreeNodeRef,
  ) => void;
  onNodeCheck: (checked: boolean, node: TreeNodeRef) => void;
  onMoreClick?: (node: TreeNodeRef) => Promise<void>;
  depth: number;
  more?: boolean;
  timeout?: Timeout;
  depthPadding: number;
  arrowType: arrowTypeEnum;
  ListItemStyle?: CSSProperties;
  ListItemTextStyle?: CSSProperties;
}

// eslint-disable-next-line fp/no-class
class NonUniqueIdError extends Error {
  constructor() {
    super('Each node of the tree lists should have a unique id !');
  }
}

const hasChildChecked = (node: TreeNode, checkedIds: string[]): boolean => {
  return node.children
    ? node.children.some(
        (child) =>
          child.checked === checkboxStatus.checked ||
          checkedIds.includes(child.id) ||
          hasChildChecked(child, checkedIds),
      )
    : false;
};

const hasEveryChildChecked = (
  node: TreeNode,
  checkedIds: string[],
): boolean => {
  if (!node.children || !node.children.length) {
    return false;
  }

  return node.children.every(
    (child) =>
      child.checked === checkboxStatus.checked ||
      checkedIds.includes(child.id) ||
      hasEveryChildChecked(child, checkedIds),
  );
};

const constructNodeMap = (
  nodeMap: NodeMap,
  nodes: TreeNode[],
  autoOpen = false,
  parent?: string,
  checkedIds: string[] = [],
): NodeMap => {
  const children: { [key: string]: TreeNode[] } = {};
  nodes.forEach((node) => {
    if (nodeMap[node.id]) {
      throw new NonUniqueIdError();
    }
    // eslint-disable-next-line immutable/no-mutation
    nodeMap[node.id] = {
      ...node,
      open:
        node.open ||
        (autoOpen &&
          hasChildChecked(node, checkedIds) &&
          ([
            checkboxStatus.unchecked,
            checkboxStatus.indeterminate,
            undefined,
          ].includes(node.checked) ||
            checkedIds.includes(node.id))),
      children: (node.children || []).map((child: TreeNode) => child.id),
      parent,
      checked:
        (parent && nodeMap[parent].checked === checkboxStatus.checked) ||
        checkedIds.includes(node.id) ||
        hasEveryChildChecked(node, checkedIds)
          ? checkboxStatus.checked
          : node.checked || checkboxStatus.unchecked,
    };
    if (
      node.checked === checkboxStatus.checked ||
      checkedIds.includes(node.id)
    ) {
      let p = parent;
      // eslint-disable-next-line fp/no-loops
      while (p) {
        if (
          nodeMap[p].checked === checkboxStatus.unchecked &&
          !checkedIds.includes(nodeMap[p].id)
        ) {
          // eslint-disable-next-line immutable/no-mutation
          nodeMap[p].checked = checkboxStatus.indeterminate;
        }
        p = nodeMap[p].parent;
      }
    }
    // eslint-disable-next-line immutable/no-mutation
    children[node.id] = node.children || [];
  });

  if (Object.keys(children).length > 0) {
    // eslint-disable-next-line fp/no-loops
    for (const [parentId, childrenNode] of Object.entries(children)) {
      constructNodeMap(nodeMap, childrenNode, autoOpen, parentId, checkedIds);
    }
  }

  return nodeMap;
};

export const mapFromTree = (
  nodeTreeList?: TreeNode[],
  autoOpen = false,
  checkedIds: string[] = [],
): NodeMap => {
  if (!nodeTreeList) {
    return {};
  }
  return constructNodeMap({}, nodeTreeList, autoOpen, undefined, checkedIds);
};

const NodeList: FC<NodeListProps> = ({
  parent,
  nodeMap,
  nodeList,
  onNodeClick,
  onNodeCheck,
  onMoreClick,
  depth,
  more,
  timeout = 'auto',
  depthPadding,
  arrowType,
  ListItemStyle,
  ListItemTextStyle,
}: NodeListProps) => (
  <>
    {nodeList.map((node) => (
      <React.Fragment key={node.id}>
        <ListItem
          onClick={(event) => onNodeClick(event, node)}
          style={{
            paddingLeft: depth * depthPadding,
            paddingTop: 4,
            paddingBottom: 0,
            cursor: 'pointer',
            ...ListItemStyle,
          }}
          dense
        >
          <Checkbox
            checked={node.checked === checkboxStatus.checked}
            indeterminate={node.checked === checkboxStatus.indeterminate}
            onChange={(_, checked) => onNodeCheck(checked, node)}
            tabIndex={-1}
            disableRipple
            style={{
              height: 3 * 8,
              width: 3 * 8,
            }}
            value={node.id}
          />
          {arrowType === arrowTypeEnum.left &&
            node.children &&
            node.children.length > 0 &&
            (node.open ? <ExpandMore /> : <KeyboardArrowRight />)}
          <ListItemText
            style={{ paddingLeft: 8, ...ListItemTextStyle }}
            inset
            primary={node.title}
          />
          {arrowType === arrowTypeEnum.right &&
            node.children &&
            node.children.length > 0 &&
            (node.open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        <Collapse in={node.open} timeout={timeout} unmountOnExit>
          <List component={'div' as any} disablePadding dense>
            {node.children && (
              <NodeList
                parent={node}
                nodeMap={nodeMap}
                nodeList={node.children.map((nodeId) => nodeMap[nodeId])}
                onNodeClick={onNodeClick}
                onNodeCheck={onNodeCheck}
                onMoreClick={onMoreClick}
                depth={depth + 1}
                more={node.more}
                timeout={timeout}
                depthPadding={depthPadding}
                arrowType={arrowType}
              />
            )}
          </List>
        </Collapse>
      </React.Fragment>
    ))}
    {parent && more && (
      <ListItem
        key={`${parent.id}-more`}
        style={{ paddingLeft: depth * 8 }}
        onClick={() => onMoreClick && onMoreClick(parent)}
        button
      >
        <ListItemIcon>
          <MoreHoriz />
        </ListItemIcon>
        <ListItemText primary="En voir plus" />
      </ListItem>
    )}
  </>
);

// eslint-disable-next-line fp/no-class
export class TreeList extends Component<PropsWithStyle> {
  handleNodeClick = (
    event: ReactMouseEvent<HTMLLIElement, MouseEvent>,
    node: TreeNodeRef,
  ): void => {
    if ((event.target as HTMLInputElement).type === 'checkbox') {
      return;
    }
    if (!node.children || node.children.length === 0) {
      const beenChecked =
        node.checked === checkboxStatus.unchecked ||
        node.checked === checkboxStatus.indeterminate;
      this.handleNodeCheck(beenChecked, node);
      return;
    }
    const newNodeMap = {
      ...this.props.nodeMap,
      [node.id]: { ...node, open: !node.open },
    };
    this.props.onChange(newNodeMap, changeType.nodeClick);

    const { onNodeClick } = this.props;
    if (onNodeClick) {
      onNodeClick(node);
    }
  };

  handleNodeCheck = (checked: boolean, node: TreeNodeRef): boolean | void => {
    const beenChecked = node.checked === checkboxStatus.unchecked && checked;
    const beenUnchecked =
      (node.checked === checkboxStatus.checked && !checked) ||
      node.checked === checkboxStatus.indeterminate;

    const changes = {
      [node.id]: {
        checked: beenChecked
          ? checkboxStatus.checked
          : checkboxStatus.unchecked,
      },
    };
    let currentNode = node;
    let children = node.children || [];
    if (beenChecked) {
      // check all children recursively
      // eslint-disable-next-line fp/no-loops
      while (children.length > 0) {
        children.forEach((childId) => {
          // eslint-disable-next-line immutable/no-mutation
          changes[childId] = { checked: checkboxStatus.checked };
        });
        // problem with @types/ramda
        children = (R.flatten(
          children.map((childId) => this.props.nodeMap[childId].children || []),
        ) as unknown) as string[];
      }
    }
    if (beenUnchecked) {
      // uncheck all children recursively
      // eslint-disable-next-line fp/no-loops
      while (children.length > 0) {
        children.forEach((childId) => {
          // eslint-disable-next-line immutable/no-mutation
          changes[childId] = { checked: checkboxStatus.unchecked };
        });
        // problem with @types/ramda
        children = (R.flatten(
          children.map((childId) => this.props.nodeMap[childId].children || []),
        ) as unknown) as string[];
      }
    }

    // check all parents up to the root
    // eslint-disable-next-line fp/no-loops
    while (currentNode.parent) {
      const parentChildren =
        this.props.nodeMap[currentNode.parent].children || [];
      let parentChecked;
      if (beenUnchecked) {
        // if there is at least one child checked or indeterminate then indeterminate otherwise unchecked
        parentChecked =
          parentChildren.findIndex((childId) => {
            const child = changes[childId] || this.props.nodeMap[childId];
            return [
              checkboxStatus.checked,
              checkboxStatus.indeterminate,
            ].includes(child.checked);
          }) !== -1
            ? checkboxStatus.indeterminate
            : checkboxStatus.unchecked;
      } else {
        // if there is at least one child unchecked or indeterminate then indeterminate otherwise checked
        parentChecked =
          parentChildren.findIndex((childId) => {
            const child = changes[childId] || this.props.nodeMap[childId];
            return [
              checkboxStatus.unchecked,
              checkboxStatus.indeterminate,
            ].includes(child.checked);
          }) !== -1
            ? checkboxStatus.indeterminate
            : checkboxStatus.checked;
      }
      // eslint-disable-next-line immutable/no-mutation
      changes[currentNode.parent] = { checked: parentChecked };
      currentNode = this.props.nodeMap[currentNode.parent];
    }
    const newNodeMap: NodeMap = R.mergeDeepRight(
      this.props.nodeMap,
      changes,
    ) as NodeMap;
    this.props.onChange(newNodeMap, changeType.nodeCheck);

    const { onNodeCheck } = this.props;
    if (onNodeCheck) {
      onNodeCheck(node, newNodeMap);
    }
  };

  handleMoreClick = async (node: TreeNodeRef): Promise<void> => {
    if (!this.props.onMoreClick) {
      return;
    }
    const newNodes = await this.props.onMoreClick(node);
    if (newNodes) {
      newNodes.forEach((n) => {
        if (this.props.nodeMap[n.id]) {
          throw new NonUniqueIdError();
        }
      });
      this.props.onChange(
        {
          ...this.props.nodeMap,
          [node.id]: {
            ...node,
            children: [
              ...node.children,
              ...newNodes.map((newNode) => newNode.id),
            ],
          },
          ...newNodes.reduce((acc, newNode) => {
            // eslint-disable-next-line immutable/no-mutation
            acc[newNode.id] = {
              ...newNode,
              parent: node.id,
              checked:
                node.checked === checkboxStatus.checked
                  ? checkboxStatus.checked
                  : checkboxStatus.unchecked,
              children: newNode.children
                ? newNode.children.map((child) => child.id)
                : [],
            };
            return acc;
            // tslint:disable-next-line
          }, {} as { [key: string]: TreeNodeRef }),
        },
        changeType.moreClick,
      );
    }
  };

  render() {
    const { nodeMap, timeout, depthPadding, arrowType } = this.props;
    const defaultDepthPadding = 3 * 8;
    const defaultArrowType = arrowTypeEnum.left;
    return (
      <div
        style={{
          width: '100%',
          maxWidth: 360,
        }}
      >
        <List component="nav" dense>
          <NodeList
            nodeMap={nodeMap}
            nodeList={Object.values(nodeMap).filter((node) => !node.parent)}
            onNodeClick={this.handleNodeClick}
            onNodeCheck={this.handleNodeCheck}
            onMoreClick={this.handleMoreClick}
            depth={1}
            timeout={timeout}
            depthPadding={depthPadding || defaultDepthPadding}
            arrowType={arrowType || defaultArrowType}
          />
        </List>
      </div>
    );
  }
}
